import React from 'react';
import FusionModuleCard from '../FusionModule/FusionModuleCard';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import FusionModuleStatus from '../FusionModule/FusionModuleStatus';
import ComponentStatus from '../Component/ComponentStatus';
import FusionJobLink from '../FusionJob/FusionJobLink';
import FusorService from '../../services/FusorService';
import { ROUTES } from '../../constants';
import TitanPage from '../Titan/TitanPage';
import { useTitan } from '../Titan/Titan';
import TitanDataGrid, { VIEW_MODES } from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import useFusionModules from '../FusionModule/use-fusion-modules';

const FusionModulePage = () => {
  const { addPageToPageHistory } = useTitan();

  const breadcrumbs = React.useMemo(
    () => [
      {
        path: ROUTES.FUSION_MODULES,
        name: 'Fusion Modules',
        disabled: true
      }
    ],
    []
  );

  const fusionModuleColumns = React.useMemo(
    () => [
      {
        headerName: 'Fusion Module',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <FusionModuleLink fusionModule={row} />
      },
      {
        headerName: 'State',
        field: 'state',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <FusionModuleStatus
            fusionModuleId={row.id}
            initialStatus={row.state?.state}
          />
        )
      },
      {
        headerName: 'Version',
        field: 'version',
        visibilityBreakpoint: 'md',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => row.state?.fusionModuleVersion || ''
      },
      {
        headerName: 'Fusion Job',
        field: 'fusionJob',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevFusionJob;

          return activeJob || prevJob ? (
            <FusionJobLink fusionJob={activeJob ? activeJob : prevJob} />
          ) : (
            ''
          );
        }
      },
      {
        headerName: 'Job Status',
        field: 'fusionJobStatus',
        visibilityBreakpoint: 'sm',
        minWidth: 170,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevFusionJob;

          return activeJob || prevJob ? (
            <ComponentStatus component={activeJob ? activeJob : prevJob} />
          ) : (
            ''
          );
        }
      }
    ],
    []
  );

  const loadFusionModules = React.useCallback(async (params, config) => {
    const response = await FusorService.getFusors(
      {
        withRelated: [
          'activeJobs',
          'activeJobs.fusionPlan',
          'activeJobs.assemblies',
          'prevFusionJob',
          'prevFusionJob.fusionPlan',
          'prevFusionJob.assemblies'
        ],
        ...params
      },
      config
    );

    return response;
  }, []);

  const fusionModulesDataGridProps = useTitanDataGrid(loadFusionModules, {
    columns: fusionModuleColumns,
    defaultViewMode: VIEW_MODES.GRID
  });

  const { rows } = fusionModulesDataGridProps;

  const returnFusionModules = React.useCallback(() => {
    return rows;
  }, [rows]);

  const { fusionModules } = useFusionModules(returnFusionModules);

  React.useEffect(() => {
    addPageToPageHistory({
      id: 'FUSION_MODULES',
      url: ROUTES.FUSION_MODULES,
      label: 'Fusion Modules'
    });
  }, []);

  return (
    <TitanPage breadcrumbs={breadcrumbs} title={'Fusion Modules'}>
      <TitanDataGrid
        {...fusionModulesDataGridProps}
        allowViewModeChange={true}
        rows={fusionModules}
        renderGridCard={item => <FusionModuleCard fusionModule={item} />}
        searchPlaceholder="Search by Fusion Module name..."
      />
    </TitanPage>
  );
};

export default FusionModulePage;
