import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES, WEB_SOCKET_ACTIONS } from '../../constants';
import { useFusionPlan } from './FusionPlanPage';
import useWebSocket from 'react-use-websocket';
import { useTitan } from '../Titan/Titan';

export default function FusionPlanSocketHandlers() {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();
  const history = useHistory();

  const {
    fusionPlan,
    setFusionPlan,
    setConfigurationLoading
  } = useFusionPlan();

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_PLAN
      // TODO refactor in TTN-1161
    ) {
      setConfigurationLoading(false);
      if (fusionPlan.id !== lastJsonMessage.data.id) {
        history.push({
          pathname: ROUTES.FUSION_PLAN_V2(lastJsonMessage.data.id),
          state: { from: ROUTES.FUSION_PLANS(0) }
        });
      } else {
        setFusionPlan({
          ...fusionPlan,
          ...lastJsonMessage.data
        });
      }
    }
  }, [lastJsonMessage]);

  return <></>;
}
