import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants';
import TitanPage from '../Titan/TitanPage';
import FusionPlanContent from './FusionPlanContent';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import FuseDialog from '../FuseDialog/FuseDialog';
import FusionPlanSocketHandlers from './FusionPlanSocketHandlers';
import FusionPlanService from '../../services/FusionPlanService';
import FusionPlanFavoriteButton from './FusionPlanFavoriteButton';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import FusionPlanDialog from './FusionPlanDialog';
import PlanService from '../../services/PlanService';
import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import SelectMouldsDialog from '../Moulds/SelectMouldsDialog';
import SelectFusionModuleDialog from './SelectFusionModuleDialog';
import useComparableFusionPlans from '../FusionPlans/use-comparable-fusion-plans';

export const FusionPlanContext = React.createContext();
export const useFusionPlan = () => useContext(FusionPlanContext);

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginLeft: theme.spacing(2)
  }
}));

export default function FusionPlanPage() {
  const classes = useStyles();
  const { fusionPlanId, tab = 'configuration' } = useParams();

  const location = useLocation();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [configurationLoading, setConfigurationLoading] = React.useState(false);
  const [fusionPlan, setFusionPlan] = React.useState();
  const [hasFinalParts, setHasFinalParts] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openMouldsDialog, setOpenMouldsDialog] = React.useState(false);
  const fusionJobsTableRef = React.useRef();

  const { currentMemberId } = useAuth0();
  const { addPageToPageHistory, backToPreviousPage } = useTitan();
  const {
    comparablePlans,
    toggleComparablePlan,
    renderCompareButton,
    renderClearButton
  } = useComparableFusionPlans();

  const [openFuseDialog, setOpenFuseDialog] = React.useState(false);
  const [
    openSelectFusionModuleDialog,
    setOpenSelectFusionModuleDialog
  ] = React.useState(false);

  const disableFusionPlanForm = () => {
    setHasFinalParts(true);
  };

  const loadFusionPlan = () => {
    setLoading(true);

    FusionPlanService.getFusionPlan(
      fusionPlanId,
      {
        withRelated: [
          'customFields',
          'customFields.image',
          'member',
          'mould',
          'project'
        ]
      },
      'v2'
    )
      .then(fusionPlan => {
        setFusionPlan(fusionPlan);
        setError(null);
        setHasFinalParts(fusionPlan.hasFinalParts);
        setLoading(false);
      })
      .catch(error => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    loadFusionPlan();
    setOpenFuseDialog(false);
  }, [fusionPlanId]);

  const updateFusionPlan = React.useCallback(
    async data => {
      const updatedFusionPlan = await FusionPlanService.updateFusionPlan(
        fusionPlan.id,
        data,
        'v2'
      );

      setFusionPlan({
        ...fusionPlan,
        ...updatedFusionPlan
      });
    },
    [fusionPlan, setFusionPlan]
  );

  const onSelectMould = React.useCallback(
    mouldId => updateFusionPlan({ mouldId }),
    [updateFusionPlan]
  );

  const updateFusionJobs = fusionJobsToUpdate => {
    if (!fusionJobsTableRef || !fusionJobsTableRef.current) {
      return;
    }

    const fusionJobs = fusionJobsTableRef.current.dataManager.data;

    fusionJobs.forEach((fusionJob, index) => {
      const fusionJobToUpdate = fusionJobsToUpdate.find(
        c => c.id === fusionJob.id
      );

      if (fusionJobToUpdate) {
        fusionJobs[index] = {
          tableData: fusionJobs[index].tableData,
          ...fusionJobToUpdate
        };
      }
    });

    fusionJobsToUpdate.forEach(fj => {
      if (!fusionJobs.find(fusionJob => fusionJob.id === fj.id)) {
        fj.tableData = { id: 0 };

        fusionJobs.unshift(fj);
      }
    });

    fusionJobsTableRef.current.dataManager.data = fusionJobs
      .slice(0, fusionJobsTableRef.current.dataManager.pageSize)
      .map((fj, index) => ({
        ...fj,
        tableData: {
          id: index
        }
      }));
  };

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Fusion Plans',
        path: ROUTES.FUSION_PLANS(0)
      },
      {
        name: fusionPlan ? fusionPlan.name : '',
        disabled: true
      }
    ],
    [fusionPlan]
  );

  const sendSaveConfigRequest = fusionModuleId => {
    return FusionPlanService.sendSaveConfigRequest(
      fusionPlan.id,
      {
        fusionModuleId: fusionModuleId
      },
      'v2'
    );
  };

  const onLoadToFusionModule = async fusionJob => {
    disableFusionPlanForm();
    updateFusionJobs([fusionJob]);
    setOpenFuseDialog(false);
  };

  const onSelectFusionModule = async selectedFusionModule => {
    setConfigurationLoading(true);
    return sendSaveConfigRequest(selectedFusionModule.id);
  };

  return (
    <FusionPlanContext.Provider
      value={{
        fusionPlan,
        setFusionPlan,
        loading,
        openFuseDialog,
        setOpenFuseDialog,
        openSelectFusionModuleDialog,
        setOpenSelectFusionModuleDialog,
        updateFusionJobs,
        configurationLoading,
        setConfigurationLoading,
        fusionJobsTableRef,
        hasFinalParts,
        updateFusionPlan,
        openMouldsDialog,
        setOpenMouldsDialog,
        comparablePlans,
        toggleComparablePlan
      }}
    >
      <TitanPage
        loading={loading}
        error={error}
        breadcrumbs={breadcrumbs}
        title={
          <Stack direction="row" alignItems="center">
            {fusionPlan
              ? `${fusionPlan.name} ${PlanService.formatPlanKey(
                  fusionPlan.planKey,
                  fusionPlan.version
                )}`
              : ''}
            {fusionPlan?.isDefault && (
              <Chip
                label="DEFAULT"
                color="primary"
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
          </Stack>
        }
        onBackButtonClick={() => {
          backToPreviousPage(location);
        }}
        titleContent={
          <IconButton size="large" onClick={() => setOpenEditDialog(true)}>
            <CreateIcon />
          </IconButton>
        }
        headerContent={
          <>
            <FusionPlanFavoriteButton
              fusionPlan={fusionPlan}
              onChange={updatedFusionPlan => setFusionPlan(updatedFusionPlan)}
            />
            {tab === 'history' ? (
              <>
                {renderCompareButton()}
                {renderClearButton()}
              </>
            ) : (
              ''
            )}
            {!fusionPlan?.isDefault && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => updateFusionPlan({ isDefault: true })}
                className={classes.actionButton}
                title="Mark this version as default"
              >
                Set as default
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenFuseDialog(true);
              }}
              disabled={!fusionPlan || !fusionPlan.configuration}
              className={classes.actionButton}
              title="Send Fusion Plan to Fusion Module"
            >
              Fuse
            </Button>
          </>
        }
      >
        <FusionPlanContent
          fusionPlanId={fusionPlanId}
          tab={tab}
          addPageToPageHistory={addPageToPageHistory}
        />

        {openFuseDialog && (
          <FuseDialog
            onLoadToFusionModule={onLoadToFusionModule}
            fusionPlan={fusionPlan}
            onClose={() => setOpenFuseDialog(false)}
          />
        )}

        {openSelectFusionModuleDialog && (
          <SelectFusionModuleDialog
            title="Load Config Request"
            submitButtonLabel="Load Config"
            onSelect={onSelectFusionModule}
            onClose={() => setOpenSelectFusionModuleDialog(false)}
          />
        )}

        {openEditDialog && (
          <FusionPlanDialog
            fusionPlan={fusionPlan}
            onClose={() => {
              setOpenEditDialog(false);
            }}
            currentMemberId={currentMemberId}
            onSave={updateFusionPlan}
          />
        )}

        {openMouldsDialog && (
          <SelectMouldsDialog
            title="Select Fusion Plan mould"
            onClose={() => setOpenMouldsDialog(false)}
            moulds={fusionPlan.mouldId ? [fusionPlan.mould] : []}
            onSelectMould={onSelectMould}
          />
        )}

        <FusionPlanSocketHandlers />
      </TitanPage>
    </FusionPlanContext.Provider>
  );
}
