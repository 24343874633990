import React from 'react';
import useWebSocket from 'react-use-websocket';
import { COMPONENT_STATUSES, WEB_SOCKET_ACTIONS } from '../../constants';
import { useTitan } from '../Titan/Titan';

export default function useFusionModuleStatus(
  fusionModuleId,
  initialStatus = COMPONENT_STATUSES.OFFLINE,
  initialProgress = 0,
  initialDeviceVersion = ''
) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();
  const [status, setStatus] = React.useState(initialStatus);
  const [progress, setProgress] = React.useState(initialProgress);
  const [deviceVersion, setDeviceVersion] = React.useState(
    initialDeviceVersion
  );

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_MODULE_STATE &&
      lastJsonMessage.fusionModuleId === fusionModuleId
    ) {
      setStatus(lastJsonMessage.state);
      setProgress(lastJsonMessage.progress);
      setDeviceVersion(lastJsonMessage.fusionModuleVersion);
    }
  }, [lastJsonMessage]);

  return {
    status,
    setStatus,
    progress,
    setProgress,
    deviceVersion,
    setDeviceVersion
  };
}
