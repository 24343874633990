import React from 'react';
import { COMPONENT_STATUSES } from '../../constants';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { Button } from '@mui/material';

export default function FinalPartActions({
  finalPart,
  onFused,
  onFusionScrap,
}) {
  const [actions, setActions] = React.useState([]);
  const [finalPartToFused, setFinalPartToFused] = React.useState();
  const [finalPartToFusionScrap, setFinalPartToFusionScrap] = React.useState();

  React.useEffect(() => {
    const actions = [];

    if ([COMPONENT_STATUSES.FUSION_SCRAP].includes(finalPart.status)) {
      actions.push({
        color: 'secondary',
        variant: 'outlined',
        label: 'Set as Fused',
        onClick: () => setFinalPartToFused(finalPart),
      });
    }

    if (finalPart.status === COMPONENT_STATUSES.FUSED) {
      actions.push({
        color: 'primary',
        variant: 'outlined',
        label: 'MARK AS FUSION SCRAP',
        onClick: () => setFinalPartToFusionScrap(finalPart),
      });
    }

    setActions(actions);
  }, [finalPart]);

  if (actions.length === 0) {
    return '';
  }

  return (
    <>
      {actions.length
        ? actions.map((action, index) => (
            <Button
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick(e);
              }}
              variant={action.variant}
              color={action.color}
            >
              {action.label}
            </Button>
          ))
        : ''}

      {finalPartToFused && (
        <TitanConfirmationDialog
          title={`Set final part ${finalPart.id} as fused`}
          message={`Are you sure you want to set final part ${finalPart.id} as fused?`}
          onConfirm={async () => {
            await onFused(finalPart);
          }}
          onClose={() => setFinalPartToFused(null)}
        />
      )}

      {finalPartToFusionScrap && (
        <TitanConfirmationDialog
          title={`Mark final part ${finalPart.id} as fusion scrap`}
          message={`Are you sure you want to mark final part ${finalPart.id} as fusion scrap?`}
          onConfirm={async () => {
            await onFusionScrap(finalPart);
          }}
          onClose={() => setFinalPartToFusionScrap(null)}
        />
      )}
    </>
  );
}
