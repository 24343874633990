import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { useAuth0 } from '../Authentication/Auth0';
import { Grid, Paper, Link } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import ProgressBar from '../Fibrify/FibrifyProgressBar';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import { PERMISSIONS } from '../../constants/auth0';
import ProjectLink from '../Projects/ProjectLink';
import { format } from 'date-fns';

function ManufacturingOrderSummary({
  manufacturingOrder,
  organization,
  project
}) {
  const { can } = useAuth0();

  const canSyncOdoo = React.useMemo(() => can([PERMISSIONS.ODOO_SYNC]), [can]);

  const [builtPreformsCount, setBuiltPreformsCount] = React.useState([]);
  const [fusedFinalPartsCount, setFusedFinalPartsCount] = React.useState([]);

  useAsyncEffect(async () => {
    const { builtPreforms } = await ManufacturingOrderService.getPreformsCount(
      manufacturingOrder?.id
    );

    const {
      fusedFinalParts
    } = await ManufacturingOrderService.getFinalPartsCount(
      manufacturingOrder?.id
    );

    setBuiltPreformsCount(builtPreforms);
    setFusedFinalPartsCount(fusedFinalParts);
  }, [manufacturingOrder?.id]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Project">
                <ProjectLink project={project} />
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Start date">
                {manufacturingOrder.startDate
                  ? format(new Date(manufacturingOrder.startDate), 'yyyy-MM-dd')
                  : '-'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="End date">
                {manufacturingOrder.finishDate
                  ? format(
                      new Date(manufacturingOrder.finishDate),
                      'yyyy-MM-dd'
                    )
                  : '-'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Order Key">
                {manufacturingOrder.orderKey}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Customer Name">
                {manufacturingOrder.customerName}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem
                label={`Number of ${
                  manufacturingOrder.preformsCount ? 'Preforms' : 'Final Parts'
                }`}
              >
                {manufacturingOrder.preformsCount
                  ? manufacturingOrder.preformsCount
                  : manufacturingOrder.finalPartsCount}
              </TitanInfoItem>
            </Grid>
            {manufacturingOrder.odooManufacturingOrderId && canSyncOdoo && (
              <Grid item xs={4}>
                <TitanInfoItem label="Odoo">
                  <Link
                    href={manufacturingOrder.odooManufacturingOrderPageUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    {manufacturingOrder.odooManufacturingOrderName}
                  </Link>
                </TitanInfoItem>
              </Grid>
            )}
            {manufacturingOrder.preformsCount ? (
              <Grid item xs={4}>
                <TitanInfoItem label="Spool tags">
                  {manufacturingOrder.spoolTags &&
                  manufacturingOrder.spoolTags.length !== 0
                    ? manufacturingOrder.spoolTags
                        .map(tag => tag.name)
                        .join(', ')
                    : '-'}
                </TitanInfoItem>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Paper>
      </Grid>

      {manufacturingOrder.preformsCount ? (
        <Grid item xs={12}>
          <ProgressBar
            name="Preforms Progress"
            currentValue={builtPreformsCount}
            maxValue={manufacturingOrder.preformsCount}
            zeroMaxValueMessage="No Preforms connected to this Manufacturing Order yet"
          />
        </Grid>
      ) : (
        ''
      )}

      {manufacturingOrder.finalPartsCount ? (
        <Grid item xs={12}>
          <ProgressBar
            name="Final Parts Progress"
            currentValue={fusedFinalPartsCount}
            maxValue={manufacturingOrder.finalPartsCount}
            zeroMaxValueMessage="No Final Parts connected to this Manufacturing Order yet"
          />
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
}

export default ManufacturingOrderSummary;
