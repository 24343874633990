/* eslint-disable import/no-anonymous-default-export */
export default {
  BUILD_JOB: (buildJobId = ':buildJobId') => `/build-jobs/${buildJobId}`,
  BUILD_JOB_TAB: (
    buildJobId = ':buildJobId',
    tab = ':tab?',
    action = ':action?'
  ) => `/build-jobs/${buildJobId}/${tab}/${action}`,
  BUILD_JOB_TAB_PAGE: (
    buildJobId = ':buildJobId',
    tab = ':tab?',
    page = ':page?',
    action = ':action?'
  ) => `/build-jobs/${buildJobId}/${tab}/${page}/${action}`,
  BUILD_PLAN_V2: (buildPlanId = ':buildPlanId') =>
    `/build-plans/v2/${buildPlanId}`,
  BUILD_PLAN_V2_TAB: (buildPlanId = ':buildPlanId', tab = ':tab?') =>
    `/build-plans/v2/${buildPlanId}/${tab}`,
  BUILD_PLAN_V2_TAB_PAGE: (
    buildPlanId = ':buildPlanId',
    tab = ':tab?',
    page = ':page?'
  ) => `/build-plans/v2/${buildPlanId}/${tab}/${page}`,
  FUSION_JOB: (fusionJobId = ':fusionJobId') => `/fusion-jobs/${fusionJobId}`,
  FUSION_JOB_TAB: (fusionJobId = ':fusionJobId', tab = ':tab?') =>
    `/fusion-jobs/${fusionJobId}/${tab}`,
  FUSION_JOB_TAB_PAGE: (
    fusionJobId = ':fusionJobId',
    tab = ':tab?',
    page = ':page?'
  ) => `/fusion-jobs/${fusionJobId}/${tab}/${page}`,
  FUSION_PLAN_V2: (fusionPlanId = ':fusionPlanId') =>
    `/fusion-plans/v2/${fusionPlanId}`,
  FUSION_PLAN_V2_TAB: (fusionPlanId = ':fusionPlanId', tab = ':tab?') =>
    `/fusion-plans/v2/${fusionPlanId}/${tab}`,
  FUSION_PLAN_V2_TAB_PAGE: (
    fusionPlanId = ':fusionPlanId',
    tab = ':tab?',
    page = ':page?'
  ) => `/fusion-plans/v2/${fusionPlanId}/${tab}/${page}`,
  PRODUCTION_EVALUATION_FORM: (
    general = true,
    productionEvaluationId = ':productionEvaluationId'
  ) =>
    `/production-evaluation-form${general ? '' : `/${productionEvaluationId}`}`,
  WELCOME: '/welcome',
  VERIFY_EMAIL: '/verify-email',
  ADMIN_DASHBOARD: '/admin-dashboard',
  ADMIN_DASHBOARD_TAB: (tabName = ':tabName') => `/admin-dashboard/${tabName}`,
  ADMIN_DASHBOARD_TAB_PAGE: (tabName = ':tabName', page = ':page?') =>
    `/admin-dashboard/${tabName}/${page}`,
  ORGANIZATION: (organizationId = ':organizationId') =>
    `/organizations/${organizationId}`,
  ORGANIZATION_TAB: (
    organizationId = ':organizationId',
    tabName = ':tabName'
  ) => `/organizations/${organizationId}/${tabName}`,
  ORGANIZATION_TAB_PAGE: (
    organizationId = ':organizationId',
    tabName = ':tabName',
    page = ':page?'
  ) => `/organizations/${organizationId}/${tabName}/${page}`,
  PRE_BUILDING_CHECKLIST: (
    preBuildingChecklistId = ':preBuildingChecklistId'
  ) => `/pre-building-checklists/${preBuildingChecklistId}`,
  HOME_PAGE: '/',
  HOME: '/',
  DEVICES: (deviceType = ':deviceType') => `/devices/${deviceType}`,
  BUILD_MODULES: '/build-modules',
  FUSION_MODULES: '/fusion-modules',
  DASHBOARD: '/dashboard',
  BUILD_PLANS: (page = ':page?') =>
    parseInt(page, 10) === 0
      ? `/build-plans`
      : `/build-plans/${page === ':page?' ? ':pageKey?' : 'page'}/${page}`,
  FUSION_PLANS: (page = ':page?') =>
    parseInt(page, 10) === 0
      ? `/fusion-plans`
      : `/fusion-plans/${page === ':page?' ? ':pageKey?' : 'page'}/${page}`,
  BUILD_MODULE: (buildModuleId = ':buildModuleId') =>
    `/build-modules/${buildModuleId}/`,
  BUILD_MODULE_TAB: (buildModuleId = ':buildModuleId', tab = ':tab?') =>
    `/build-modules/${buildModuleId}/${tab}`,
  BUILD_MODULE_TAB_PAGE: (
    buildModuleId = ':buildModuleId',
    tab = ':tab?',
    page = ':page?'
  ) => `/build-modules/${buildModuleId}/${tab}/${page}`,
  FUSION_MODULE: (fusionModuleId = ':fusionModuleId') =>
    `/fusion-modules/${fusionModuleId}/`,
  FUSION_MODULE_TAB: (fusionModuleId = ':fusionModuleId', tab = ':tab?') =>
    `/fusion-modules/${fusionModuleId}/${tab}`,
  FUSION_MODULE_TAB_PAGE: (
    fusionModuleId = ':fusionModuleId',
    tab = ':tab?',
    page = ':page?'
  ) => `/fusion-modules/${fusionModuleId}/${tab}/${page}`,
  FDS_RELEASES: '/fds-releases',
  FDS_RELEASES_PAGE: (page = ':page?') => `/fds-releases/${page}`,
  HISTORY: (page = ':page?') =>
    parseInt(page, 10) === 0
      ? `/history`
      : `/history/${page === ':page?' ? ':pageKey?' : 'page'}/${page}`,
  FINAL_PART: (finalPartId = ':finalPartId') => `/final-parts/${finalPartId}`,
  FINAL_PART_TAB: (finalPartId = ':finalPartId', tab = ':tab?') =>
    `/final-parts/${finalPartId}/${tab}`,
  FINAL_PART_TAB_PAGE: (
    finalPartId = ':finalPartId',
    tab = ':tab?',
    page = ':page?'
  ) => `/final-parts/${finalPartId}/${tab}/${page}`,
  PREFORM: (preformId = ':preformId') => `/preforms/${preformId}`,
  PREFORM_TAB: (preformId = ':preformId', tab = ':tab?') =>
    `/preforms/${preformId}/${tab}`,
  PRESET: (presetId = ':presetId') => `/presets/${presetId}`,
  SPOOL: (spoolId = ':spoolId') => `/spools/${spoolId}`,
  SPOOL_TAB: (spoolId = ':spoolId', tab = ':tab') =>
    `/spools/${spoolId}/${tab}`,
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  LICENSES_OPEN_SOURCE: '/licenses/open-source',
  NEW_TERMS_AND_CONDITIONS: '/terms-and-conditions/new',
  TERMS_AND_CONDITIONS_SINGLE: (
    termsAndConditionsId = ':termsAndConditionsId'
  ) => `/terms-and-conditions/${termsAndConditionsId}`,
  PROFILE: '/profile',
  SUPPORT: '/support',
  GEAR: '/gear',
  GEAR_TAB: (tabName = ':tabName') => `/gear/${tabName}`,
  GEAR_TAB_PAGE: (tabName = ':tabName', page = ':page?') =>
    `/gear/${tabName}/${page}`,
  PROJECTS: (page = ':page?') => `/projects`,
  PROJECTS_PAGE: (page = ':page?') =>
    parseInt(page, 10) === 0 ? `/projects` : `/projects/page/${page}`,
  PROJECT: (projectId = ':projectId') => `/projects/${projectId}`,
  PROJECT_TAB: (projectId = ':projectId', tab = ':tab?') =>
    `/projects/${projectId}/${tab}`,
  PLANS_COMPARSION: '/compare-plans',
  MANUFACTURING_ORDER: (manufacturingOrderId = ':manufacturingOrderId') =>
    `/manufacturing-orders/${manufacturingOrderId}`,
  MANUFACTURING_ORDER_TAB: (
    manufacturingOrderId = ':manufacturingOrderId',
    tab = ':tab?'
  ) => `/manufacturing-orders/${manufacturingOrderId}/${tab}`
};
