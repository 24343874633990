import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import { useAuth0 } from '../Authentication/Auth0';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { Typography, Box } from '@mui/material';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import BuildPlanDialog from '../BuildPlan/BuildPlanDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import BuildPlanShareDialog from '../BuildPlan/BuildPlanShareDialog';
import MemberSelectionDialog from '../Fibrify/FibrifyMemberSelectionDialog';
import BuildPlanSelectionDialog from '../Fibrify/FibrifyBuildPlanSelectionDialog';
import BuildPlanThumbnail from '../BuildPlan/BuildPlanThubnail';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import BuildPlanTags from '../BuildPlan/BuildPlanTags';
import BuildPlanService from '../../services/BuildPlanService';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import {
  ROUTES,
  ROLES,
  SORT_ORDERS,
  STYLING_FUNCTIONS,
  BUILD_PLAN_SHARE_TYPES
} from '../../constants';
import { colors } from '../Theme/vars';

export default function BuildPlansTable({
  projectId,
  manufacturingOrderId,
  manufacturingOrdersProjectId,
  addBuildPlanToProjectButton = false,
  addBuildPlanToManufacturingOrderButton = false
}) {
  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();
  const { currentMemberId, roles, isOrganizationAdmin } = useAuth0();
  const { pushSnackbar } = useTitan();

  const [openBuildPlanDialog, setOpenBuildPlanDialog] = React.useState(false);
  const [openAddToProjectDialog, setOpenAddToProjectDialog] = React.useState(
    false
  );
  const [
    openAddToManufacturingOrderDialog,
    setOpenAddToManufacturingOrderDialog
  ] = React.useState(false);
  const [
    openRemoveFromProjectDialog,
    setOpenRemoveFromProjectDialog
  ] = React.useState(false);
  const [
    openRemoveFromManufacturingOrderDialog,
    setOpenRemoveFromManufacturingOrderDialog
  ] = React.useState(false);
  const [openAssignMemberDialog, setOpenAssignMemberDialog] = React.useState(
    false
  );
  const [editableBuildPlan, setEditableBuildPlan] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);

  const [sharedMembersIds, setSharedMembersIds] = React.useState([]);

  const [deletingPlanHasPreforms, setDeletingPlanHasPreforms] = React.useState(
    false
  );

  const openAssignedToDialog = React.useCallback(async buildPlan => {
    setEditableBuildPlan(buildPlan);
    if (buildPlan.shareType === BUILD_PLAN_SHARE_TYPES.MEMBERS) {
      const sharedMembers = await BuildPlanService.getBuildPlanSharedMembers(
        buildPlan.id
      );
      setSharedMembersIds(sharedMembers.map(m => m.id));
    } else {
      setSharedMembersIds([]);
    }
    setOpenAssignMemberDialog(true);
  }, []);

  React.useEffect(() => {
    console.log('render');
  }, []);

  const loadData = React.useCallback(
    async (params, config) => {
      const { data, pagination } = await BuildPlanService.getBuildPlans(
        {
          ...params,
          withRelated: ['tags', 'files', 'components', 'assignee'],
          ...(projectId && { projectId }),
          ...(manufacturingOrderId && {
            manufacturingOrderIds: [manufacturingOrderId]
          })
        },
        'v2',
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [projectId, manufacturingOrderId]
  );

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Preview',
        field: 'preview',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <BuildPlanThumbnail buildPlan={row} />
      },
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 300,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) => (
          <Box>
            <BuildPlanLink buildPlan={row} width={colDef.computedWidth} />
            <BuildPlanTags buildPlan={row} width={colDef.computedWidth} />
          </Box>
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.BUILD_PLAN_V2(row.id),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Tags',
        field: 'tags',
        minWidth: 300,
        sortable: false,
        visibilityBreakpoint: 'lg',
        valueGetter: ({ row }) => (row.tags ? row.tags.join(', ') : '')
      },
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 50,
        visibilityBreakpoint: 'lg',
        sortable: false
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: 'Share Type',
        field: 'shareType',
        minWidth: 150,
        visibilityBreakpoint: 'lg',
        sortable: false
      }
    ];

    if (
      roles.map(r => r.id).includes(ROLES.ADMIN) ||
      roles.map(r => r.id).includes(ROLES.APPLICATION_ENGINEER)
    ) {
      columns.push({
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 100,
        sortable: false,
        hideInMenu: true,
        visibilityBreakpoint: 'sm',
        getActions: params =>
          BuildPlanService.getBuildPlanActions(
            params.row,
            currentMemberId,
            roles,
            {
              onRename: () => {
                setEditableBuildPlan(params.row);
                setOpenBuildPlanDialog(true);
              },
              onShare: () => {
                setEditableBuildPlan(params.row);
                setOpenShareDialog(true);
              },
              onDelete: () => {
                setEditableBuildPlan(params.row);
                setDeletingPlanHasPreforms(params.row.components.length > 0);
                setOpenDeleteDialog(true);
              },
              onToggleFavorite: buildPlan => updateBuildPlan(buildPlan),
              onAssignTo: () => {
                openAssignedToDialog(params.row);
              },
              onRemoveFromProject: projectId
                ? () => {
                    setEditableBuildPlan(params.row);
                    setOpenRemoveFromProjectDialog(true);
                  }
                : null,
              onRemoveFromManufacturingOrder: manufacturingOrderId
                ? () => {
                    setEditableBuildPlan(params.row);
                    setOpenRemoveFromManufacturingOrderDialog(true);
                  }
                : null
            }
          ).map(action =>
            !action.label ? (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                showInMenu={false}
              />
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                disabled={action.disabled}
                showInMenu
              />
            )
          )
      });
    }

    return columns;
  }, []);

  const gridOptionsObject = {
    orders: {
      created_at: SORT_ORDERS.DESC
    },
    columns,
    pinnedColumns: {
      left: ['deleting', 'preview', 'name'],
      right: ['actions']
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptionsObject.page = Number(pageParam);
  }
  const titanDataGridProps = useTitanDataGrid(loadData, gridOptionsObject);

  const { rows, setRows, reloadData } = titanDataGridProps;

  const updateBuildPlan = React.useCallback(
    buildPlan =>
      setRows(prev =>
        prev.map(bp => (bp.id === buildPlan.id ? { ...bp, ...buildPlan } : bp))
      ),
    [rows]
  );

  const onUpdateBuildPlan = React.useCallback(
    async name => {
      const updatedBuildPlan = await BuildPlanService.updateBuildPlan(
        editableBuildPlan.id,
        {
          name
        },
        'v2'
      );

      setOpenBuildPlanDialog(false);
      updateBuildPlan(updatedBuildPlan);
    },
    [editableBuildPlan, updateBuildPlan]
  );

  const onAddingBuildPlansToProject = React.useCallback(
    async buildPlans => {
      await Promise.allSettled(
        buildPlans.map(buildPlan =>
          BuildPlanService.updateBuildPlan(
            buildPlan.id,
            {
              projectId
            },
            'v2'
          )
        )
      );

      await reloadData();
    },
    [projectId, reloadData]
  );

  const onRemoveFromProject = React.useCallback(async () => {
    await BuildPlanService.updateBuildPlan(
      editableBuildPlan.id,
      {
        projectId: null
      },
      'v2'
    );

    await reloadData();
  }, [editableBuildPlan, reloadData]);

  const onRemoveFromManufacturingOrder = React.useCallback(async () => {
    await ManufacturingOrderService.detachBuildPlan(
      manufacturingOrderId,
      editableBuildPlan.id
    );

    await reloadData();
  }, [manufacturingOrderId, editableBuildPlan, reloadData]);

  const onAddingBuildPlansToManufacturingOrder = React.useCallback(
    async buildPlans => {
      await Promise.allSettled(
        buildPlans.map(buildPlan =>
          ManufacturingOrderService.attachBuildPlan(
            manufacturingOrderId,
            buildPlan.id
          )
        )
      );

      await reloadData();
    },
    [manufacturingOrderId, reloadData]
  );

  const onDelete = React.useCallback(async () => {
    await BuildPlanService.deleteBuildPlan(editableBuildPlan.id, 'v2');

    pushSnackbar('Build Plan successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);

    setRows(prev => prev.filter(bp => bp.id !== editableBuildPlan.id));
  }, [editableBuildPlan, pushSnackbar, setRows]);

  const onAssignToBuildPlan = React.useCallback(
    async member => {
      const assigneeId = member ? member.id : null;

      await BuildPlanService.updateBuildPlan(
        editableBuildPlan.id,
        {
          assigneeId
        },
        'v2'
      );

      setRows(prev =>
        prev.map(bp =>
          bp.id === editableBuildPlan.id ? { ...bp, assigneeId } : bp
        )
      );
    },
    [editableBuildPlan, setRows]
  );

  return (
    <Box>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel
        }}
        getRowHeight={STYLING_FUNCTIONS.MAKE_ROW_HEIGHT(110)}
        searchPlaceholder="Search by Build Plan name..."
        {...addBuildPlanToProjectButton && {
          onCreateClick: () => setOpenAddToProjectDialog(true),
          createButtonLabel: 'Build Plan'
        }}
        {...addBuildPlanToManufacturingOrderButton && {
          onCreateClick: () => setOpenAddToManufacturingOrderDialog(true),
          createButtonLabel: 'Build Plan'
        }}
      />

      {openBuildPlanDialog && (
        <BuildPlanDialog
          buildPlan={editableBuildPlan}
          onClose={() => {
            setOpenBuildPlanDialog(false);
            setEditableBuildPlan({});
          }}
          currentMemberId={currentMemberId}
          onUpdate={onUpdateBuildPlan}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Build Plan?"
          message={`Are you sure you want to delete Build Plan?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setDeletingPlanHasPreforms(false);
            setEditableBuildPlan({});
          }}
          onConfirm={() => onDelete()}
        >
          {deletingPlanHasPreforms && (
            <Typography sx={{ color: colors.LIGHT_RED }}>
              {`This Build Plan contains preforms!`}
            </Typography>
          )}
        </TitanConfirmationDialog>
      )}

      {openAddToProjectDialog && (
        <BuildPlanSelectionDialog
          title="Select Build Plans that you want to add to the Project"
          saveButtonLabel="Add"
          multipleSelection
          filters={{ withoutProject: true }}
          onSave={onAddingBuildPlansToProject}
          onClose={() => setOpenAddToProjectDialog(false)}
          isRowSelectable={({ row }) =>
            isOrganizationAdmin || row.memberId === currentMemberId
          }
        />
      )}

      {openRemoveFromProjectDialog && (
        <TitanConfirmationDialog
          title="Remove this Build Plan from Project?"
          message="Are you sure you want to remove this Build Plan from Project?"
          onClose={() => {
            setOpenRemoveFromProjectDialog(false);
          }}
          onConfirm={() => onRemoveFromProject()}
        />
      )}

      {openRemoveFromManufacturingOrderDialog && (
        <TitanConfirmationDialog
          title="Remove this Build Plan from Manufacturing Order?"
          message="Are you sure you want to remove this Build Plan from Manufacturing Order?"
          onClose={() => {
            setOpenRemoveFromManufacturingOrderDialog(false);
          }}
          onConfirm={() => onRemoveFromManufacturingOrder()}
        />
      )}

      {openAddToManufacturingOrderDialog && (
        <BuildPlanSelectionDialog
          title="Select Build Plans that you want to add to this Manufacturing Order"
          saveButtonLabel="Add"
          multipleSelection
          filters={{
            onlyApproved: true,
            ...(manufacturingOrdersProjectId && {
              projectIds: [manufacturingOrdersProjectId]
            }),
            ...(manufacturingOrderId && {
              excludeManufacturingOrderIds: [manufacturingOrderId]
            })
          }}
          onSave={onAddingBuildPlansToManufacturingOrder}
          onClose={() => setOpenAddToManufacturingOrderDialog(false)}
          isRowSelectable={({ row }) =>
            isOrganizationAdmin || row.memberId === currentMemberId
          }
        />
      )}

      {openShareDialog && (
        <BuildPlanShareDialog
          buildPlan={editableBuildPlan}
          onClose={() => {
            setOpenShareDialog(false);
          }}
          onSave={buildPlan => {
            setOpenShareDialog(false);
            updateBuildPlan(buildPlan);
          }}
        />
      )}

      {openAssignMemberDialog && (
        <MemberSelectionDialog
          title="Select member which you want to assign to this Build Plan"
          multipleSelection={false}
          currentMembers={
            editableBuildPlan.assignee ? [editableBuildPlan.assignee] : []
          }
          includeMemberIds={sharedMembersIds}
          onSave={onAssignToBuildPlan}
          onClose={() => setOpenAssignMemberDialog(false)}
        />
      )}
    </Box>
  );
}
