export const PERMISSIONS = {
  // Auth0 scopes
  OPENID: 'openid',
  PROFILE: 'profile',
  EMAIL: 'email',

  // printers
  CREATE_BUILD_MODULE: 'c:bm',
  READ_BUILD_MODULE: 'r:bm',
  UPDATE_BUILD_MODULE: 'u:bm',
  UPDATE_BUILD_MODULE_NOTES: 'update_notes:bm',
  DELETE_BUILD_MODULE: 'd:bm',

  // fusors
  CREATE_FUSION_MODULE: 'c:fm',
  READ_FUSION_MODULE: 'r:fm',
  UPDATE_FUSION_MODULE: 'u:fm',
  DELETE_FUSION_MODULE: 'd:fm',

  CHANGE_DEVICE_ORGANIZATION: 'change_organization:device',

  // ORGANIZATIONS
  CREATE_ORGANIZATION: 'c:organization',
  READ_ORGANIZATIONS: 'r:organizations',
  READ_ORGANIZATION: 'r:organization',
  UPDATE_ORGANIZATION: 'u:organization',
  DELETE_ORGANIZATION: 'd:organization',

  // ORGANIZATION ROLES
  READ_ORGANIZATION_ROLES: 'r:organization_roles',
  UPDATE_ORGANIZATION_ROLES: 'u:organization_roles',

  // pre building checklists
  CREATE_PRE_BUILDING_CHECKLIST: 'c:pb_checklist',
  READ_PRE_BUILDING_CHECKLIST: 'r:pb_checklist',
  READ_PRE_BUILDING_CHECKLISTS: 'r:pb_checklists',
  UPDATE_PRE_BUILDING_CHECKLIST: 'u:pb_checklist',
  DELETE_PRE_BUILDING_CHECKLIST: 'd:pb_checklist',

  // projects
  READ_PROJECTS: 'r:projects',
  WRITE_PROJECTS: 'w:projects',

  // manufacturing orders
  READ_MANUFACTURING_ORDERS: 'r:manufacturing_orders',
  WRITE_MANUFACTURING_ORDERS: 'w:manufacturing_orders',

  // build plans
  CREATE_BUILD_PLAN: 'c:build_plan',
  READ_BUILD_PLANS: 'r:build_plans',
  READ_BUILD_PLAN: 'r:build_plan',
  UPDATE_BUILD_PLAN: 'u:build_plan',
  DELETE_BUILD_PLAN: 'd:build_plan',
  SHARE_BUILD_PLAN: 'share:build_plan',

  // fusion plans
  CREATE_FUSION_PLAN: 'c:fusion_plan',
  READ_FUSION_PLANS: 'r:fusion_plans',
  READ_FUSION_PLAN: 'r:fusion_plan',
  UPDATE_FUSION_PLAN: 'u:fusion_plan',
  DELETE_FUSION_PLAN: 'd:fusion_plan',
  FUSE_FUSION_PLAN: 'fuse:fusion_plan',

  // components
  MANAGE_COMPONENTS: 'manage:components',

  // assemblies
  MANAGE_ASSEMBLIES: 'manage:assemblies',
  UPDATE_ASSEMBLIES: 'u:assemblies',

  // presets
  READ_PRESETS: 'r:presets',
  WRITE_PRESETS: 'w:presets',

  // MEMBERS
  CREATE_MEMBER: 'c:member',
  READ_MEMBERS: 'r:members',
  READ_MEMBER: 'r:member',
  UPDATE_MEMBER: 'u:member',
  DELETE_MEMBER: 'd:member',

  // ORGANIZATION MEMBERS
  CREATE_ORGANIZATION_MEMBER: 'c:organization_member',
  READ_ORGANIZATION_MEMBERS: 'r:organization_members',
  READ_ORGANIZATION_MEMBER: 'r:organization_member',
  UPDATE_ORGANIZATION_MEMBER: 'u:organization_member',
  DELETE_ORGANIZATION_MEMBER: 'd:organization_member',

  // terms and conditions
  CREATE_TERMS_AND_CONDITIONS: 'c:terms_and_conditions',
  MANAGE_TERMS_AND_CONDITIONS: 'manage:terms_and_conditions',

  // TODO remove after refactoring
  MANAGE_MEMBERS: 'manage:members',
  MANAGE_ASSIGNEES: 'manage:assignees',

  EDIT_PROFILE: 'edit:profile',
  MANAGE_FILES: 'manage:files',

  PUSH_MQTT_MESSAGES: 'push:mqtt_messages',
  PUSH_NOTIFICATIONS: 'push:notifications',
  READ_ALL_PRODUCTION_EVALUATIONS: 'read_all:production_evaluations',

  MANAGE_PRINTER_SERIES: 'manage:printer_series',

  READ_PRINTER_MODELS: 'r:printer_models',
  MANAGE_PRINTER_MODELS: 'manage:printer_models',

  MANAGE_FDS_RELEASES: 'manage:fds_releases',
  READ_FDS_RELEASES: 'r:fds_releases',

  MANAGE_MATERIAL_TYPES: 'manage:material_types',
  READ_MATERIAL_TYPES: 'r:material_types',

  MANAGE_SPOOLS: 'manage:spools',
  MANAGE_MOULDS: 'manage:moulds',
  MANAGE_PRINT_HEADS: 'manage:print_heads',

  LOGIN_AS_MEMBER: 'login_as:member',
  LOGOUT_AS_MEMBER: 'logout_as:member',

  ODOO_SYNC: 'sync:odoo',

  TRACK_ACTIVITY: 'track:activity',

  READ_TAGS: 'read:tags'
};

export const AUTH0_SCOPES = Object.values(PERMISSIONS).join(' ');
