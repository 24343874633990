import React, { useContext } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import FusorService from '../../services/FusorService';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import ScanComponentDialog from '../BuildPlan/ScanComponentDialog';
import { useTitan } from '../Titan/Titan';
import FuseDialogFusionModulesStep from './FuseDialogFusionModulesStep';
import DialogManufacturingOrdersStep from '../ManufacturingOrders/DialogManufacturingOrdersStep';
import FuseDialogPreformsStep from './FuseDialogPreformsStep';
import FuseDialogReviewStep from './FuseDialogReviewStep';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../assets/icons/cross-dark.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import FusionPlanService from '../../services/FusionPlanService';
import FuseDialogPreFusionCustomFieldsStep from './FuseDialogPreFusionCustomFieldsStep';
import FinalPartService from '../../services/FinalPartService';
import useAsyncEffect from 'use-async-effect';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';

const STEP_KEYS = {
  SELECT_FUSION_MODULE: 'SELECT_FUSION_MODULE',
  SELECT_MANUFACTURING_ORDER: 'SELECT_MANUFACTURING_ORDER',
  SELECT_PREFORMS: 'SELECT_PREFORMS',
  PRE_FUSION_CUSTOM_FIELDS: 'PRE_FUSION_CUSTOM_FIELDS',
  REVIEW: 'REVIEW'
};

export const FuseDialogContext = React.createContext();
export const useFuseDialog = () => useContext(FuseDialogContext);

export default function FuseDialog({
  fusionPlan,
  onLoadToFusionModule,
  onClose
}) {
  const location = useLocation();
  const { addPageToPageHistory, pushSnackbar } = useTitan();

  const [loading, setLoading] = React.useState(false);
  const [fusionModules, setFusionModules] = React.useState([]);
  const [selectedFusionModule, setSelectedFusionModule] = React.useState();
  const [fusionPlanCustomFields, setFusionPlanCustomFields] = React.useState(
    []
  );
  const [finalPartCustomFields, setFinalPartCustomFields] = React.useState([]);

  const [
    selectedManufacturingOrder,
    setSelectedManufacturingOrder
  ] = React.useState();
  const [
    manufacturingOrdersAvailable,
    setManufacturingOrdersAvailable
  ] = React.useState(false);
  const [selectedPreforms, setSelectedPreforms] = React.useState([]);
  const [selectedMould, setSelectedMould] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openScanDialog, setOpenScanDialog] = React.useState(false);
  const [isExternalHeating, setIsExternalHeating] = React.useState(false);
  const [isAutomatic, setIsAutomatic] = React.useState(true);
  const [isPreformConfig, setIsPreformConfig] = React.useState(true);
  const [isMouldSetup, setIsMouldSetup] = React.useState(true);
  const [isMaterialSetup, setIsMaterialSetup] = React.useState(true);
  const [
    isValidAdditionalCondition,
    setIsValidAdditionalCondition
  ] = React.useState(true);
  const [
    openFuseConfirmationDialog,
    setOpenFuseConfirmationDialog
  ] = React.useState(false);

  const [fuseLoading, setFuseLoading] = React.useState(false);

  const [steps, setSteps] = React.useState([]);

  React.useEffect(() => {
    setSteps([
      {
        key: STEP_KEYS.SELECT_FUSION_MODULE,
        label: 'Select Fusion Module'
      },
      {
        key: STEP_KEYS.SELECT_MANUFACTURING_ORDER,
        label: 'Select Manufacturing Order',
        disabled: !fusionPlan.projectId || !manufacturingOrdersAvailable,
        disableReason: !fusionPlan.projectId
          ? 'Fusion Plan is not connected to any Project'
          : !manufacturingOrdersAvailable
          ? "Fusion Plan's Project does not have any Manufacturing Orders"
          : null
      },
      {
        key: STEP_KEYS.SELECT_PREFORMS,
        label: 'Select Preforms'
      },
      {
        key: STEP_KEYS.PRE_FUSION_CUSTOM_FIELDS,
        label: 'Custom fields',
        disabled: fusionPlanCustomFields.length === 0,
        disableReason:
          fusionPlanCustomFields.length === 0
            ? 'Fusion plan does not have any custom fields'
            : null
      },
      {
        key: STEP_KEYS.REVIEW,
        label: 'Review'
      }
    ]);
  }, [fusionPlanCustomFields, fusionPlan, manufacturingOrdersAvailable]);

  React.useEffect(() => {
    setLoading(true);

    FusorService.getFusors({
      withRelated: [
        'activeJobs',
        'activeJobs.fusionPlan',
        'activeJobs.assemblies',
        'prevFusionJob',
        'prevFusionJob.fusionPlan',
        'prevFusionJob.assemblies'
      ]
    }).then(response => {
      setLoading(false);
      setFusionModules(response.data);
    });
  }, []);

  useAsyncEffect(async () => {
    const manufacturingOrders = await ManufacturingOrderService.getManufacturingOrders(
      {
        fusionPlanIds: [fusionPlan.id],
        pageSize: 1
      }
    );

    setManufacturingOrdersAvailable(
      manufacturingOrders.pagination.totalCount !== 0
    );
  }, [fusionPlan]);

  const hasActiveJob = React.useMemo(() => {
    return (
      selectedFusionModule &&
      selectedFusionModule.activeJobs &&
      selectedFusionModule.activeJobs.length !== 0
    );
  }, [selectedFusionModule]);

  const hasActiveFusingJob = React.useMemo(() => {
    if (
      selectedFusionModule &&
      selectedFusionModule.activeJobs &&
      selectedFusionModule.activeJobs.length !== 0
    ) {
      const fusingJob = selectedFusionModule.activeJobs.find(
        fj => fj.status === COMPONENT_STATUSES.FUSING
      );

      return !!fusingJob;
    }

    return false;
  }, [selectedFusionModule]);

  const messageForPush = React.useMemo(() => {
    return (
      <>
        {`Fusion Plan ${fusionPlan.name} ${
          hasActiveFusingJob ? 'added' : 'sent'
        } to`}
        <Link
          to={{
            pathname: ROUTES.FUSION_MODULE_TAB(
              selectedFusionModule?.id,
              'fusionJobs'
            ),
            state: { from: location.pathname }
          }}
          sx={{
            color: '#fff',
            textDecorationColor: '#fff',
            fontWeight: 'bold',
            margin: '0 5px'
          }}
          component={RouterLink}
          onClick={() =>
            addPageToPageHistory({
              id: `FUSION_MODULE:${selectedFusionModule?.id}`,
              url: ROUTES.FUSION_MODULE_TAB(
                selectedFusionModule?.id,
                'fusionJobs'
              ),
              label: `${selectedFusionModule?.name ||
                'Fusion Module'} | Fusion Jobs`
            })
          }
        >
          {selectedFusionModule?.name}
        </Link>
        {hasActiveFusingJob ? ' queue' : ''}
      </>
    );
  }, [
    fusionPlan.name,
    selectedFusionModule,
    location.pathname,
    addPageToPageHistory,
    hasActiveFusingJob
  ]);

  const sendToFusionModule = React.useCallback(async () => {
    const data = {
      fusionModuleId: selectedFusionModule.id,
      preformIds: selectedPreforms.map(p => p.id),
      isExternalHeating,
      isAutomatic,
      isMouldSetup,
      isMaterialSetup,
      mouldId: selectedMould ? selectedMould.id : null,
      manufacturingOrderId: selectedManufacturingOrder
        ? selectedManufacturingOrder.id
        : null,
      finalPartCustomFields: finalPartCustomFields
        ? Object.values(finalPartCustomFields)
        : []
    };
    let response;

    setFuseLoading(true);

    if (hasActiveFusingJob) {
      response = await FusionPlanService.addToQueue(fusionPlan.id, data, 'v2');
    } else {
      response = await FusionPlanService.fuse(fusionPlan.id, data, 'v2');
    }

    pushSnackbar(messageForPush, { variant: 'success' });

    setFuseLoading(false);

    return onLoadToFusionModule(response.fusionJob);
  }, [
    selectedFusionModule,
    selectedPreforms,
    isExternalHeating,
    isAutomatic,
    isMouldSetup,
    isMaterialSetup,
    selectedMould,
    selectedManufacturingOrder,
    onLoadToFusionModule
  ]);

  const onFuseHandler = React.useCallback(async () => {
    if (!hasActiveFusingJob && hasActiveJob) {
      setOpenFuseConfirmationDialog(true);

      return;
    }

    return sendToFusionModule();
  }, [hasActiveJob, hasActiveFusingJob, sendToFusionModule]);

  const onPrev = React.useCallback(() => {
    if (activeStep === 2 && !fusionPlan.projectId) {
      setActiveStep(0);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, fusionPlan]);

  const onNext = React.useCallback(() => {
    if (
      activeStep === 0 &&
      (!fusionPlan.projectId || !manufacturingOrdersAvailable)
    ) {
      setActiveStep(2);
      setSteps(
        steps.map((step, index) =>
          index === 1 ? { ...step, completed: false } : step
        )
      );
    } else if (activeStep === 2 && fusionPlanCustomFields.length === 0) {
      setActiveStep(4);
      setSteps(
        steps.map((step, index) =>
          index === 3 ? { ...step, completed: false } : step
        )
      );
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [
    activeStep,
    fusionPlan,
    steps,
    fusionPlanCustomFields,
    manufacturingOrdersAvailable
  ]);

  const onSelectManufacturingOrder = React.useCallback(
    manufacturingOrder => {
      setSelectedManufacturingOrder(manufacturingOrder);
      setSelectedPreforms([]);
    },
    [setSelectedManufacturingOrder, setSelectedPreforms]
  );

  const isValid = !!selectedFusionModule && activeStep === steps.length - 1;
  const isNextValid = !!selectedFusionModule && isValidAdditionalCondition;
  const nextButtonLabel =
    (activeStep === 1 && !selectedManufacturingOrder) ||
    (activeStep === 2 && selectedPreforms.length === 0)
      ? 'Skip & Next'
      : 'Next';
  const nextButtonTooltip =
    activeStep === 2 && !selectedMould ? 'Mould has to be selected' : '';

  React.useEffect(() => {
    const fusionPlanCustomFields = fusionPlan.customFields
      ? fusionPlan.customFields
          .filter(cf => cf.formType === 'PRE_FUSION')
          .sort((a, b) => a.order - b.order)
      : [];

    setFusionPlanCustomFields(fusionPlanCustomFields);

    setFinalPartCustomFields(
      FinalPartService.prepareFinalPartCustomFields(
        0,
        fusionPlanCustomFields,
        finalPartCustomFields
      )
    );
  }, [fusionPlan.customFields]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: {
            lg: '1200px'
          }
        }
      }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{`Load ${fusionPlan.name} to Fusion Module`}</Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="Close icon" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <FuseDialogContext.Provider
          value={{
            fusionPlan,
            fusionModules,

            selectedFusionModule,
            setSelectedFusionModule,

            selectedManufacturingOrder,
            setSelectedManufacturingOrder,

            selectedPreforms,
            setSelectedPreforms,

            selectedMould,
            setSelectedMould,

            setOpenScanDialog,

            isValidAdditionalCondition,
            setIsValidAdditionalCondition,

            isExternalHeating,
            setIsExternalHeating,

            isPreformConfig,
            setIsPreformConfig,

            isAutomatic,
            setIsAutomatic,

            isMouldSetup,
            setIsMouldSetup,

            isMaterialSetup,
            setIsMaterialSetup,

            hasActiveFusingJob,
            hasActiveJob,

            fusionPlanCustomFields,
            setFusionPlanCustomFields,

            finalPartCustomFields,
            setFinalPartCustomFields
          }}
        >
          {loading ? (
            <LinearProgress />
          ) : (
            <div>
              <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
                {steps.map(step => (
                  <Step key={step.key} completed={step.completed}>
                    {step.disabled ? (
                      <Tooltip title={step.disableReason}>
                        <StepLabel>{step.label}</StepLabel>
                      </Tooltip>
                    ) : (
                      <StepLabel>{step.label}</StepLabel>
                    )}
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 && <FuseDialogFusionModulesStep />}

              {activeStep === 1 && (
                <DialogManufacturingOrdersStep
                  fusionPlanId={fusionPlan.id}
                  selectedManufacturingOrder={selectedManufacturingOrder}
                  setSelectedManufacturingOrder={onSelectManufacturingOrder}
                />
              )}

              {activeStep === 2 && <FuseDialogPreformsStep />}

              {activeStep === 3 && <FuseDialogPreFusionCustomFieldsStep />}

              {activeStep === 4 && <FuseDialogReviewStep />}

              {openScanDialog && (
                <ScanComponentDialog
                  fusionPlan={fusionPlan}
                  setOpenScanDialog={setOpenScanDialog}
                  onSelect={setSelectedPreforms}
                  setStep={setActiveStep}
                />
              )}

              {openFuseConfirmationDialog && (
                <TitanConfirmationDialog
                  title="Overwrite current active Fusion Job config?"
                  message={`Fusion Module ${
                    selectedFusionModule.name
                  } has active Fusion Job. Are you sure you want to load new Fusion Job?`}
                  onConfirm={() => sendToFusionModule()}
                  onClose={() => setOpenFuseConfirmationDialog(false)}
                  confirmLabel="Load new job"
                />
              )}
            </div>
          )}
        </FuseDialogContext.Provider>
      </DialogContent>
      <DialogActions sx={{ pl: 3, pr: 3 }}>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          {activeStep > 0 && (
            <Button variant="outlined" color="secondary" onClick={onPrev}>
              Prev
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Tooltip
              title={nextButtonTooltip}
              placement="top-start"
              disableHoverListener={!nextButtonTooltip}
              sx={{ ml: 1 }}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onNext}
                  disabled={!isNextValid}
                >
                  {nextButtonLabel}
                </Button>
              </span>
            </Tooltip>
          )}
          {sendToFusionModule && (
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              disabled={fuseLoading || !isValid}
              onClick={onFuseHandler}
            >
              {hasActiveFusingJob ? 'Add to queue' : 'Load to FM'}
              {fuseLoading && <CircularProgress sx={{ ml: 1 }} size={24} />}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
