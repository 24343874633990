import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import PreformService from '../../services/PreformService';
import { SORT_ORDERS } from '../../constants';
import ComponentStatus from '../Component/ComponentStatus';
import StateCommentDialog from '../Fibrify/FibrifyStateCommentDialog';

export default function PreformStates({ preform }) {
  const [loading, setLoading] = React.useState(true);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const [rows, setRows] = React.useState([]);

  const [cursor, setCursor] = React.useState(null);

  const [editableState, setEditableState] = React.useState(null);

  const mapPageToCursor = React.useRef({});

  const loadStates = React.useCallback(async () => {
    const { states, lastKey } = await PreformService.getPreformStates(
      preform.id,
      {
        sort: SORT_ORDERS.DESCENDING,
        startAt: mapPageToCursor.current[page - 1],
        pageSize
      }
    );

    setRows(states);
    lastKey ? setCursor(lastKey.timestamp) : setCursor(null);
  }, [page, pageSize, setRows, setCursor]);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'State',
        field: 'state',
        visibilityBreakpoint: 'sm',
        minWidth: 250,
        sortable: false,
        renderCell: ({ row }) => (
          <ComponentStatus
            component={{ status: row.state, progress: row.progress }}
          />
        )
      },
      {
        headerName: 'Time',
        field: 'time',
        visibilityBreakpoint: 'sm',
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) =>
          row.timestamp
            ? moment(row.timestamp).format('YYYY-MM-DD HH:mm:ss')
            : ''
      },
      {
        headerName: 'Comment',
        field: 'comment',
        visibilityBreakpoint: 'lg',
        flex: 1,
        minWidth: 180,
        sortable: false
      },
      {
        headerName: '',
        width: 60,
        field: 'actions',
        type: 'actions',
        getActions: params =>
          [
            {
              label:
                params.row.comment?.length > 0 ? 'Edit comment' : 'Add comment',
              icon: <EditIcon />,
              onClick: () => {
                setEditableState(params.row);
              }
            }
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              showInMenu
            />
          ))
      }
    ],
    []
  );

  const handlePageChange = newPage => {
    if (newPage === 0 || mapPageToCursor.current[newPage - 1]) {
      setPage(newPage);
    }
  };

  React.useEffect(() => {
    if (!loading) {
      mapPageToCursor.current[page] = cursor;
    }
  }, [page, loading, cursor]);

  React.useEffect(() => {
    setLoading(true);
    loadStates().then(() => setLoading(false));
  }, [preform, pageSize, page]);

  return (
    <Box>
      <TitanDataGrid
        columns={columns}
        loading={loading}
        rows={rows ? rows : []}
        rowCount={-1}
        getRowId={row => row.timestamp}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onPageChange={handlePageChange}
        showSearch={false}
        nextPageAvailable={!!cursor}
      />

      {editableState && (
        <StateCommentDialog
          state={editableState}
          onSave={data => {
            PreformService.updatePreformState(
              editableState.preformId,
              editableState.timestamp,
              data
            );
            setRows(
              rows.map(state =>
                state.timestamp === editableState.timestamp
                  ? (state = { ...state, ...data })
                  : state
              )
            );
          }}
          onClose={() => setEditableState(null)}
        />
      )}
    </Box>
  );
}
