import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import TitanPage from '../Titan/TitanPage';
import TitanTabs from '../Titan/TitanTabs';
import { Stack, Typography, Button, IconButton, Tooltip } from '@mui/material';
import ManufacturingOrderSummary from './ManufacturingOrderSummary';
import BuildPlansTable from '../BuildPlans/BuildPlansTable';
import FusionPlansTable from '../FusionPlans/FusionPlansTable';
import ManufacturingOrderChangeLogTable from './ManufacturingOrderChangeLogTable';
import SpoolsTable from '../Spools/SpoolsTable';
import ProjectService from '../../services/ProjectService';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import OrganizationService from '../../services/OrganizationService';
import { ROUTES, ROLES } from '../../constants';
import { PERMISSIONS } from '../../constants/auth0';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CreateIcon from '@mui/icons-material/Create';
import ManufacturingOrderDialog from './ManufacturingOrderDialog';
import SpoolsSelectionDialog from '../Fibrify/FibrifySpoolsSelectionDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import OdooImportManufacturingOrderSpoolsDialog from './OdooImportManufacturingOrderSpoolsDialog';
import OdooSyncManufacturingOrderDialog from './OdooSyncManufacturingOrderDialog';
import Can from '../Authentication/Can';
import PreformsTable from '../Preforms/PreformsTable';
import FinalPartsTable from '../FinalPart/FinalPartsTable';
import BuildModulesTable from '../DeviceList/BuildModulesTable';
import FibrifyBuildModuleSelectionDialog from '../Fibrify/FibrifyBuildModuleSelectionDialog';
import FusionModulesTable from '../DeviceList/FusionModulesTable';
import FibrifyFusionModuleSelectionDialog from '../Fibrify/FibrifyFusionModuleSelectionDialog';
import WarningIcon from '@mui/icons-material/Warning';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ManufacturingOrderPage() {
  const { manufacturingOrderId, tab = 'summary' } = useParams();
  const history = useHistory();

  const { addPageToPageHistory } = useTitan();
  const { isOrganizationAdmin, isApplicationEngineer, profile } = useAuth0();

  const [manufacturingOrder, setManufacturingOrder] = React.useState({});
  const [project, setProject] = React.useState({});
  const [organization, setOrganization] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const spoolsTableApiRef = React.useRef();
  const buildModulesTableApiRef = React.useRef();
  const fusionModulesTableApiRef = React.useRef();
  const [
    openAttachBuildModulesToManufacturingOrderDialog,
    setOpenAttachBuildModulesToManufacturingOrderDialog
  ] = React.useState(false);
  const [
    openAttachFusionModulesToManufacturingOrderDialog,
    setOpenAttachFusionModulesToManufacturingOrderDialog
  ] = React.useState(false);
  const [
    openAttachSpoolsToManufacturingOrderDialog,
    setOpenAttachSpoolsToManufacturingOrderDialog
  ] = React.useState(false);
  const [spoolTypes, setSpoolTypes] = React.useState('all');
  const [spoolsFilter, setSpoolsFilter] = React.useState({
    excludeManufacturingOrderIds: [manufacturingOrderId]
  });
  const [
    openSyncManufacturingOrderDialog,
    setOpenSyncManufacturingOrderDialog
  ] = React.useState(false);
  const [spoolToDetach, setSpoolToDetach] = React.useState();
  const [buildModuleToDetach, setBuildModuleToDetach] = React.useState();
  const [fusionModuleToDetach, setFusionModuleToDetach] = React.useState();
  const [
    openOdooImportManufacturingOrderSpoolsDialog,
    setOpenOdooImportManufacturingOrderSpoolsDialog
  ] = React.useState(false);

  const isAbleToEdit = isOrganizationAdmin || isApplicationEngineer;

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Projects',
        path: ROUTES.PROJECTS()
      },
      {
        name: project?.name ? project.name : 'Project',
        path: ROUTES.PROJECT(manufacturingOrder?.projectId)
      },
      {
        name: 'Manufacturing Orders',
        path: ROUTES.PROJECT_TAB(
          manufacturingOrder?.projectId,
          'manufacturing-orders'
        )
      },
      {
        name: manufacturingOrder?.name
          ? manufacturingOrder.name
          : 'Manufacturing Order',
        disabled: true
      }
    ],
    [project, manufacturingOrder]
  );

  useAsyncEffect(async () => {
    setLoading(true);
    const {
      data: manufacturingOrder
    } = await ManufacturingOrderService.getManufacturingOrderById(
      manufacturingOrderId
    );

    if (manufacturingOrder.projectId) {
      const { data: project } = await ProjectService.getProjectById(
        manufacturingOrder.projectId
      );
      setProject(project);
    }

    if (manufacturingOrder.organizationId) {
      const organization = await OrganizationService.getOrganizationById(
        manufacturingOrder?.organizationId
      );

      setOrganization(organization);
    }

    setManufacturingOrder(manufacturingOrder);
    setLoading(false);
  }, [
    manufacturingOrderId,
    setManufacturingOrder,
    setLoading,
    setOrganization,
    setProject
  ]);

  const onUpdateManufacturingOrder = React.useCallback(
    async data => {
      const updatedManufacturingOrder = await ManufacturingOrderService.updateManufacturingOrder(
        manufacturingOrder.id,
        data
      );

      setManufacturingOrder(updatedManufacturingOrder);
      setOpenEditDialog(false);
    },
    [manufacturingOrder]
  );

  const preparePageObject = React.useCallback(
    (tab, tabForURL) => {
      return {
        id: `MANUFACTURING_ORDER:${manufacturingOrderId}`,
        url: ROUTES.MANUFACTURING_ORDER_TAB(manufacturingOrderId, tabForURL),
        label: `${manufacturingOrder.name} | ${tab}`
      };
    },
    [manufacturingOrder]
  );

  const attachSpoolToManufacturingOrder = React.useCallback(
    async spools => {
      await Promise.allSettled(
        spools.map(spool =>
          ManufacturingOrderService.attachSpool(manufacturingOrderId, spool.id)
        )
      );

      await spoolsTableApiRef.current.reloadData();
    },
    [manufacturingOrderId]
  );

  const detachSpoolFromManufacturingOrder = React.useCallback(
    async spool => {
      await ManufacturingOrderService.detachSpool(
        manufacturingOrderId,
        spool.id
      );

      await spoolsTableApiRef.current.reloadData();
    },
    [manufacturingOrderId]
  );

  const attachBuildModulesToManufacturingOrder = React.useCallback(
    async buildModules => {
      await Promise.allSettled(
        buildModules.map(buildModule =>
          ManufacturingOrderService.attachBuildModule(
            manufacturingOrderId,
            buildModule.id
          )
        )
      );

      await buildModulesTableApiRef.current.reloadData();
    },
    [manufacturingOrderId]
  );

  const detachBuildModuleFromManufacturingOrder = React.useCallback(
    async buildModule => {
      await ManufacturingOrderService.detachBuildModule(
        manufacturingOrderId,
        buildModule.id
      );

      await buildModulesTableApiRef.current.reloadData();
    },
    [manufacturingOrderId]
  );

  const attachFusionModulesToManufacturingOrder = React.useCallback(
    async fusionModules => {
      await Promise.allSettled(
        fusionModules.map(fusionModule =>
          ManufacturingOrderService.attachFusionModule(
            manufacturingOrderId,
            fusionModule.id
          )
        )
      );

      await fusionModulesTableApiRef.current.reloadData();
    },
    [manufacturingOrderId]
  );

  const detachFusionModuleFromManufacturingOrder = React.useCallback(
    async fusionModule => {
      await ManufacturingOrderService.detachFusionModule(
        manufacturingOrderId,
        fusionModule.id
      );

      await fusionModulesTableApiRef.current.reloadData();
    },
    [manufacturingOrderId]
  );

  const spoolsExtraActions = React.useMemo(() => {
    return [
      {
        title: 'Detach',
        icon: <RemoveCircleIcon />,
        onClick: spool => setSpoolToDetach(spool),
        disabled: false
      }
    ];
  }, []);

  const buildModulesExtraActions = React.useMemo(() => {
    return [
      {
        title: 'Detach',
        icon: RemoveCircleIcon,
        onClick: buildModule => setBuildModuleToDetach(buildModule),
        disabled: false
      }
    ];
  }, []);

  const fusionModulesExtraActions = React.useMemo(() => {
    return [
      {
        title: 'Detach',
        icon: RemoveCircleIcon,
        onClick: fusionModule => setFusionModuleToDetach(fusionModule),
        disabled: false
      }
    ];
  }, []);

  const spoolsExtraColumns = React.useMemo(() => {
    const spoolsExtraColumns = [
      {
        headerName: 'Odoo sync',
        field: 'odooProductId',
        type: 'boolean',
        visibilityBreakpoint: 'md',
        width: 120,
        sortable: false
      }
    ];

    if (
      manufacturingOrder.spoolTags &&
      manufacturingOrder.spoolTags.length !== 0
    ) {
      spoolsExtraColumns.push({
        headerName: '',
        field: 'spoolValid',
        width: 60,
        sortable: false,
        renderCell: ({ row }) =>
          !row.tags.some(tag =>
            manufacturingOrder.spoolTags.find(
              moSpoolTag => moSpoolTag.name === tag.name
            )
          ) ? (
            <Tooltip title="Spool tag is not associated with manufacturing order spool tags">
              <WarningIcon style={{ fill: 'orange' }} />
            </Tooltip>
          ) : (
            ''
          )
      });
    }

    return spoolsExtraColumns;
  }, [manufacturingOrder]);

  const tabs = React.useMemo(
    () => [
      {
        label: 'Summary',
        value: 'summary',
        content: (
          <ManufacturingOrderSummary
            manufacturingOrder={manufacturingOrder}
            project={project}
            organization={organization}
          />
        )
      },
      ...(manufacturingOrder.preformsCount
        ? [
            {
              label: 'Build Plans',
              value: 'build-plans',
              content: (
                <BuildPlansTable
                  manufacturingOrderId={manufacturingOrderId}
                  manufacturingOrdersProjectId={manufacturingOrder.projectId}
                  addBuildPlanToManufacturingOrderButton={
                    isOrganizationAdmin || isApplicationEngineer
                  }
                />
              )
            }
          ]
        : []),
      ...(manufacturingOrder.finalPartsCount
        ? [
            {
              label: 'Fusion Plans',
              value: 'fusion-plans',
              content: (
                <FusionPlansTable
                  manufacturingOrderId={manufacturingOrderId}
                  manufacturingOrdersProjectId={manufacturingOrder.projectId}
                  addFusionPlanToManufacturingOrderButton={
                    isOrganizationAdmin || isApplicationEngineer
                  }
                />
              )
            }
          ]
        : []),
      ...(manufacturingOrder.preformsCount
        ? [
            {
              label: 'Preforms',
              value: 'preforms',
              content: (
                <PreformsTable manufacturingOrderIds={[manufacturingOrderId]} />
              )
            }
          ]
        : []),
      ...(manufacturingOrder.finalPartsCount
        ? [
            {
              label: 'Final Parts',
              value: 'final-parts',
              content: (
                <FinalPartsTable
                  manufacturingOrderIds={[manufacturingOrderId]}
                />
              )
            }
          ]
        : []),
      ...(manufacturingOrder.preformsCount
        ? [
            {
              label: 'Build Modules',
              value: 'build-modules',
              content: (
                <BuildModulesTable
                  apiRef={buildModulesTableApiRef}
                  title=""
                  manufacturingOrderId={manufacturingOrderId}
                  showManageActions={false}
                  extraActions={buildModulesExtraActions}
                />
              )
            }
          ]
        : []),
      ...(manufacturingOrder.finalPartsCount
        ? [
            {
              label: 'Fusion Modules',
              value: 'fusion-modules',
              content: (
                <FusionModulesTable
                  apiRef={fusionModulesTableApiRef}
                  title=""
                  manufacturingOrderId={manufacturingOrderId}
                  showManageActions={false}
                  extraActions={fusionModulesExtraActions}
                />
              )
            }
          ]
        : []),
      ...(manufacturingOrder.preformsCount
        ? [
            {
              label: 'Spools',
              value: 'spools',
              content: (
                <SpoolsTable
                  apiRef={spoolsTableApiRef}
                  manufacturingOrderIds={[manufacturingOrderId]}
                  title={null}
                  createButtonLabel={null}
                  extraActions={spoolsExtraActions}
                  extraColumns={spoolsExtraColumns}
                />
              )
            }
          ]
        : []),
      {
        label: 'Change Log',
        value: 'change-log',
        content: (
          <ManufacturingOrderChangeLogTable
            changeLog={manufacturingOrder.changeLog}
          />
        )
      }
    ],
    [
      manufacturingOrderId,
      manufacturingOrder,
      organization?.name,
      project?.name,
      spoolsExtraActions,
      spoolsExtraColumns
    ]
  );

  const onChangeTab = React.useCallback(
    value => {
      const activeLabel = value.charAt(0).toUpperCase() + value.slice(1);
      addPageToPageHistory(preparePageObject(activeLabel, value));
      history.push(ROUTES.MANUFACTURING_ORDER_TAB(manufacturingOrderId, value));
    },
    [history, addPageToPageHistory, preparePageObject, manufacturingOrderId]
  );

  return (
    <TitanPage
      loading={loading}
      breadcrumbs={breadcrumbs}
      title={manufacturingOrder?.name || 'Manufacturing Order'}
      titleContent={
        !loading &&
        !manufacturingOrder?.deletedAt &&
        isAbleToEdit && (
          <IconButton size="large" onClick={() => setOpenEditDialog(true)}>
            <CreateIcon />
          </IconButton>
        )
      }
      headerContent={
        <Stack spacing={2} direction="row">
          <Can
            permissions={[PERMISSIONS.ODOO_SYNC]}
            yes={() => (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SyncIcon />}
                onClick={async () => setOpenSyncManufacturingOrderDialog(true)}
              >
                Odoo sync
              </Button>
            )}
          />

          {/*{tab === 'spools' && isOrganizationAdmin ? (*/}
          {/*  <Tooltip*/}
          {/*    title={*/}
          {/*      !manufacturingOrder.odooManufacturingOrderId*/}
          {/*        ? 'Sync manufacturing order with Odoo first'*/}
          {/*        : ''*/}
          {/*    }*/}
          {/*    disableHoverListener={false}*/}
          {/*    disableTouchListener={false}*/}
          {/*  >*/}
          {/*    <div>*/}
          {/*      <Button*/}
          {/*        variant="outlined"*/}
          {/*        color="primary"*/}
          {/*        startIcon={<DownloadIcon />}*/}
          {/*        onClick={async () =>*/}
          {/*          setOpenOdooImportManufacturingOrderSpoolsDialog(true)*/}
          {/*        }*/}
          {/*        disabled={!manufacturingOrder.odooManufacturingOrderId}*/}
          {/*      >*/}
          {/*        Odoo import*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*  </Tooltip>*/}
          {/*) : (*/}
          {/*  ''*/}
          {/*)}*/}

          {tab === 'build-modules' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={async () =>
                setOpenAttachBuildModulesToManufacturingOrderDialog(true)
              }
              disabled={!(isOrganizationAdmin || isApplicationEngineer)}
            >
              Attach build modules
            </Button>
          )}

          {tab === 'fusion-modules' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={async () =>
                setOpenAttachFusionModulesToManufacturingOrderDialog(true)
              }
              disabled={!(isOrganizationAdmin || isApplicationEngineer)}
            >
              Attach fusion modules
            </Button>
          )}

          {tab === 'spools' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={async () => {
                setSpoolTypes('all');
                setSpoolsFilter({
                  excludeManufacturingOrderIds: [manufacturingOrderId]
                });
                setOpenAttachSpoolsToManufacturingOrderDialog(true);
              }}
              disabled={!(isOrganizationAdmin || isApplicationEngineer)}
            >
              Attach spools
            </Button>
          )}
        </Stack>
      }
    >
      <TitanTabs tabs={tabs} activeTab={tab} onChangeTab={onChangeTab} />

      {openEditDialog && (
        <ManufacturingOrderDialog
          projectId={project.id}
          manufacturingOrder={manufacturingOrder}
          profile={profile}
          onUpdate={onUpdateManufacturingOrder}
          onClose={() => setOpenEditDialog(false)}
        />
      )}

      {openAttachSpoolsToManufacturingOrderDialog && (
        <SpoolsSelectionDialog
          title="Select spools that you want to attach to this Manufacturing Order"
          saveButtonLabel="Attach"
          multipleSelection
          filters={spoolsFilter}
          filtersContent={
            manufacturingOrder.spoolTags &&
            manufacturingOrder.spoolTags.length !== 0 ? (
              <RadioGroup
                row
                value={spoolTypes}
                onChange={e => {
                  setSpoolTypes(e.target.value);
                  setSpoolsFilter(
                    e.target.value === 'all'
                      ? {
                          excludeManufacturingOrderIds: [manufacturingOrderId]
                        }
                      : {
                          excludeManufacturingOrderIds: [manufacturingOrderId],
                          spoolTagIds: manufacturingOrder.spoolTags.map(
                            tag => tag.id
                          )
                        }
                  );
                }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All spools"
                />
                <FormControlLabel
                  value="mo-spool-tags"
                  control={<Radio />}
                  label="MO spool tags"
                />
              </RadioGroup>
            ) : (
              ''
            )
          }
          onSave={attachSpoolToManufacturingOrder}
          onClose={() => setOpenAttachSpoolsToManufacturingOrderDialog(false)}
        />
      )}

      {spoolToDetach && (
        <TitanConfirmationDialog
          title={`Detach spool from manufacturing order?`}
          message={`Are you sure you want to detach ${
            spoolToDetach.spoolIdentifier
          } from ${manufacturingOrder.name}?`}
          onClose={() => {
            setSpoolToDetach(null);
          }}
          onConfirm={() => detachSpoolFromManufacturingOrder(spoolToDetach)}
        />
      )}

      {openAttachBuildModulesToManufacturingOrderDialog && (
        <FibrifyBuildModuleSelectionDialog
          title="Select build modules to attach to manufacturing order"
          onClose={() =>
            setOpenAttachBuildModulesToManufacturingOrderDialog(false)
          }
          filters={{ excludeManufacturingOrderIds: [manufacturingOrderId] }}
          onSave={attachBuildModulesToManufacturingOrder}
          saveButtonLabel="Attach"
          multipleSelection
        />
      )}

      {openAttachFusionModulesToManufacturingOrderDialog && (
        <FibrifyFusionModuleSelectionDialog
          title="Select fusion modules to attach to manufacturing order"
          onClose={() =>
            setOpenAttachFusionModulesToManufacturingOrderDialog(false)
          }
          filters={{ excludeManufacturingOrderIds: [manufacturingOrderId] }}
          onSave={attachFusionModulesToManufacturingOrder}
          saveButtonLabel="Attach"
          multipleSelection
        />
      )}

      {buildModuleToDetach && (
        <TitanConfirmationDialog
          title={`Detach build module ${
            buildModuleToDetach.name
          } from manufacturing order?`}
          message={`Are you sure you want to detach build module ${
            buildModuleToDetach.name
          } from ${manufacturingOrder.name}?`}
          onClose={() => {
            setBuildModuleToDetach(null);
          }}
          onConfirm={() =>
            detachBuildModuleFromManufacturingOrder(buildModuleToDetach)
          }
        />
      )}

      {fusionModuleToDetach && (
        <TitanConfirmationDialog
          title={`Detach fusion module ${
            fusionModuleToDetach.name
          } from manufacturing order?`}
          message={`Are you sure you want to detach fusion module ${
            fusionModuleToDetach.name
          } from ${manufacturingOrder.name}?`}
          onClose={() => {
            setFusionModuleToDetach(null);
          }}
          onConfirm={() =>
            detachFusionModuleFromManufacturingOrder(fusionModuleToDetach)
          }
        />
      )}

      {openOdooImportManufacturingOrderSpoolsDialog && (
        <OdooImportManufacturingOrderSpoolsDialog
          odooManufacturingOrderId={manufacturingOrder.odooManufacturingOrderId}
          onClose={async () => {
            setOpenOdooImportManufacturingOrderSpoolsDialog(false);
            await spoolsTableApiRef.current.reloadData();
          }}
        />
      )}

      {openSyncManufacturingOrderDialog && (
        <OdooSyncManufacturingOrderDialog
          manufacturingOrderId={manufacturingOrder.id}
          onClose={async () => {
            setOpenSyncManufacturingOrderDialog(false);
          }}
        />
      )}
    </TitanPage>
  );
}
