module.exports = {
  FINISH: 'FINISH',
  SET_AS_FUSED: 'SET_AS_FUSED',
  RESEND: 'RESEND',
  UNLOAD: 'UNLOAD',
  FORCE_UNLOAD: 'FORCE_UNLOAD',
  CANCEL: 'CANCEL',
  FORCE_CANCEL: 'FORCE_CANCEL',
  ATTACH_TO_MO: 'ATTACH_TO_MO',
  DETACH_FROM_MO: 'DETACH_FROM_MO',
  SET_FUSION_JOB_OPERATOR: 'SET_FUSION_JOB_OPERATOR',
};
