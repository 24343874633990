module.exports = {
  ON_SET_AS_BUILT: 'onSetAsBuilt',
  ON_FINISH: 'onFinish',
  ON_RESEND: 'onResend',
  ON_UNLOAD: 'onUnload',
  ON_FORCE_UNLOAD: 'onForceUnload',
  ON_CANCEL: 'onCancel',
  ON_FORCE_CANCEL: 'onForceCancel',
  ON_ATTACH_TO_MANUFACTURING_ORDER: 'onAttachToManufacturingOrder',
  ON_DETACH_FROM_MANUFACTURING_ORDER: 'onDetachFromManufacturingOrder',
  ON_DEFER_POST_BUILDING: 'onDeferPostBuilding',
  ON_SET_BUILD_JOB_OPERATOR: 'onSetBuildJobOperator',
};
