import axios from 'axios';
import { COMPONENT_STATUSES } from '../constants';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import FusionModuleIcon from '../assets/icons/fusion-module-small-secondary.svg';
import UnloadIcon from '@mui/icons-material/FileUpload';
import React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';

const unloadStatuses = [
  COMPONENT_STATUSES.LOAD_TO_FM,
  COMPONENT_STATUSES.LOADED_TO_FM,
  COMPONENT_STATUSES.LOAD_TO_FM_FAILED,
  COMPONENT_STATUSES.PRE_FUSING,
];

const forceUnloadStatuses = [COMPONENT_STATUSES.UNLOAD_FROM_FM];

const cancelStatuses = [
  COMPONENT_STATUSES.LOAD_TO_FM,
  COMPONENT_STATUSES.LOADED_TO_FM,
  COMPONENT_STATUSES.LOAD_TO_FM_FAILED,
  COMPONENT_STATUSES.SCHEDULED,
  COMPONENT_STATUSES.PRE_FUSING,
  COMPONENT_STATUSES.FUSING,
  COMPONENT_STATUSES.FUSE_PAUSED,
];

const resendStatuses = [
  COMPONENT_STATUSES.LOAD_TO_FM,
  COMPONENT_STATUSES.LOADED_TO_FM,
  COMPONENT_STATUSES.LOAD_TO_FM_FAILED,
  COMPONENT_STATUSES.PRE_FUSING,
  COMPONENT_STATUSES.SCHEDULED,
];

const finishFusingStatuses = [
  COMPONENT_STATUSES.POST_FUSING,
  COMPONENT_STATUSES.POST_CANCELED,
];

const setAsFusedStatuses = [
  COMPONENT_STATUSES.LOAD_TO_BM,
  COMPONENT_STATUSES.LOADED_TO_FM,
  COMPONENT_STATUSES.PRE_FUSING,
  COMPONENT_STATUSES.FUSING,
  COMPONENT_STATUSES.FUSE_PAUSED,
  COMPONENT_STATUSES.FUSE_CANCELING,
];

export default class FusionJobService {
  static async getFusionJob(fusionJobId, params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJobId}`,
        {
          params,
        },
      )
      .then((res) => res.data.data);
  }

  static getFusionJobs(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs`, {
        ...config,
        params,
      })
      .then((res) => res.data);
  }

  static async getFusionJobStates(fusionJobId, params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJobId}/states`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }

  static updateFusionJobState(fusionJobId, timestamp, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJobId}/states/${timestamp}`,
        data,
      )
      .then((res) => res.data);
  }

  static create(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs`, data)
      .then((res) => res.data.data);
  }

  static async update(fusionJob, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJob.id}`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async cancelFusionJob(fusionJob, force = false) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJob.id}/cancel`,
        { force },
      )
      .then((res) => res.data.data);
  }

  static async resendFusionJob(fusionJob) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJob.id}/resend`,
      )
      .then((res) => res.data.data);
  }

  static async unloadFusionJob(fusionJob, data = {}) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v2/fusion-jobs/${fusionJob.id}/unload`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async markFusionJobFused(fusionJob) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/fusion-jobs/${fusionJob.id}/fused`,
      )
      .then((res) => res.data.data);
  }

  static getFusionJobActions({
    fusionJob,
    fusionModuleStatus,
    onUnload,
    onForceUnload,
    onFinish,
    onResend,
    onCancel,
    onForceCancel,
    onSetAsFused,
    onAttachToManufacturingOrder,
    onDetachFromManufacturingOrder,
    onSetFusionJobOperator,
  }) {
    const actions = [];
    const isAvailableToFuse =
      fusionModuleStatus && fusionModuleStatus !== COMPONENT_STATUSES.FUSING;

    if (!fusionJob.operatorId && onSetFusionJobOperator) {
      actions.push({
        variant: 'outlined',
        color: 'primary',
        label: 'I am the operator of this job',
        icon: <EngineeringIcon />,
        onClick: onSetFusionJobOperator,
      });
    }

    if (
      resendStatuses.includes(fusionJob.status) &&
      (!fusionModuleStatus ||
        (fusionModuleStatus &&
          ![
            COMPONENT_STATUSES.ONLINE,
            COMPONENT_STATUSES.IDLE,
            COMPONENT_STATUSES.MAINTENANCE,
          ].includes(fusionModuleStatus)))
    ) {
      actions.push({
        variant: 'outlined',
        color: 'primary',
        label:
          fusionJob.status === COMPONENT_STATUSES.SCHEDULED ? 'Fuse' : 'Resend',
        icon:
          fusionJob.status === COMPONENT_STATUSES.SCHEDULED ? (
            <img src={FusionModuleIcon} alt="" />
          ) : (
            <RefreshIcon />
          ),
        onClick: () => onResend(fusionJob),
        disabled: !isAvailableToFuse,
        tooltipText: !isAvailableToFuse
          ? 'Fusion module has active fusing job'
          : '',
      });
    }

    if (finishFusingStatuses.includes(fusionJob.status)) {
      actions.push({
        variant: 'contained',
        color: 'primary',
        label: 'Finish Fusing',
        icon: <DoneIcon />,
        onClick: onFinish,
      });
    }

    if (setAsFusedStatuses.includes(fusionJob.status)) {
      actions.push({
        variant: 'outlined',
        color: 'primary',
        label: 'Set as Fused',
        icon: <DoneIcon />,
        onClick: onSetAsFused,
      });
    }

    if (unloadStatuses.includes(fusionJob.status)) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Unload',
        icon: <UnloadIcon />,
        onClick: onUnload,
      });
    }

    if (forceUnloadStatuses.includes(fusionJob.status)) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Force Unload',
        icon: <UnloadIcon />,
        onClick: onForceUnload,
      });
    }

    if (cancelStatuses.includes(fusionJob.status)) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Cancel',
        icon: <CancelIcon />,
        onClick: onCancel,
      });
    }

    if (fusionJob.status === COMPONENT_STATUSES.FUSE_CANCELING) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Force Cancel',
        icon: <CancelIcon />,
        onClick: onForceCancel,
      });
    }
    /*
    actions.push({
      variant: 'outlined',
      color: 'secondary',
      label: !fusionJob.manufacturingOrderId
        ? 'to Manufacturing Order'
        : 'Change Manufacturing Order',
      icon: !fusionJob.manufacturingOrderId ? (
        <AddCircleIcon />
      ) : (
        <ChangeCircle />
      ),
      onClick: onAttachToManufacturingOrder
    });

    if (fusionJob.manufacturingOrderId) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'from Manufacturing Order',
        icon: <RemoveCircleIcon />,
        onClick: onDetachFromManufacturingOrder
      });
    }
    */

    return actions;
  }

  static async getFusionJobTelemetry(fusionJobId, params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/fusion-jobs/${fusionJobId}/telemetry`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }
}
