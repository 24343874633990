import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { SORT_ORDERS } from '../../constants';
import PrintHeadService from '../../services/PrintHeadService';
import renderCellExpand from '../ExpandRenderCell/ExpandRenderCell';
import PrintHeadDialog from './PrintHeadDialog';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { useTitan } from '../Titan/Titan';
import { useParams } from 'react-router-dom';

export default function PrintHeadsTable({ setNewPageForTab }) {
  const { pushSnackbar } = useTitan();
  const { page: pageParam = 0 } = useParams();

  const [openPrintHeadDialog, setOpenPrintHeadDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [editablePrintHead, setEditablePrintHead] = React.useState(null);

  const loadData = React.useCallback(async (params, config) => {
    const { data, pagination } = await PrintHeadService.getPrintHeads(
      {
        ...params
      },
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'printHeadId',
        flex: 1,
        minWidth: 100,
        hideable: false,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.printHeadId, colDef.computedWidth)
      },
      {
        headerName: 'Version',
        field: 'version',
        flex: 1,
        hideable: false,
        minWidth: 170,
        visibilityBreakpoint: 'sm',
        sortable: false
      },
      {
        headerName: 'Comment',
        field: 'comment',
        flex: 1,
        hideable: false,
        minWidth: 170,
        visibilityBreakpoint: 'sm',
        sortable: false
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        hideable: false,
        sortable: false,
        width: 60,
        getActions: params =>
          [
            {
              label: 'Edit',
              icon: <EditIcon />,
              onClick: () => {
                setEditablePrintHead(params.row);
                setOpenPrintHeadDialog(true);
              }
            },
            {
              label: 'Delete',
              icon: <DeleteIcon />,
              onClick: () => {
                setEditablePrintHead(params.row);
                setOpenDeleteDialog(true);
              }
            }
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          ))
      }
    ],
    []
  );

  const gridOption = {
    orders: {
      created_at: SORT_ORDERS.DESC
    },
    columns,
    onChangePage: page => {
      setNewPageForTab('printHeads', 'Print Heads', page);
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOption.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOption);
  const { rows, page, setRows } = titanDataGridProps;

  const onCloseDialog = async () => {
    setOpenPrintHeadDialog(false);
    setEditablePrintHead(null);
  };

  const onSave = React.useCallback(
    async data => {
      const { printHeadIdExists } = await PrintHeadService.printHeadIdExists({
        printHeadId: data.printHeadId,
        ...(editablePrintHead && { id: editablePrintHead.id })
      });

      if (printHeadIdExists) {
        throw new Error(
          'Print head with the same identifier already exists within the organization'
        );
      }

      if (editablePrintHead) {
        const updatedPrintHead = await PrintHeadService.updatePrintHead(
          editablePrintHead.id,
          data
        );
        setRows(prev =>
          prev.map(s => (s.id === updatedPrintHead.id ? updatedPrintHead : s))
        );
      } else {
        const newPrintHead = await PrintHeadService.createPrintHead(data);
        setRows(prev => [newPrintHead, ...prev]);
      }
    },
    [rows, editablePrintHead]
  );

  const onDelete = React.useCallback(async () => {
    await PrintHeadService.deletePrintHead(editablePrintHead.id);

    pushSnackbar('Print head has been successfully deleted', {
      variant: 'success'
    });
    setOpenDeleteDialog(false);
    setRows(prev => prev.filter(s => s.id !== editablePrintHead.id));
  }, [rows, editablePrintHead]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel
        }}
        onCreateClick={() => setOpenPrintHeadDialog(true)}
        createButtonLabel="Print Head"
        title="Print Heads"
      />
      {openPrintHeadDialog && (
        <PrintHeadDialog
          onClose={onCloseDialog}
          editablePrintHead={editablePrintHead}
          onSave={onSave}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete print head?"
          message={`Are you sure you want to delete print head "${editablePrintHead.printHeadId ||
            editablePrintHead.id}"?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditablePrintHead(null);
          }}
          onConfirm={onDelete}
        />
      )}
    </>
  );
}
