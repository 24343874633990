import React from 'react';

import { useFuseDialog } from './FuseDialog';

import PreformCustomFields from '../Preform/PreformCustomFields';
import FinalPartService from '../../services/FinalPartService';

export default function FuseDialogPreFusionCustomFieldsStep() {
  const {
    finalPartCustomFields,
    setFinalPartCustomFields,
    fusionPlanCustomFields
  } = useFuseDialog();

  return (
    <PreformCustomFields
      customFields={fusionPlanCustomFields}
      entityCustomFields={finalPartCustomFields}
      onChange={finalPartCustomFields => {
        setFinalPartCustomFields(
          FinalPartService.prepareFinalPartCustomFields(
            0,
            fusionPlanCustomFields,
            finalPartCustomFields
          )
        );
      }}
    />
  );
}
