import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import {
  COMPONENT_STATUSES,
  ROUTES,
  SORT_ORDERS,
  WEB_SOCKET_ACTIONS
} from '../../constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PreformLink from '../Component/PreformLink';
import ComponentStatus from '../Component/ComponentStatus';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import PreformService from '../../services/PreformService';
import PreformsTableFilter from './PreformsTableFilter';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useWebSocket from 'react-use-websocket';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import BuildPlanService from '../../services/BuildPlanService';
import BuildJobService from '../../services/BuildJobService';
import ComponentService from '../../services/ComponentService';
import { RenderPreformsCell } from '../TablesComponents/RenderPreformsCell';
import { useTitan } from '../Titan/Titan';
import PreformsChips from './PreformsChips';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import ManufacturingOrderSelectionDialog from '../Fibrify/FibrifyManufacturingOrderSelectionDialog';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';

export default function PreformsTable({
  buildModuleId,
  buildPlanId,
  rootBuildPlanId,
  buildJobId,
  finalPartId,
  manufacturingOrderIds,
  isHeaderShown = true,
  columnsToHide,
  action
}) {
  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();
  const [buildPlanVersionType, setBuildPlanVersionType] = React.useState(
    'current'
  );

  const [buildPlan, setBuildPlan] = React.useState();
  const [buildJob, setBuildJob] = React.useState();

  const [
    preformToAttachToManufacturingOrder,
    setPreformToAttachToManufacturingOrder
  ] = React.useState(null);
  const [
    preformToDetachFromManufacturingOrder,
    setPreformToDetachFromManufacturingOrder
  ] = React.useState(null);

  const {
    addPageToPageHistory,
    getWebSocketUrl,
    getWebSocketOptions,
    pushSnackbar
  } = useTitan();

  const isEditable = React.useCallback(row => {
    return true || [COMPONENT_STATUSES.BUILT].includes(row.status);
  }, []);

  React.useEffect(() => {
    let columnsBuildPlanId;

    if (buildPlanVersionType === 'all' && rootBuildPlanId) {
      columnsBuildPlanId = rootBuildPlanId;
    } else if (buildPlanId) {
      columnsBuildPlanId = buildPlanId;
    }

    if (columnsBuildPlanId) {
      BuildPlanService.getBuildPlan(
        columnsBuildPlanId,
        {
          withRelated: ['preformTypes', 'preformTypes.customFields']
        },
        'v2'
      ).then(buildPlan => setBuildPlan(buildPlan));
    } else if (buildJobId) {
      BuildJobService.getBuildJob(buildJobId, {
        withRelated: [
          'buildPlan',
          'buildPlan.preformTypes',
          'buildPlan.preformTypes.customFields'
        ]
      }).then(res => {
        const buildJob = res.data;

        setBuildPlan(buildJob.buildPlan);
        setBuildJob(buildJob);
      });
    }
  }, [buildJobId, buildPlanId, buildPlanVersionType, rootBuildPlanId]);

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: [
          'printer',
          'preformType',
          'preformType.customFields',
          'buildJob',
          'buildJob.creator',
          'buildJob.spools',
          'buildJob.spools.materialType',
          'customFields',
          'customFields.preformTypeCustomField',
          'manufacturingOrder'
        ]
      };

      if (buildPlanVersionType === 'all' && rootBuildPlanId) {
        params.rootBuildPlanId = rootBuildPlanId;
      } else if (buildPlanId) {
        params.buildPlanId = buildPlanId;
      } else if (buildPlanVersionType && buildPlan) {
        params.buildPlanId = buildPlan.id;
      }

      if (buildModuleId) {
        params.buildModuleId = buildModuleId;
      }

      if (
        buildJobId &&
        (!buildPlanVersionType || buildPlanVersionType === 'current')
      ) {
        params.buildJobId = buildJobId;
      }

      if (manufacturingOrderIds) {
        params.manufacturingOrderIds = manufacturingOrderIds;
      }

      if (finalPartId) {
        params.finalPartId = finalPartId;
      }

      const { data, pagination } = await PreformService.getPreforms(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [buildPlanId, buildModuleId, buildJobId, finalPartId, buildPlanVersionType]
  );

  const defaultOrder = React.useMemo(() => {
    let preformOrderParams = {
      'components.status_updated_at': SORT_ORDERS.DESC
    };

    if (buildJobId) {
      preformOrderParams = {
        'preform_types.order': SORT_ORDERS.ASC
      };
    }

    return preformOrderParams;
  }, [buildJobId]);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  const preparePreformToUpdate = React.useCallback(
    (fieldType, row, customFieldName, newValue) => {
      let preformToUpdate = {
        ...row,
        customFields: [
          ...row.customFields.map(cf => {
            if (cf.preformTypeCustomField.name === customFieldName) {
              return {
                ...cf,
                value:
                  fieldType === 'MULTI_SELECT' ? newValue.join(', ') : newValue
              };
            }
            return cf;
          })
        ],
        preform: {
          ...row.preform,
          customFields: [
            ...row.customFields.map(cf => {
              if (cf.preformTypeCustomField.name === customFieldName) {
                return {
                  ...cf,
                  value:
                    fieldType === 'MULTI_SELECT'
                      ? newValue.join(', ')
                      : newValue
                };
              }
              return cf;
            })
          ]
        }
      };
      return preformToUpdate;
    },
    []
  );

  const prepareCustomFieldsObject = React.useCallback(
    (row, customFieldId, newValue) => {
      return {
        customFields: Object.values({
          ...PreformService.preparePreformCustomFields(row.preform),
          [customFieldId]: {
            ...PreformService.preparePreformCustomFields(row.preform)[
              customFieldId
            ],
            value: newValue
          }
        })
      };
    },
    []
  );

  const updatePreforms = React.useCallback(
    preformsToUpdate => {
      const filteredPreformsToUpdate = preformsToUpdate.filter(
        preform =>
          (buildModuleId && preform.buildModuleId === buildModuleId) ||
          (buildPlanId && preform.buildPlanId === buildPlanId) ||
          (buildJobId && preform.buildJobId === buildJobId) ||
          (finalPartId && preform.finalPartId === finalPartId) ||
          (manufacturingOrderIds &&
            manufacturingOrderIds.includes(preform.manufacturingOrderId)) ||
          (!buildJobId &&
            !buildModuleId &&
            !buildPlanId &&
            !finalPartId &&
            !manufacturingOrderIds)
      );

      if (filteredPreformsToUpdate.length === 0) {
        return;
      }

      const changeRows = rows => {
        let preforms = [...rows];

        preforms = preforms.map(preform => {
          const preformToUpdate = preformsToUpdate.find(
            c => c.id === preform.id
          );

          if (preformToUpdate) {
            const { lastState, customFields } = preformToUpdate;

            return {
              ...preform,
              lastState,
              customFields
            };
          }

          return preform;
        });

        const newPreforms = [];

        preformsToUpdate.forEach(p => {
          if (!preforms.find(preform => preform.id === p.id)) {
            newPreforms.push(p);
          }
        });

        if (newPreforms.length !== 0) {
          preforms = [...newPreforms, ...preforms];
        }
        return preforms;
      };

      setRows(prev => changeRows(prev));
    },
    [buildJobId, buildPlanId, buildModuleId, finalPartId, manufacturingOrderIds]
  );

  const getCustomFieldFromUpdatedComponent = (component, name) => {
    const updatedCF = component.customFields.find(
      cf => cf.preformTypeCustomField.name === name
    );
    return updatedCF;
  };

  const updateComponent = React.useCallback(
    async (row, data, name, preformToUpdate, cf) => {
      const updatedComponent = await ComponentService.updateComponent(
        row.preform.id,
        data
      );
      const updatedCF = getCustomFieldFromUpdatedComponent(
        updatedComponent,
        name
      );
      if (updatedCF) {
        if (preformToUpdate.customFields.find(cf => cf.id === updatedCF.id)) {
          preformToUpdate.customFields = preformToUpdate.customFields.map(
            cf => {
              return cf.id === updatedCF.id ? updatedCF : cf;
            }
          );
          preformToUpdate.preform = {
            ...preformToUpdate.preform,
            customFields: preformToUpdate.preform.customFields.map(cf => {
              return cf.id === updatedCF.id ? updatedCF : cf;
            })
          };
        } else {
          preformToUpdate.customFields = [
            ...preformToUpdate.customFields,
            updatedCF
          ];
          preformToUpdate.preform = {
            ...preformToUpdate.preform,
            customFields: [...preformToUpdate.preform.customFields, updatedCF]
          };
        }
      }
      await updatePreforms([preformToUpdate]);
    },
    []
  );

  const updateCustomField = React.useCallback(
    async (preform, updatedCF) => {
      const updatedCustomFields = preform.customFields.map(cf => {
        if (cf.id === updatedCF.id) {
          return updatedCF;
        }
        return cf;
      });
      await ComponentService.updateComponent(preform.id, {
        customFields: updatedCustomFields
      });
    },
    [buildPlan]
  );

  const staticColumns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 170,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <PreformLink preform={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row.hasReadAccess === false) {
            return;
          }

          history.push({
            pathname: ROUTES.PREFORM(row.id),
            state: { from: location.pathname }
          });
        }
      },
      {
        headerName: 'Preform Type',
        field: 'preformType',
        visibilityBreakpoint: 'lg',
        minWidth: 300,
        sortable: false,
        renderCell: ({ row }) =>
          row.preformType
            ? `${row.preformType.name} (${row.preformType.labels})`
            : ''
      },
      {
        headerName: 'Build Module',
        field: 'buildModule',
        visibilityBreakpoint: 'sm',
        hide: columnsToHide ? columnsToHide.includes('buildModule') : false,
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.printer ? (
            <BuildModuleLink
              buildModule={row.printer}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.printer) {
            history.push({
              pathname: ROUTES.BUILD_MODULE(row.printer.id),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Manufacturing order',
        field: 'manufacturingOrder',
        visibilityBreakpoint: 'md',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: columnsToHide
          ? columnsToHide.includes('manufacturingOrder')
          : false,
        renderCell: ({ row }) =>
          row.manufacturingOrder ? (
            <ManufacturingOrderLink
              manufacturingOrder={row.manufacturingOrder}
            />
          ) : (
            ''
          )
      },
      {
        headerName: 'Operator',
        field: 'operator',
        visibilityBreakpoint: 'lg',
        hide: columnsToHide ? columnsToHide.includes('operator') : false,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          row.buildJob && row.buildJob.creator ? row.buildJob.creator.name : ''
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.status && (
            <ComponentStatus
              component={{
                status: row.lastState ? row.lastState.state : row.status,
                progress: row.lastState ? row.lastState.progress : row.progress
              }}
            />
          )
      },
      {
        headerName: 'Time',
        field: 'time',
        visibilityBreakpoint: 'lg',
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => (
          <TitanTimeAgo
            time={
              row.status === COMPONENT_STATUSES.SCHEDULED
                ? row.createdAt
                : row.statusUpdatedAt
            }
            start={row.startBuildTime}
            end={row.endBuildTime}
            status={row.status}
          />
        )
      },
      {
        headerName: 'Spools',
        field: 'spools',
        visibilityBreakpoint: 'lg',
        minWidth: 300,
        hide: true,
        sortable: false,
        renderCell: ({ row }) =>
          row.buildJob && row.buildJob.spools
            ? row.buildJob.spools
                .map(
                  spool =>
                    `${spool.spoolIdentifier} (${spool.materialType.name})`
                )
                .join(', ')
            : ''
      }
      /*
    {
      headerName: '',
      field: 'actions',
      hideable: false,
      hideInMenu: true,
      type: 'actions',
      visibilityBreakpoint: 'sm',
      width: 60,
      getActions: params =>
        PreformService.getPreformActions({
          preform: params.row,
          onAttachToManufacturingOrder: () => {
            setPreformToAttachToManufacturingOrder(params.row);
          },
          onDetachFromManufacturingOrder: () => {
            setPreformToDetachFromManufacturingOrder(params.row);
          }
        }).map(action => (
          <GridActionsCellItem
            icon={action.icon}
            label={action.label}
            showInMenu
            onClick={action.onClick}
            disabled={action.disabled}
          />
        ))
    }
    */
    ],
    [columnsToHide, history, location.pathname]
  );

  const pushHistory = React.useCallback(
    settedPage => {
      let newRecentPage;
      if (buildPlanId || rootBuildPlanId) {
        newRecentPage = {
          id: `BUILD_PLAN:${buildPlanId || rootBuildPlanId}`,
          url: ROUTES.BUILD_PLAN_V2_TAB_PAGE(
            buildPlanId || rootBuildPlanId,
            'preforms',
            settedPage
          ),
          label:
            settedPage === 0
              ? `${buildPlan.name} | Preforms`
              : `${buildPlan.name} | Preforms | Page : ${settedPage + 1}`
        };
      } else if (buildJobId) {
        newRecentPage = {
          id: `BUILD_JOB:${buildJobId}`,
          url: ROUTES.BUILD_JOB_TAB_PAGE(
            buildJobId,
            'preforms',
            settedPage,
            action
          ),
          label:
            settedPage === 0
              ? `${
                  buildJob?.jobKey ? buildJob?.jobKey : 'Build Job'
                } | Preforms`
              : `${
                  buildJob?.jobKey ? buildJob?.jobKey : 'Build Job'
                } | Preforms | Page : ${settedPage + 1}`,
          addInfo: {
            buildPlan: buildJob?.buildPlan
          }
        };
      }

      if (newRecentPage) {
        addPageToPageHistory(newRecentPage);
      }
    },
    [buildPlanId, rootBuildPlanId, buildJobId, action, buildPlan, buildJob]
  );

  const gridOptions = React.useMemo(() => {
    const gridOptions = {
      orders: defaultOrder,
      columns: staticColumns,
      pinnedColumns: {
        left: ['id'],
        right: []
      },
      onChangePage: page => {
        pushHistory(page);
      }
    };

    if (pageParam && Number(pageParam) > 0) {
      gridOptions.page = Number(pageParam);
    }

    return gridOptions;
  }, [pageParam, defaultOrder, staticColumns, pushHistory]);

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { setRows, setColumns } = titanDataGridProps;

  React.useEffect(() => {
    if (buildPlan) {
      const customFieldColumns = [];

      buildPlan.preformTypes
        .sort((a, b) => a.order - b.order)
        .forEach(preformType => {
          if (preformType.customFields) {
            preformType.customFields
              .sort((a, b) => a.order - b.order)
              .forEach(customField => {
                if (
                  !customFieldColumns.find(c => c.name === customField.name)
                ) {
                  customFieldColumns.push({
                    headerName: customField.name,
                    field: customField.name,
                    visibilityBreakpoint: 'lg',
                    minWidth: customField.type.endsWith('_SELECT')
                      ? 400
                      : customField.type === 'BOOLEAN'
                      ? 50
                      : 200,
                    hide: true,
                    sortable: false,
                    renderCell: ({ row }) => (
                      <RenderPreformsCell
                        row={row}
                        customField={customField}
                        buildPlan={buildPlan}
                        isEditable={isEditable}
                        preparePreformToUpdate={preparePreformToUpdate}
                        updateComponent={updateComponent}
                        updatePreforms={updatePreforms}
                        updateCustomField={updateCustomField}
                        prepareCustomFieldsObject={prepareCustomFieldsObject}
                      />
                    )
                  });
                }
              });
          }
        });

      const uniqueCustomFieldsColumns = customFieldColumns.reduce(
        (accum, current) => {
          if (!accum.find(cf => cf.field === current.field)) {
            accum.push(current);
          }
          return accum;
        },
        []
      );
      setColumns([...staticColumns, ...uniqueCustomFieldsColumns]);
    }
  }, [
    isEditable,
    staticColumns,
    setColumns,
    buildPlan,
    preparePreformToUpdate,
    updateComponent,
    updatePreforms,
    prepareCustomFieldsObject,
    updateCustomField
  ]);

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.PREFORM &&
      lastJsonMessage.data
    ) {
      updatePreforms([lastJsonMessage.data]);
    }
  }, [lastJsonMessage, updatePreforms]);

  const getRowHeight = React.useCallback(
    ({ model }) => {
      if (model.customFields) {
        const customFieldsLength = model?.customFields
          ?.filter(cf => {
            return cf.preformTypeCustomField.type.endsWith('SELECT');
          })
          ?.reduce((accum, current) => {
            if (current.preformTypeCustomField.options.length >= accum) {
              return current.preformTypeCustomField.options.length + 1;
            }
            return accum;
          }, 0);
        if (customFieldsLength && customFieldsLength > 0) {
          return 1.25 * 52;
        }
      }

      return null;
    },
    [buildPlan]
  );

  const renderPreformsFilter = React.useCallback(() => {
    if (!isHeaderShown || buildJobId) {
      return null;
    }

    if (buildPlanId) {
      return (
        <PreformsTableFilter
          buildPlanVersionType={buildPlanVersionType}
          setBuildPlanVersionType={setBuildPlanVersionType}
        />
      );
    }
  }, [isHeaderShown, buildJobId, buildPlanId]);

  const renderPreformsChips = React.useCallback(() => {
    if (!isHeaderShown || buildJobId) {
      return null;
    }

    if (buildPlanId) {
      return (
        <PreformsChips
          buildPlanVersionType={buildPlanVersionType}
          setBuildPlanVersionType={setBuildPlanVersionType}
        />
      );
    }
  }, [isHeaderShown, buildJobId, buildPlanId]);

  const onAttachToManufacturingOrder = React.useCallback(
    async manufacturingOrder => {
      try {
        const updatedPreform = await ComponentService.updateComponent(
          preformToAttachToManufacturingOrder.id,
          {
            manufacturingOrderId: manufacturingOrder.id
          }
        );
        updatePreforms([
          {
            ...updatedPreform,
            manufacturingOrder
          }
        ]);
      } catch (e) {
        pushSnackbar(e.message, {
          variant: 'error'
        });
      }
    },
    [preformToAttachToManufacturingOrder?.id, pushSnackbar, updatePreforms]
  );

  const onDetachFromManufacturingOrder = React.useCallback(async () => {
    try {
      const updatedPreform = await ComponentService.updateComponent(
        preformToDetachFromManufacturingOrder.id,
        { manufacturingOrderId: null }
      );
      updatePreforms([
        {
          ...updatedPreform,
          manufacturingOrder: null
        }
      ]);
    } catch (e) {
      pushSnackbar(e.message, {
        variant: 'error'
      });
    }
  }, [preformToDetachFromManufacturingOrder?.id, pushSnackbar, updatePreforms]);

  return (
    <>
      <TitanDataGrid
        components={{
          Toolbar: TitanDataGridToolbar
        }}
        {...titanDataGridProps}
        getRowHeight={getRowHeight}
        order={buildJobId ? {} : titanDataGridProps['order']}
        searchPlaceholder="Search by preform ID or preform type"
        showSearch={isHeaderShown}
        filtersContent={renderPreformsFilter()}
        chipsContent={renderPreformsChips()}
      />

      {preformToAttachToManufacturingOrder && (
        <ManufacturingOrderSelectionDialog
          title="Select Manufacturing Order to which you want to connect this Preform"
          filters={{
            buildPlanIds: [preformToAttachToManufacturingOrder.buildPlan.id]
          }}
          onSave={onAttachToManufacturingOrder}
          onClose={() => setPreformToAttachToManufacturingOrder(null)}
          {...preformToAttachToManufacturingOrder.manufacturingOrder && {
            currentSelection: [
              preformToAttachToManufacturingOrder.manufacturingOrder
            ]
          }}
        />
      )}

      {preformToDetachFromManufacturingOrder && (
        <TitanConfirmationDialog
          title="Remove Preform from Manufacturing Order"
          message="Are you sure you want to remove this Preform from Manufacturing Order?"
          onConfirm={() => onDetachFromManufacturingOrder()}
          onClose={() => setPreformToDetachFromManufacturingOrder(null)}
        />
      )}
    </>
  );
}
