import React from 'react';
import Grid from '@mui/material/Grid';

import { useFuseDialog } from './FuseDialog';

import FusionModuleCard from '../FusionModule/FusionModuleCard';

export default function FuseDialogFusionModulesStep() {
  const {
    fusionModules,
    selectedFusionModule,
    setSelectedFusionModule
  } = useFuseDialog();

  const onSelectFusionModule = React.useCallback(
    (e, fusionModule) => {
      setSelectedFusionModule(fusionModule);
    },
    [setSelectedFusionModule]
  );

  return (
    <Grid container spacing={3}>
      {fusionModules.map(fusionModule => (
        <Grid key={fusionModule.id} item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FusionModuleCard
            fusionModule={fusionModule}
            onClick={onSelectFusionModule}
            selected={fusionModule === selectedFusionModule}
          />
        </Grid>
      ))}
    </Grid>
  );
}
