import Dialog from '@mui/material/Dialog';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import { QrReader } from 'react-qr-reader';
import { Card, CardActions } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import ComponentService from '../../services/ComponentService';
import Button from '@mui/material/Button';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';

const DEFAULT_TEXT = '';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  }
}));

export default function ScanComponentDialog({
  fusionPlan,
  setOpenScanDialog,
  onSelect,
  setStep
}) {
  const classes = useStyles();

  const [components, setComponents] = React.useState([]);
  const [mounted, setMounted] = React.useState(true);
  const [result, setResult] = React.useState(DEFAULT_TEXT);
  const [selectedPreforms, setSelectedPreforms] = React.useState([]);

  const isValidHttpUrl = string => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const handleScan = async data => {
    if (data && isValidHttpUrl(data)) {
      await setResult(isValidHttpUrl(data) ? data.split('/').pop() : data);

      ComponentService.getComponent(result, {
        withRelated: ['buildJob', 'printer', 'buildPlan']
      }).then(assembly => {
        if (components.filter(e => e.id === assembly.id).length === 0) {
          setComponents([...components, assembly]);
        }
      });
    }
  };

  const handleError = err => {
    console.error(err);
  };

  const [loading] = React.useState(false);

  const onClose = () => {
    setMounted(false);
    setOpenScanDialog(false);
  };

  const onSelectionChange = selectedRows => {
    setSelectedPreforms(selectedRows);
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: 'Build Plan',
        field: 'buildPlanName',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row.buildPlan ? row.buildPlan.name : '')
      }
    ],
    []
  );

  return (
    <Dialog
      open={true}
      maxWidth="100%"
      title={`Fuse Build ${fusionPlan.name}`}
      onClose={onClose}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container justifyContent="center" spacing={0}>
          <Card>
            <CardContent elevation={0} spacing={0}>
              {mounted && (
                <QrReader
                  delay={100}
                  facingMode="user"
                  onError={handleError}
                  onScan={handleScan}
                  style={{ height: 320, width: 320 }}
                />
              )}
            </CardContent>
          </Card>
          <Card>
            <div className={classes.details}>
              <CardContent>
                <TitanDataGrid
                  rows={components}
                  showSearch={false}
                  columns={columns}
                  onSelectionChange={onSelectionChange}
                />
              </CardContent>

              {!!components.length && (
                <CardActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenScanDialog(false);
                      onSelect(selectedPreforms);
                      setStep(2);
                    }}
                    title="Add"
                  >
                    Add
                  </Button>
                </CardActions>
              )}
            </div>
          </Card>
        </Grid>
      )}
    </Dialog>
  );
}
