import React from 'react';
import { colors } from '../Theme/vars';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

export default function TitanInfoItem({
  label = '',
  chipInfo = '',
  children = '',
  styleOfItem = 'common'
}) {
  return (
    <Box
      sx={{
        p: styleOfItem === 'vertical' ? '8px 8px 8px 0' : 1,
        display: styleOfItem === 'vertical' ? 'flex' : 'block',
        alignItems: styleOfItem === 'vertical' ? 'center' : 'none',
        minWidth: styleOfItem === 'vertical' ? '120px' : 'auto'
      }}
    >
      <Box
        sx={{
          color: colors.GREY,
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          marginRight: styleOfItem === 'vertical' ? '10px' : 0
        }}
      >
        <Typography>
          {label} {styleOfItem === 'vertical' ? ':' : ''}
        </Typography>
        {chipInfo && (
          <Chip
            size="small"
            label={chipInfo}
            sx={{ ml: 1, color: colors.STONE }}
          />
        )}
      </Box>
      <div>{children}</div>
    </Box>
  );
}
