import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import FusorService from '../../services/FusorService';
import REGEXPS from '../../constants/regexps';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OrganizationService from '../../services/OrganizationService';
import { useAuth0 } from '../Authentication/Auth0';

export default function FusorDialog(props) {
  const { onClose, onSave, fusor } = props;
  const { isSuperAdmin } = useAuth0();

  const [name, setName] = React.useState(fusor ? fusor.name : '');
  const [nameError, setNameError] = React.useState(false);
  const [organizationId, setOrganizationId] = React.useState(
    fusor ? fusor.organizationId : props.organizationId
  );
  const [organizations, setOrganizations] = React.useState([]);

  const onChangeName = e => {
    setName(e.target.value);
    setNameError(!REGEXPS.IOT_THING_NAME.test(e.target.value));
  };

  const handleSave = async () => {
    const data = {
      name,
      organizationId
    };

    let fusor;

    if (props.fusor) {
      fusor = await FusorService.updateFusor(props.fusor.id, data);
    } else {
      fusor = await FusorService.createFusor(data);
    }

    onSave(fusor);
  };

  React.useEffect(() => {
    if (isSuperAdmin) {
      OrganizationService.getOrganizations().then(response =>
        setOrganizations(response.data)
      );
    }
  }, []);

  return (
    <TitanDialog
      title={`${fusor ? 'Edit' : 'Add'} Fusion Module`}
      onClose={onClose}
      onSave={handleSave}
      isValid={!nameError && name.length !== 0 && organizationId}
      successSaveMessage={`Fusion Module ${fusor ? 'update' : 'created'}`}
    >
      <TextField
        required
        label="Name"
        placeholder="Name"
        value={name}
        onChange={onChangeName}
        fullWidth
        margin="normal"
        error={nameError}
        helperText="only alphanumeric characters and/or the following: -_:"
      />
      {isSuperAdmin && (
        <FormControl required fullWidth margin="dense">
          <InputLabel>Organization</InputLabel>
          <Select
            value={organizationId}
            onChange={e => setOrganizationId(e.target.value)}
          >
            {organizations &&
              organizations.map(organization => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </TitanDialog>
  );
}
