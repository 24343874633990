import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TitanEditableText from '../Titan/TitanEditableText';
import TitanEditableSelect from '../Titan/TitanEditableSelect';
import Stack from '@mui/material/Stack';
import FibrifyMeasurement from '../Fibrify/FibrifyMeasurement';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { toNumber } from 'lodash';

export default function EditableCustomField({
  customField,
  value,
  onChange,
  enableEditing = true,
}) {
  const customFieldUnits = customField.units
    ? customField.units.toLowerCase()
    : '';

  const expectedValue = customField.expectedValue;

  let difference = null;

  if (customField.type === 'NUMBER' && expectedValue && value) {
    const expectedValueNumber = toNumber(expectedValue.replace(/\D+$/g, ''));
    const valueNumber = toNumber(value.replace(/\D+$/g, ''));

    difference = Math.round((valueNumber - expectedValueNumber) * 100) / 100;
  }

  const textField = (
    <Stack>
      <TitanEditableText
        enableEditing={enableEditing}
        text={value}
        label={customField.name}
        onChangeValue={onChange}
        suffix={customFieldUnits}
        inputType={customField.type}
      />

      {expectedValue ? (
        <FibrifyMeasurement
          label="Expected"
          value={expectedValue}
          units={customFieldUnits}
        />
      ) : (
        ''
      )}

      {difference !== null ? (
        <FibrifyMeasurement
          label="Difference"
          value={`${difference > 0 ? '+' : ''}${difference}`}
          units={customFieldUnits}
        />
      ) : (
        ''
      )}
    </Stack>
  );

  const booleanField = (
    <Stack>
      <FormControl>
        <RadioGroup
          row
          value={value ? value : ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <Typography variant="caption">
        Expected:
        <Typography variant="body1" component="span" sx={{ ml: 1 }}>
          {expectedValue === 'true' ? 'Yes' : 'No'}
        </Typography>
      </Typography>
    </Stack>
  );

  const selectField = (
    <TitanEditableSelect
      enableEditing={enableEditing}
      text={value}
      inputType={customField.type}
      label={customField.name}
      onChangeValue={onChange}
      options={customField.options}
    />
  );

  let field = textField;

  if (customField.type === 'BOOLEAN') {
    field = booleanField;
  } else if (
    customField.type === 'SINGLE_SELECT' ||
    customField.type === 'MULTI_SELECT'
  ) {
    field = selectField;
  }

  return field;
}
