import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import MemberService from '../../services/MemberService';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';

export default function FibrifyMemberSelectionDialog({
  title,
  multipleSelection = false,
  currentMembers = [],
  includeMemberIds = [],
  excludeMemberIds = [],
  email,
  onSave,
  saveButtonLabel = 'Confirm',
  onClose
}) {
  const { profile } = useAuth0();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        organizationId: profile.organizationId,
        ...(includeMemberIds && { includeMemberIds }),
        ...(excludeMemberIds && { excludeMemberIds }),
        withRelated: ['roles'],
        page: page,
        pageSize: pageSize
      };

      if (email) {
        params.email = email;
      }

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await MemberService.loadOrganizationMembers(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [profile, includeMemberIds, email]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sortable: false,
        flex: 1
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: false,
        flex: 1
      },
      {
        headerName: 'Roles',
        field: 'roles',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => row.roles.map(role => role.name).join(', ')
      }
    ],
    []
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      selectedItems={currentMembers}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by name or email"
    />
  );
}
