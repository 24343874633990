import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
  Table,
  TableRow,
  TableContainer,
  TableBody
} from '@mui/material';
import {
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const TablePaginationActions = props => {
  const { count, page, rowsPerPage, onPageChange, nextIconButtonProps } = props;

  const theme = useTheme();

  const handleFirstPageButtonClick = e => {
    onPageChange(e, 0);
  };

  const handleBackButtonClick = e => {
    onPageChange(e, page - 1);
  };

  const handleNextButtonClick = e => {
    onPageChange(e, page + 1);
  };

  const handleLastPageButtonClick = e => {
    onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        aria-label="next page"
        {...nextIconButtonProps}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      {count !== -1 && (
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      )}
    </Box>
  );
};

export const CustomPagination = props => {
  const { rowCount, nextPageAvailable } = props;

  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const handleChangePage = React.useCallback(
    (e, newPage) => {
      apiRef.current.setPage(newPage);
    },
    [apiRef.current.setPage]
  );

  const handleChangeRowsPerPage = React.useCallback(
    e => {
      apiRef.current.setPageSize(parseInt(e.target.value, 10));
      apiRef.current.setPage(0);
    },
    [apiRef.current.setPageSize, apiRef.current.setPage]
  );

  return (
    <TableContainer sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={rowCount}
              {...(rowCount === -1
                ? {
                    labelDisplayedRows: ({ from, to }) => `${from}–${to}`
                  }
                : {})}
              rowsPerPage={pageSize}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              nextIconButtonProps={{
                disabled:
                  rowCount === -1
                    ? !nextPageAvailable
                    : page >= Math.ceil(rowCount / pageSize) - 1
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
