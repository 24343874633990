import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ImageIcon from '@mui/icons-material/Image';
import FileService from '../../services/FileService';
import Box from '@mui/material/Box';
import CachedIcon from '@mui/icons-material/Cached';

export default function ProjectForm({
  name,
  setName,
  salesOrderNumber,
  setSalesOrderNumber,
  projectNumber,
  setProjectNumber,
  customerAbbreviation,
  setCustomerAbbreviation,
  partName,
  setPartName,
  projectKey,
  setProjectKey,
  previewImage,
  setPreviewImage,
  autoGenerateProjectKey,
  setAutoGenerateProjectKey,
  displayImage,
  setDisplayImage
}) {
  const [imageUploading, setImageUploading] = React.useState(false);
  const [
    projectKeyChangedManually,
    setProjectKeyChangedManually
  ] = React.useState(false);

  const imageInput = React.useRef();

  const handleImageUpload = async e => {
    if (e.target.files.length === 0) {
      return;
    }

    setImageUploading(true);
    const file = e.target.files[0];

    const fileData = await FileService.uploadFile(file.name, file);
    const base64File = await FileService.fileToBase64(file);

    setImageUploading(false);
    setDisplayImage(base64File);
    setPreviewImage(fileData);
  };

  const handleImageClear = () => {
    setPreviewImage(null);
    setDisplayImage(null);
    imageInput.current.value = '';
  };

  const generateProjectKey = React.useCallback(() => {
    setProjectKey(
      [projectNumber, customerAbbreviation, partName]
        .filter(i => i && i.length !== 0)
        .join('_')
    );
  }, [projectNumber, customerAbbreviation, partName]);

  React.useEffect(() => {
    if (!autoGenerateProjectKey || projectKeyChangedManually) {
      return;
    }

    generateProjectKey();
  }, [generateProjectKey, autoGenerateProjectKey, projectKeyChangedManually]);

  return (
    <>
      <TextField
        required
        label="Project name"
        value={name}
        onChange={e => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Sales Order Number"
        value={salesOrderNumber}
        onChange={e => setSalesOrderNumber(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        type="number"
        label="Project Number"
        value={projectNumber}
        onChange={e => setProjectNumber(parseInt(e.target.value, 10))}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="Customer's Abbreviation"
        value={customerAbbreviation}
        onChange={e =>
          setCustomerAbbreviation(
            e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, '')
          )
        }
        fullWidth
        margin="normal"
        error={customerAbbreviation.length > 5}
        helperText={
          customerAbbreviation.length > 5
            ? "Max length of the customer's name abbreviation is 5 symbols"
            : ''
        }
      />
      <TextField
        label="Part Name"
        value={partName}
        onChange={e => setPartName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Project Key"
        value={projectKey}
        onChange={e => {
          setProjectKeyChangedManually(true);
          setProjectKey(e.target.value);
        }}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => {
                generateProjectKey();
                setAutoGenerateProjectKey(true);
                setProjectKeyChangedManually(false);
              }}
            >
              <CachedIcon />
            </IconButton>
          )
        }}
      />
      <Box sx={{ mt: 2, mb: 2 }}>
        {imageUploading ? (
          <CircularProgress sx={{ display: 'block', mt: 2, mb: 4 }} />
        ) : (
          displayImage && <PreviewImage src={displayImage} alt="" />
        )}
        <Button variant="contained" component="label" startIcon={<ImageIcon />}>
          Upload Image
          <input
            hidden
            type="file"
            accept="image/*"
            ref={imageInput}
            onChange={handleImageUpload}
          />
        </Button>
        {displayImage && (
          <Button variant="outlined" onClick={handleImageClear} sx={{ ml: 2 }}>
            Clear Image
          </Button>
        )}
      </Box>
    </>
  );
}

const PreviewImage = styled('img')({
  display: 'block',
  maxWidth: '50%',
  maxHeight: '200px',
  margin: '10px 0'
});
