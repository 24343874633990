import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { format } from 'date-fns';
import { ROLES } from '../../constants';
import Autocomplete from '@mui/material/Autocomplete';
import TagService from '../../services/TagService';
import { Chip } from '@mui/material';
import useAsyncEffect from 'use-async-effect';

const ITEMS_TYPES = {
  PREFORMS: 'PREFORMS',
  FINAL_PARTS: 'FINAL_PARTS'
};

export default function ManufacturingOrderDialog({
  projectId,
  manufacturingOrder,
  profile,
  onCreate,
  onUpdate,
  onClose
}) {
  const [name, setName] = React.useState(manufacturingOrder?.name || '');
  const [preformsCount, setPreformsCount] = React.useState(
    manufacturingOrder?.preformsCount || ''
  );
  const [finalPartsCount, setFinalPartsCount] = React.useState(
    manufacturingOrder?.finalPartsCount || ''
  );
  const [dates, setDates] = React.useState([null, null]);
  const [spoolTags, setSpoolTags] = React.useState(
    manufacturingOrder &&
      manufacturingOrder.spoolTags &&
      manufacturingOrder.spoolTags.length !== 0
      ? manufacturingOrder.spoolTags.map(tag => tag.name)
      : []
  );
  const [availableSpoolTags, setAvailableSpoolTags] = React.useState([]);

  const [countItemsType, setCountItemsType] = React.useState(
    manufacturingOrder?.preformsCount
      ? ITEMS_TYPES.PREFORMS
      : ITEMS_TYPES.FINAL_PARTS
  );

  const [formValid, setFormValid] = React.useState(false);

  const onSubmitForm = () => {
    const data = {
      name,
      ...(countItemsType === ITEMS_TYPES.PREFORMS && { preformsCount }),
      ...(countItemsType === ITEMS_TYPES.FINAL_PARTS && { finalPartsCount }),
      ...(projectId && { projectId }),
      ...(dates[0] && { startDate: format(dates[0], 'yyyy-MM-dd') }),
      ...(dates[1] && { finishDate: format(dates[1], 'yyyy-MM-dd') }),
      spoolTags
    };

    if (!manufacturingOrder?.id) {
      return onCreate(data);
    } else {
      return onUpdate(data);
    }
  };

  React.useEffect(() => {
    const isOrganizationAdmin = profile.roles.find(r => r.id === ROLES.ADMIN);
    const isApplicationEngineer = profile.roles.find(
      r => r.id === ROLES.APPLICATION_ENGINEER
    );

    const numberOfItems =
      countItemsType === ITEMS_TYPES.PREFORMS
        ? preformsCount !== ''
        : finalPartsCount !== '';

    if (
      name &&
      numberOfItems &&
      (isOrganizationAdmin || isApplicationEngineer)
    ) {
      setFormValid(true);
      return;
    }

    setFormValid(false);
  }, [profile, name, preformsCount, finalPartsCount, countItemsType]);

  React.useEffect(() => {
    let startDate = null;
    let finishDate = null;

    if (manufacturingOrder?.startDate) {
      startDate = new Date(manufacturingOrder.startDate);
    }
    if (manufacturingOrder?.finishDate) {
      finishDate = new Date(manufacturingOrder.finishDate);
    }

    setDates([startDate, finishDate]);
  }, [manufacturingOrder?.startDate, manufacturingOrder?.finishDate]);

  const loadAvailableSpoolTags = async () => {
    const { tags } = await TagService.getTags({ tagType: 'SPOOL' });

    setAvailableSpoolTags(tags.map(tag => tag.name));
  };

  useAsyncEffect(async () => {
    await loadAvailableSpoolTags();
  }, []);

  const onChangeSpoolTags = (e, values) => {
    setSpoolTags(values);
  };

  return (
    <TitanDialog
      title={`${
        !manufacturingOrder?.id ? 'Create' : 'Edit'
      } Manufacturing Order`}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      onSave={onSubmitForm}
      isValid={formValid}
    >
      <TextField
        label={`Name of Manufacturing Order ${
          countItemsType === ITEMS_TYPES.PREFORMS
            ? 'for Preforms'
            : 'for Final Parts'
        }`}
        type="text"
        value={name}
        onChange={e => setName(e.target.value)}
        required
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <RadioGroup
        row
        value={countItemsType}
        onChange={e => setCountItemsType(e.target.value)}
      >
        <FormControlLabel
          value={ITEMS_TYPES.PREFORMS}
          control={<Radio />}
          label="Preforms"
        />
        <FormControlLabel
          value={ITEMS_TYPES.FINAL_PARTS}
          control={<Radio />}
          label="Final Parts"
        />
      </RadioGroup>
      {countItemsType === ITEMS_TYPES.PREFORMS ? (
        <TextField
          label="Number of Preforms"
          type="number"
          value={preformsCount}
          onChange={e =>
            setPreformsCount(
              Math.abs(e.target.value) > 0 ? Math.abs(e.target.value) : ''
            )
          }
          required
          fullWidth
          margin="normal"
          variant="outlined"
        />
      ) : (
        <TextField
          label="Number of Final Parts"
          type="number"
          value={finalPartsCount}
          onChange={e =>
            setFinalPartsCount(
              Math.abs(e.target.value) > 0 ? Math.abs(e.target.value) : ''
            )
          }
          required
          fullWidth
          margin="normal"
          variant="outlined"
        />
      )}
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: 'Start', end: 'Finish' }}
      >
        <Box sx={{ mt: 2, mb: 2 }}>
          <DateRangePicker
            value={dates}
            inputFormat="yyyy-MM-dd"
            onChange={newValue => {
              setDates(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </Box>
      </LocalizationProvider>
      {countItemsType === ITEMS_TYPES.PREFORMS ? (
        <Autocomplete
          multiple
          id="mo-spool-tags"
          value={spoolTags}
          onChange={onChangeSpoolTags}
          options={availableSpoolTags}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Spool tags"
              placeholder="Spool tags"
            />
          )}
          onBlur={event => {
            const value = event.target.value.trim();

            if (value !== '') {
              setSpoolTags(prev => [...prev, value]);
            }
          }}
          clearOnBlur
        />
      ) : (
        ''
      )}
    </TitanDialog>
  );
}
