export default {
  BUILD_MODULE_STATE: 'BUILD_MODULE_STATE',
  BUILD_MODULE_STATUS: 'BUILD_MODULE_STATUS',
  FUSION_MODULE_STATE: 'FUSION_MODULE_STATE',

  BUILD_MODULE_TELEMETRY: 'BUILD_MODULE_TELEMETRY',
  FUSION_MODULE_TELEMETRY: 'FUSION_MODULE_TELEMETRY',

  BUILD_JOB: 'BUILD_JOB',
  BUILD_JOB_STATE: 'BUILD_JOB_STATE',
  PREFORM: 'PREFORM',

  FUSION_PLAN: 'FUSION_PLAN',

  FUSION_JOB: 'FUSION_JOB',
  FINAL_PART: 'FINAL_PART'
};
