import React from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import SearchIcon from '../../assets/icons/search.svg';
import CrossDark from '../../assets/icons/cross-dark.svg';
import SortOrderIcon from '../../assets/icons/sort-order-icon.svg';
import { SORT_ORDERS } from '../../constants';
import { VIEW_MODES } from '../TitanDataGrid/TitanDataGrid';
import Button from '@mui/material/Button';
import ActionsMenu from '../Menu/ActionsMenu';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';

const useStyles = makeStyles(theme => ({
  orderButton: {
    marginRight: theme.spacing(1)
  },
  arrowDown: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
    animation: `$backwardRotation 200ms ${
      theme.transitions.easing.easeInOut
    } forwards`
  },
  arrowUp: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
    transform: 'rotate(180deg)',
    animation: `$rotation 200ms ${theme.transitions.easing.easeInOut} forwards`
  },

  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(180deg)'
    }
  },

  '@keyframes backwardRotation': {
    '0%': {
      transform: 'rotate(180deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  search: {
    width: '50%'
  },
  rightPane: {
    display: 'flex'
  },
  label: {
    marginRight: theme.spacing(1)
  },
  filters: {
    display: 'flex',
    alignItems: 'center'
  },
  orders: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  order: {
    marginLeft: theme.spacing(1),
    textTransform: 'none'
  },
  clearSearch: {
    cursor: 'pointer'
  },
  chipsContent: {
    marginTop: theme.spacing(2)
  }
}));

const orderTitles = {
  created_at: 'Date',
  updated_at: 'Date',
  name: 'Name',
  buildFuseTime: 'Build/Fuse Time',
  'preform_types.order': 'Preform order'
};

export default function TitanPageFilters({
  showSearch = true,
  searchPlaceholder = '',
  search,
  onChangeSearch,
  allowViewModeChange,
  viewMode,
  onViewModeChange,
  order,
  onChangeOrder,
  filtersContent,
  chipsContent,
  actions
}) {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.search}>
          {showSearch && (
            <TextField
              fullWidth
              variant="outlined"
              placeholder={searchPlaceholder}
              value={search}
              onChange={e => onChangeSearch(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search ? (
                      <img
                        src={CrossDark}
                        alt=""
                        className={classes.clearSearch}
                        onClick={() => onChangeSearch('')}
                      />
                    ) : (
                      <img src={SearchIcon} alt="" />
                    )}
                  </InputAdornment>
                )
              }}
            />
          )}
        </div>
        <div className={classes.rightPane}>
          {filtersContent && (
            <div className={classes.filters}>
              <div className={classes.filters}>{filtersContent}</div>
            </div>
          )}

          {order && Object.keys(order).length !== 0 ? (
            <div className={classes.orders}>
              {Object.keys(order).map(key => {
                return (
                  <Button
                    className={classes.order}
                    key={key}
                    onClick={() => {
                      const newOrder =
                        order[key] === SORT_ORDERS.DESC
                          ? SORT_ORDERS.ASC
                          : SORT_ORDERS.DESC;

                      onChangeOrder({ ...order, [key]: newOrder });
                    }}
                  >
                    <Typography variant="body2" className={classes.orderButton}>
                      {orderTitles[key]}{' '}
                    </Typography>
                    {order[key] && (
                      <img
                        src={SortOrderIcon}
                        alt=""
                        className={
                          order[key] === SORT_ORDERS.ASC
                            ? classes.arrowUp
                            : classes.arrowDown
                        }
                      />
                    )}
                  </Button>
                );
              })}
            </div>
          ) : (
            ''
          )}

          {actions && <ActionsMenu items={actions} />}

          {allowViewModeChange && (
            <>
              <IconButton
                onClick={() => {
                  const pageName = _.camelCase(pathname);
                  localStorage.setItem(`${pageName}ViewMode`, VIEW_MODES.GRID);
                  onViewModeChange(VIEW_MODES.GRID);
                }}
              >
                <GridViewIcon
                  color={viewMode === VIEW_MODES.GRID ? 'primary' : 'inherit'}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  const pageName = _.camelCase(pathname);
                  localStorage.setItem(`${pageName}ViewMode`, VIEW_MODES.TABLE);
                  onViewModeChange(VIEW_MODES.TABLE);
                }}
              >
                <ViewListIcon
                  color={viewMode === VIEW_MODES.TABLE ? 'primary' : 'inherit'}
                />
              </IconButton>
            </>
          )}
        </div>
      </div>

      {chipsContent && (
        <div className={classes.chipsContent}>{chipsContent}</div>
      )}
    </div>
  );
}
