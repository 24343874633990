import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';
import REGEXPS from '../../constants/regexps';

export default function FusionPlanDialog({
  fusionPlan,
  onClose,
  onSave,
  currentMemberId
}) {
  const [fusionPlanName, setFusionPlanName] = React.useState(
    fusionPlan.name || ''
  );
  const [planKey, setPlanKey] = React.useState(fusionPlan.planKey || '');
  const [isFormValid, setIsFormValid] = React.useState(false);

  const onSubmitForm = () =>
    onSave({
      name: fusionPlanName,
      planKey
    });

  const isOwner =
    fusionPlan.memberId === currentMemberId || !fusionPlan.memberId;

  // Form Validation
  React.useEffect(() => {
    const planKeyValid =
      planKey.length > 0 ? REGEXPS.PLAN_KEY.test(planKey) : true;

    setIsFormValid(!!fusionPlanName && planKeyValid);
  }, [planKey, fusionPlanName]);

  return (
    <TitanDialog
      title={`${fusionPlan.id ? 'Update' : 'Create'} Fusion Plan`}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      onSave={onSubmitForm}
      isValid={isFormValid}
    >
      <TextField
        autoFocus
        id="name"
        label="name"
        type="text"
        value={fusionPlanName}
        fullWidth
        margin="normal"
        onChange={e => setFusionPlanName(e.target.value)}
        disabled={!isOwner}
        variant="outlined"
      />
      <TextField
        id="planKey"
        label="Plan key"
        type="text"
        disabled={fusionPlan.hasFinalParts}
        value={planKey}
        fullWidth
        margin="normal"
        onChange={e => setPlanKey(e.target.value.toUpperCase())}
        variant="outlined"
        inputProps={{ style: { textTransform: 'uppercase' } }}
      />
    </TitanDialog>
  );
}
