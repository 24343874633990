import axios from 'axios';

export default class PrintHeadService {
  static async getPrintHeads(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/print-heads`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async printHeadIdExists(params) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/print-heads/print-head-id-exists`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static async createPrintHead(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v2/print-heads`, data)
      .then(res => res.data.data);
  }

  static async updatePrintHead(printHeadId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/print-heads/${printHeadId}`,
        data
      )
      .then(res => res.data.data);
  }

  static async deletePrintHead(printHeadId) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v2/print-heads/${printHeadId}`
      )
      .then(res => res.data);
  }
}
