import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import PresetService from '../../services/PresetService';
import TitanPage from '../Titan/TitanPage';
import CreateIcon from '@mui/icons-material/Create';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/GetApp';
import PresetContent from './PresetContent';
import { PRESET_CATEGORIES } from '../../constants/presetCategories';
import { PERMISSIONS } from '../../constants/auth0';
import { ROUTES } from '../../constants';
import {
  Chip,
  Button,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  Stack,
  Tooltip
} from '@mui/material';
import PresetDialog from './PresetsDialog';
import PresetChips, { PRESET_COMPLEXITIES } from './PresetChips';
import PresetImportDialog from './PresetImportDialog';

const FileDownload = require('js-file-download');

export default function PresetPage() {
  const location = useLocation();

  const { presetId } = useParams();
  const { permissions, checkPermissions } = useAuth0();
  const { backToPreviousPage, pushSnackbar } = useTitan();
  const [preset, setPreset] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(
    PRESET_CATEGORIES.FIBER
  );
  const [complexityFilter, setComplexityFilter] = React.useState(
    PRESET_COMPLEXITIES.BASIC
  );

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const [presetImportData, setPresetImportData] = React.useState(null);

  const canEditPreset = React.useMemo(
    () =>
      checkPermissions(permissions, [PERMISSIONS.WRITE_PRESETS]) &&
      !preset?.deletedAt,
    [preset, permissions]
  );

  const disableReason = React.useMemo(() => {
    switch (true) {
      case !checkPermissions(permissions, [PERMISSIONS.WRITE_PRESETS]):
        return "You don't have rights to modify a Preset";
      case !!preset?.deletedAt:
        return "You can't modify deleted preset";
      default:
        return '';
    }
  }, [preset, permissions]);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Presets',
        path: ROUTES.GEAR + '/presets'
      },
      {
        name: preset ? preset.name : '',
        disabled: true
      }
    ],
    [preset, presetId]
  );

  useAsyncEffect(async () => {
    setError(false);
    setLoading(true);
    const presetIncoming = await PresetService.getPreset(presetId);

    if (presetIncoming === undefined) {
      setError(`Preset not found: ${presetId}`);
      setLoading(false);
      setPreset(undefined);
      return;
    }
    setPreset(presetIncoming.data);
    setLoading(false);
  }, [presetId]);

  const exportPreset = () => {
    FileDownload(
      JSON.stringify({
        Name: preset.name,
        PrintingMaterials: {
          FiberMaterialId: preset.fiberMaterialTypeId,
          PolymerMaterialId: preset.polymerMaterialTypeId
        },
        Entries: preset.entries
      }),
      `${preset.name}.json`
    );
  };

  const importPreset = e => {
    if (preset === undefined) {
      pushSnackbar('There is no preset', { variant: 'error' });
      return;
    }
    const reader = new FileReader();

    reader.onload = async event => {
      const data = JSON.parse(event.target.result);

      const configuration = data.configuration ? data.configuration : data;

      const updatedProperties = {
        id: preset.id,
        name: preset.name,
        entries: configuration.Entries
      };

      updatedProperties.fiberMaterialTypeId = configuration?.PrintingMaterials
        ?.FiberMaterialId
        ? configuration.PrintingMaterials.FiberMaterialId
        : preset.fiberMaterialTypeId;

      updatedProperties.polymerMaterialTypeId = configuration?.PrintingMaterials
        ?.PolymerMaterialId
        ? configuration.PrintingMaterials.PolymerMaterialId
        : preset.polymerMaterialTypeId;

      setPresetImportData(updatedProperties);
    };

    reader.readAsText(e.target.files[0]);
  };

  const onSaveImportData = React.useCallback(async updatedProperties => {
    const updatedPreset = await PresetService.updatePreset(updatedProperties);

    if (updatedPreset) {
      setPreset(updatedPreset);
      pushSnackbar(`Preset settings successfully imported`, {
        variant: 'success'
      });
    } else {
      pushSnackbar(
        'Preset upload failed...\nAre polymer and fiber materials exists?',
        { variant: 'error' }
      );
    }
  }, []);

  const parsedPreset = React.useMemo(() => {
    const parsedPreset = [];

    if (preset && preset.entries) {
      switch (selectedCategory) {
        case PRESET_CATEGORIES.FIBER: {
          preset.entries.forEach(entry => {
            if (entry.Item1 === null) return;
            parsedPreset.push({
              id: Math.random().toString(),
              key: entry.Item1.Name,
              value: entry.Item1.Value,
              description: entry.Item1.Description,
              complexity: entry.Item1.ComplexityLevel
            });
          });
          break;
        }
        case PRESET_CATEGORIES.POLYMER: {
          preset.entries.forEach(entry => {
            if (entry.Item2 === null) return;
            parsedPreset.push({
              id: Math.random().toString(),
              key: entry.Item2.Name,
              value: entry.Item2.Value,
              description: entry.Item2.Description,
              complexity: entry.Item2.ComplexityLevel
            });
          });
          break;
        }
      }
    }

    return parsedPreset;
  }, [preset, selectedCategory]);

  return (
    <>
      <TitanPage
        loading={loading}
        error={error}
        breadcrumbs={breadcrumbs}
        title={
          <Stack direction="row" alignItems="center">
            {preset ? `${preset.name}` : ''}
            {preset?.deletedAt && (
              <Chip
                label="DELETED"
                color="primary"
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
          </Stack>
        }
        onBackButtonClick={() => {
          backToPreviousPage(location);
        }}
        titleContent={
          preset && (
            <Tooltip title={disableReason}>
              <span>
                <IconButton
                  size="large"
                  onClick={() => setOpenEditDialog(true)}
                  disabled={!canEditPreset}
                >
                  <CreateIcon />
                </IconButton>
              </span>
            </Tooltip>
          )
        }
      >
        {preset ? (
          <Paper sx={{ p: 2 }}>
            <>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2
                }}
              />
            </>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Grid sx={{ pb: 3 }}>
                  <Typography variant="h2" sx={{ mr: 2, mb: 2 }}>
                    Preset properties
                  </Typography>
                </Grid>
                <Tabs
                  orientation="vertical"
                  value={selectedCategory}
                  onChange={(event, category) => {
                    setSelectedCategory(category);
                    setPage(0);
                  }}
                  sx={{ '& .MuiTabs-indicator': { left: 0, right: 'auto' } }}
                >
                  {Object.keys(PRESET_CATEGORIES).map(category => {
                    return (
                      <Tab
                        sx={{ alignItems: 'start' }}
                        key={category}
                        label={category}
                        value={PRESET_CATEGORIES[category]}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mb: 2
                  }}
                >
                  <PresetChips
                    complexityFilter={complexityFilter}
                    setComplexityFilter={complexityFilter => {
                      setComplexityFilter(complexityFilter);
                      setPage(0);
                    }}
                  />

                  <Stack flexDirection="row">
                    <label htmlFor="import-fusion-plan-configuration-file">
                      <input
                        accept="application/json"
                        id="import-fusion-plan-configuration-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={importPreset}
                        disabled={!canEditPreset}
                        onClick={event => {
                          event.target.value = null;
                        }}
                      />
                      <Tooltip title={disableReason}>
                        <span>
                          <Button
                            variant="outlined"
                            startIcon={<FileUploadIcon />}
                            component="span"
                            disabled={!canEditPreset}
                          >
                            Import
                          </Button>
                        </span>
                      </Tooltip>
                    </label>
                    <Button
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      sx={{ ml: 2 }}
                      onClick={exportPreset}
                    >
                      Export
                    </Button>
                  </Stack>
                </Grid>
                {preset && !preset.entries && (
                  <Grid container>
                    <Typography>
                      No configuration exists in this preset
                    </Typography>
                  </Grid>
                )}
                {preset && preset.entries && (
                  <PresetContent
                    parsedPreset={parsedPreset}
                    complexityFilter={complexityFilter}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                  />
                )}
              </Grid>
            </Grid>
            {openEditDialog && preset && (
              <PresetDialog
                preset={preset}
                onClose={() => {
                  setOpenEditDialog(false);
                }}
                onSave={async params => {
                  const updatedPresetParameters = {
                    id: preset.id,
                    name: params.name,
                    entries: preset?.Entries,
                    fiberMaterialTypeId: params.fiberMaterialTypeId,
                    polymerMaterialTypeId: params.polymerMaterialTypeId
                  };

                  const updatedPreset = await PresetService.updatePreset(
                    updatedPresetParameters
                  );
                  if (updatedPreset === false) {
                    pushSnackbar('Preset update failed', {
                      variant: 'error'
                    });
                  } else {
                    pushSnackbar('Preset updated successfully', {
                      variant: 'success'
                    });
                    setPreset(updatedPreset);
                  }
                }}
              />
            )}
          </Paper>
        ) : (
          <Paper sx={{ p: 2 }}>Preset does not exists</Paper>
        )}

        {presetImportData && (
          <PresetImportDialog
            onClose={() => setPresetImportData(null)}
            onSave={onSaveImportData}
            preset={presetImportData}
          />
        )}
      </TitanPage>
    </>
  );
}
