import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BuildJobActions from './BuildJobActions';
import BuildJobService from '../../services/BuildJobService';
import { BUILD_JOB_ACTION_TYPES } from '../../constants';
import { Tooltip } from '@mui/material';

function BuildJobPageActionButtons({ buildJob, setBuildJob }) {
  const [actions, setActions] = React.useState([]);
  const [currentAction, setCurrentAction] = React.useState();

  React.useEffect(() => {
    if (!buildJob) {
      return;
    }

    const actions = BuildJobService.getBuildJobActions({
      buildJob,
      onFinish: () => setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_FINISH),
      onResend: () => setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_RESEND),
      onCancel: () => setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_CANCEL),
      onForceCancel: () =>
        setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_FORCE_CANCEL),
      onUnload: () => setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_UNLOAD),
      onForceUnload: () =>
        setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_FORCE_UNLOAD),
      onSetAsBuilt: () =>
        setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_SET_AS_BUILT),
      onDeferPostBuilding: () =>
        setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_DEFER_POST_BUILDING),
      onSetBuildJobOperator: () =>
        setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_SET_BUILD_JOB_OPERATOR),
    });

    setActions(actions);
  }, [buildJob]);

  if (actions.length === 0) {
    return '';
  }

  return (
    <>
      <Stack spacing={2} direction="row">
        {actions.map((action, index) =>
          action.disabled ? (
            <Tooltip
              title={action.tooltipText}
              showInMenu
              placement="left-start"
            >
              <span>
                <Button
                  key={index}
                  variant={action.variant}
                  color={action.color}
                  startIcon={action.icon}
                  disabled
                >
                  {action.label}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              key={index}
              onClick={() => action.onClick(buildJob)}
              variant={action.variant}
              color={action.color}
              startIcon={action.icon}
            >
              {action.label}
            </Button>
          ),
        )}
      </Stack>

      <BuildJobActions
        buildJob={buildJob}
        currentAction={currentAction}
        onSuccess={(updatedBuildJob) => setBuildJob(updatedBuildJob)}
        onCancel={() => setCurrentAction(null)}
      />
    </>
  );
}

export default BuildJobPageActionButtons;
