import axios from 'axios';
import { ROLES } from '../constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default class FusionPlanService {
  static getFusionPlansV2(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/fusion-plans`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getFusionPlans(planId, params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/plans/${planId}/fusion-plans`,
        { params }
      )
      .then(res => res.data.data);
  }

  static getFusionPlan(fusionPlanId, params, version = 'v1') {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}`,
        { params }
      )
      .then(res => res.data.data);
  }

  static createFusionPlan(data, version = 'v1') {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/${version}/fusion-plans`,
        data
      )
      .then(res => res.data.data);
  }

  static updateFusionPlan(fusionPlanId, data, version = 'v1') {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}`,
        data
      )
      .then(res => res.data.data);
  }

  static deleteFusionPlan(fusionPlanId, version = 'v1') {
    return axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/api/${version}/fusion-plans/${fusionPlanId}`
    );
  }

  static getVersions(fusionPlanId, params, config = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/fusion-plans/${fusionPlanId}/versions`,
        { ...config, params }
      )
      .then(res => res.data.data);
  }

  static publishVersion(fusionPlanId, comment) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusion-plans/${fusionPlanId}/publish`,
        {
          comment
        }
      )
      .then(res => res.data.data);
  }

  static fuse(fusionPlanId, data, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}/fuse`,
        data
      )
      .then(res => res.data.data);
  }

  static addToQueue(fusionPlanId, data, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}/add-to-queue`,
        data
      )
      .then(res => res.data.data);
  }

  static sendSaveConfigRequest(fusionPlanId, data, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}/send-save-config-request`,
        data
      )
      .then(res => res.data.data);
  }

  static duplicateFusionPlan(fusionPlanId, data) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusion-plans/${fusionPlanId}/duplicate`,
        data
      )
      .then(res => res.data.data);
  }

  static restoreVersion(fusionPlanId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusion-plans/${fusionPlanId}/restore`
      )
      .then(res => res.data.data);
  }

  static addToFavorite(fusionPlanId, data = {}, version = 'v2') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}/favorite`,
        data
      )
      .then(res => res.data.data);
  }

  static removeFromFavorite(fusionPlanId, data = {}, version = 'v2') {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/fusion-plans/${fusionPlanId}/favorite`,
        data
      )
      .then(res => res.data.data);
  }

  static getFusionPlanActions(
    fusionPlan,
    currentMemberId,
    userRoles,
    {
      onRename,
      onDelete,
      onToggleFavorite,
      onRemoveFromProject,
      onRemoveFromManufacturingOrder,
      onAddToComparison,
      isInComparison
    }
  ) {
    const isUserOwner = fusionPlan.memberId === currentMemberId;
    const isUserAdmin = !!userRoles.find(r => r.id === ROLES.ADMIN);

    const ToggleFavorite = async () => {
      const updatedFusionPlan = await (fusionPlan.isFavorite
        ? FusionPlanService.removeFromFavorite(fusionPlan.id)
        : FusionPlanService.addToFavorite(fusionPlan.id));

      onToggleFavorite({
        ...fusionPlan,
        isFavorite: updatedFusionPlan.isFavorite
      });
    };

    const actions = [
      {
        label: 'Rename',
        icon: <EditIcon />,
        onClick: onRename,
        disabled: fusionPlan.memberId !== currentMemberId // Enabled only if current user is the owner
      },
      {
        label: 'Delete',
        icon: <DeleteIcon />,
        onClick: onDelete,
        disabled: fusionPlan.memberId !== currentMemberId // Enabled only if current user is the owner
      },
      {
        label: '',
        onClick: ToggleFavorite,
        icon: fusionPlan.isFavorite ? (
          <FavoriteIcon color="primary" />
        ) : (
          <FavoriteBorderIcon color="primary" />
        )
      }
    ];

    if (onAddToComparison) {
      actions.push({
        label: '',
        onClick: onAddToComparison,
        icon: (
          <DisplaySettingsIcon
            color={isInComparison() ? 'primary' : 'secondary'}
          />
        ),
        disabled: !fusionPlan.configuration
      });
    }

    if (fusionPlan.projectId && onRemoveFromProject) {
      actions.push({
        label: 'Remove from Project',
        icon: <RemoveCircleIcon />,
        onClick: onRemoveFromProject,
        disabled: !isUserOwner && !isUserAdmin
      });
    }

    if (onRemoveFromManufacturingOrder) {
      actions.push({
        label: 'from Manufacturing Order',
        icon: <RemoveCircleIcon />,
        onClick: onRemoveFromManufacturingOrder,
        disabled: !isUserOwner && !isUserAdmin
      });
    }

    return actions;
  }

  static getFusionPlanVersionActions(
    fusionPlan,
    { onSetIsDefault, onAddToComparison, isInComparison }
  ) {
    const actions = [
      {
        label: 'Set as Default',
        onClick: onSetIsDefault,
        icon: <CheckCircleIcon />,
        disabled: fusionPlan.isDefault
      }
    ];

    if (onAddToComparison) {
      actions.push({
        label: '',
        onClick: onAddToComparison,
        icon: (
          <DisplaySettingsIcon
            color={isInComparison() ? 'primary' : 'secondary'}
          />
        ),
        disabled: !fusionPlan.configuration
      });
    }

    return actions;
  }

  static async planKeyExists(buildPlanId, planKey) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/fusion-plans/plan-key-exists`,
        {
          params: {
            planKey,
            buildPlanId
          }
        }
      )
      .then(res => res.data.planKeyExists);
  }
}
