import React from 'react';

import useFusionModuleStatus from './use-fusion-module-status';
import ComponentStatus from '../Component/ComponentStatus';

export default function FusionModuleStatus({
  fusionModuleId,
  initialStatus,
  initialProgress,
  initialDeviceVersion
}) {
  const { status, progress, deviceVersion } = useFusionModuleStatus(
    fusionModuleId,
    initialStatus,
    initialProgress,
    initialDeviceVersion
  );

  return (
    <ComponentStatus
      component={{
        status,
        progress,
        deviceVersion
      }}
    />
  );
}
