import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import TitanPage from '../Titan/TitanPage';
import SpoolsTable from '../Spools/SpoolsTable';
import MouldsTable from '../Moulds/MouldsTable';
import MaterialsTable from '../Materials/MaterialsTable';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TitanTabPanel from '../Titan/TitanTabPanel';
import { ROUTES } from '../../constants';
import PrintHeadsTable from '../PrintHeads/PrintHeadsTable';
import { useAuth0 } from '../Authentication/Auth0';
import { PERMISSIONS } from '../../constants/auth0';
import { useTitan } from '../Titan/Titan';
import PresetsTab from '../Presets/PresetsPage';

export default function GearsPage() {
  const { tabName } = useParams();

  const history = useHistory();
  const { addPageToPageHistory } = useTitan();

  const { permissions, checkPermissions } = useAuth0();

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Gear',
        disabled: true
      }
    ],
    []
  );

  const [activeTable, setActiveTable] = React.useState(
    tabName ? tabName : 'materials'
  );

  React.useEffect(() => {
    setActiveTable(tabName);
  }, [tabName]);

  const pushHistory = React.useCallback((tabName, settedPage) => {
    history.push(ROUTES.GEAR_TAB_PAGE(tabName, settedPage));
  }, []);

  React.useEffect(() => {
    if (tabName) {
      const activeLabel = tabName.charAt(0).toUpperCase() + tabName.slice(1);
      addPageToPageHistory({
        id: `GEAR`,
        url:
          tabName === 'materials'
            ? ROUTES.GEAR_TAB(tabName)
            : ROUTES.GEAR_TAB_PAGE(tabName, 0),
        label: `Gear | ${activeLabel}`
      });
    }
  }, []);

  const preparePageObject = (tabForURL, tab) => {
    return {
      id: `GEAR`,
      url:
        tabForURL === 'materials'
          ? ROUTES.GEAR_TAB(tabForURL)
          : ROUTES.GEAR_TAB_PAGE(tabForURL, 0),
      label: `Gear | ${tab}`
    };
  };

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      const newRoute = ROUTES.GEAR_TAB_PAGE(tabName, settedPage);
      addPageToPageHistory({
        id: `GEAR`,
        url: newRoute,
        label:
          settedPage === 0
            ? `Gear | ${labelName}`
            : `Gear | ${labelName} | Page : ${settedPage + 1}`
      });
    },
    []
  );

  const tabs = React.useMemo(() => {
    const tabs = [
      {
        label: 'Materials',
        value: 'materials',
        content: <MaterialsTable />
      },

      {
        label: 'Spools',
        value: 'spools',
        content: (
          <SpoolsTable
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        )
      },

      {
        label: 'Moulds',
        value: 'moulds',
        content: (
          <MouldsTable
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        )
      },
      {
        label: 'Presets',
        value: 'presets',
        content: (
          <PresetsTab
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        )
      }
    ];

    const canManagePrintHeads = checkPermissions(permissions, [
      PERMISSIONS.MANAGE_PRINT_HEADS
    ]);

    if (canManagePrintHeads)
      tabs.push({
        label: 'Print Heads',
        value: 'printHeads',
        content: (
          <PrintHeadsTable
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        )
      });

    return tabs;
  }, []);

  return (
    <TitanPage title="Gear" breadcrumbs={breadcrumbs}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            value={activeTable}
            onChange={(event, newTab) => {
              const activeLabel = tabs.find(tab => tab.value === newTab)?.label;
              setActiveTable(newTab);
              addPageToPageHistory(preparePageObject(newTab, activeLabel));
            }}
            sx={{ '& .MuiTabs-indicator': { left: 0, right: 'auto' } }}
          >
            {tabs.map(tab => (
              <Tab
                sx={{ alignItems: 'start' }}
                key={tab.value}
                label={tab.label}
                value={tab.value}
                component={Link}
                to={ROUTES.GEAR_TAB(tab.value)}
              />
            ))}
          </Tabs>
        </Grid>

        <Grid item xs={10}>
          {tabs.map(tab => (
            <TitanTabPanel
              value={tab.value}
              index={activeTable}
              key={tab.value}
            >
              {tab.content}
            </TitanTabPanel>
          ))}
        </Grid>
      </Grid>
    </TitanPage>
  );
}
