import React from 'react';
import axios from 'axios';
import DoneIcon from '@mui/icons-material/Done';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import UnloadIcon from '@mui/icons-material/FileUpload';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { COMPONENT_STATUSES } from '../constants';

const unloadStatuses = [
  COMPONENT_STATUSES.LOAD_TO_BM,
  COMPONENT_STATUSES.LOADED_TO_BM,
  COMPONENT_STATUSES.LOAD_TO_BM_FAILED,
  COMPONENT_STATUSES.PRE_BUILDING,
];

const cancelStatuses = [
  COMPONENT_STATUSES.SCHEDULED,
  COMPONENT_STATUSES.LOAD_TO_BM,
  COMPONENT_STATUSES.LOADED_TO_BM,
  COMPONENT_STATUSES.LOAD_TO_BM_FAILED,
  COMPONENT_STATUSES.UNLOAD_FROM_BM,
  COMPONENT_STATUSES.PRE_BUILDING,
];

const resendStatuses = [
  COMPONENT_STATUSES.LOAD_TO_BM,
  COMPONENT_STATUSES.LOAD_TO_BM_FAILED,
  COMPONENT_STATUSES.SCHEDULED,
];

const finishBuildingStatuses = [
  COMPONENT_STATUSES.POST_BUILDING,
  COMPONENT_STATUSES.POST_CANCELED,
  COMPONENT_STATUSES.POST_FAILED,
];

const setAsBuiltStatuses = [
  COMPONENT_STATUSES.LOAD_TO_BM,
  COMPONENT_STATUSES.LOADED_TO_BM,
  COMPONENT_STATUSES.PRE_BUILDING,
  COMPONENT_STATUSES.BUILDING,
  COMPONENT_STATUSES.POST_BUILDING,
  COMPONENT_STATUSES.BUILD_PAUSED,
  COMPONENT_STATUSES.BUILD_CANCELING,
];

export default class BuildJobService {
  static async updateBuildJob(buildJobId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/build-jobs/${buildJobId}`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async unloadBuildJob(buildJob, force = false) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v2/build-jobs/${buildJob.id}/unload`,
        { force },
      )
      .then((res) => res.data.data);
  }

  static async cancelBuildJob(buildJob, force = false) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/build-jobs/${buildJob.id}/cancel`,
        { force },
      )
      .then((res) => res.data.data);
  }

  static async finishPostCancel(buildJob) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v2/build-jobs/${buildJob.id}/finish-post-cancel`,
      )
      .then((res) => res.data);
  }

  static async finishPostFailed(buildJobId) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v2/build-jobs/${buildJobId}/finish-post-failed`,
      )
      .then((res) => res.data);
  }

  static async resendBuildJob(buildJob) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/build-jobs/${buildJob.id}/resend`,
      )
      .then((res) => res.data.data);
  }

  static async markBuildJobBuilt(buildJobId) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/build-jobs/${buildJobId}/built`,
      )
      .then((res) => res.data);
  }

  static async deferPostBuilding(buildJobId) {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v2/build-jobs/${buildJobId}/defer-post-building`,
      )
      .then((res) => res.data);
  }

  static async getBuildJobLogsData(buildJob) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/build-jobs/${buildJob.id}/logs-data`,
      )
      .then((res) => res.data.data);
  }

  static async getBuildJobStates(buildJobId, params = {}, version = 'v2') {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/${version}/build-jobs/${buildJobId}/states`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }

  static updateBuildJobState(buildJobId, timestamp, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/build-jobs/${buildJobId}/states/${timestamp}`,
        data,
      )
      .then((res) => res.data);
  }

  static async getBuildJob(buildJobId, params = {}, version = 'v1') {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/${version}/build-jobs/${buildJobId}`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }

  static getBuildJobs(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/build-jobs`, {
        ...config,
        params,
      })
      .then((res) => res.data);
  }

  static async addPhoto(buildJobId, data, version = 'v2') {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/${version}/build-jobs/${buildJobId}/photos`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async updatePhoto(photoId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/build-jobs/photos/${photoId}`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async deletePhoto(photoId, version = 'v2') {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/${version}/build-jobs/photos/${photoId}`,
      )
      .then((res) => res.data.data);
  }

  static getBuildJobActions({
    buildJob,
    onFinish,
    onResend,
    onCancel,
    onForceCancel,
    onUnload,
    onForceUnload,
    onSetAsBuilt,
    onDeferPostBuilding,
    onSetBuildJobOperator,
    onAttachToManufacturingOrder,
    onDetachFromManufacturingOrder,
  }) {
    const actions = [];

    if (buildJob.buildPlan && buildJob.buildPlan.deletedAt) {
      return actions;
    }

    if (!buildJob.createdBy && onSetBuildJobOperator) {
      actions.push({
        variant: 'outlined',
        color: 'primary',
        label: 'I am the operator of this job',
        icon: <EngineeringIcon />,
        onClick: onSetBuildJobOperator,
      });
    }

    if (finishBuildingStatuses.includes(buildJob.status)) {
      const label = {
        [COMPONENT_STATUSES.POST_BUILDING]: 'Finish Building',
        [COMPONENT_STATUSES.POST_CANCELED]: 'Finish cancellation',
        [COMPONENT_STATUSES.POST_FAILED]: 'Finish failed',
      }[buildJob.status];

      actions.push({
        variant: 'contained',
        color: 'primary',
        label,
        icon:
          buildJob.status === COMPONENT_STATUSES.POST_BUILDING ? (
            <DoneIcon />
          ) : (
            <CancelIcon />
          ),
        onClick: onFinish,
      });
    }

    if (resendStatuses.includes(buildJob.status)) {
      const disabled =
        ![COMPONENT_STATUSES.LOAD_TO_BM, COMPONENT_STATUSES.SCHEDULED].includes(
          buildJob.status,
        ) ||
        ![COMPONENT_STATUSES.IDLE, COMPONENT_STATUSES.OPERATIONAL].includes(
          buildJob?.printer?.state?.state,
        );

      actions.push({
        variant: 'outlined',
        color: 'primary',
        label:
          buildJob.status === COMPONENT_STATUSES.SCHEDULED ? 'Build' : 'Resend',
        icon:
          buildJob.status === COMPONENT_STATUSES.SCHEDULED ? (
            <PrintIcon />
          ) : (
            <RefreshIcon />
          ),
        onClick: onResend,
        disabled,
        tooltipText: 'Make sure that Build Module is in Idle state to proceed',
      });
    }

    if (
      setAsBuiltStatuses.includes(buildJob.status) &&
      Date.now() - new Date(buildJob.createdAt).getTime() > 2 * 60 * 1000 &&
      (!buildJob.printer ||
        !buildJob.printer.lastPing ||
        !buildJob.printer.lastPing.buildJobId ||
        buildJob.printer.lastPing.buildJobId !== buildJob.id ||
        Date.now() - buildJob.printer.lastPing.timestamp > 2 * 60 * 1000)
    ) {
      actions.push({
        variant: 'outlined',
        color: 'primary',
        label: 'Set as built',
        icon: <DoneIcon />,
        onClick: onSetAsBuilt,
      });
    }

    if (
      buildJob.status === COMPONENT_STATUSES.POST_BUILDING &&
      onDeferPostBuilding
    ) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Defer post-building',
        icon: <MoreTimeIcon />,
        onClick: onDeferPostBuilding,
      });
    }

    if (cancelStatuses.includes(buildJob.status)) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Cancel',
        icon: <CancelIcon />,
        onClick: onCancel,
      });
    }

    if (buildJob.status === COMPONENT_STATUSES.BUILD_CANCELING) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Force Cancel',
        icon: <CancelIcon />,
        onClick: onForceCancel,
      });
    }

    if (unloadStatuses.includes(buildJob.status)) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Unload',
        icon: <UnloadIcon />,
        onClick: onUnload,
      });
    }

    if (buildJob.status === COMPONENT_STATUSES.UNLOAD_FROM_BM) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'Force Unload',
        icon: <UnloadIcon />,
        onClick: onForceUnload,
      });
    }

    /*
    if (onAttachToManufacturingOrder) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: !buildJob.manufacturingOrderId
          ? 'to Manufacturing Order'
          : 'Change Manufacturing Order',
        icon: !buildJob.manufacturingOrderId ? (
          <AddCircleIcon />
        ) : (
          <ChangeCircle />
        ),
        onClick: onAttachToManufacturingOrder
      });
    }

    if (onDetachFromManufacturingOrder && buildJob.manufacturingOrderId) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'from Manufacturing Order',
        icon: <RemoveCircleIcon />,
        onClick: onDetachFromManufacturingOrder
      });
    }
    */

    return actions;
  }
}
