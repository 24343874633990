import React from 'react';

import { useFuseDialog } from './FuseDialog';

import { COMPONENT_STATUSES, SORT_ORDERS } from '../../constants';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import ComponentService from '../../services/ComponentService';
import PreformLink from '../Component/PreformLink';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import ComponentStatus from '../Component/ComponentStatus';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { Stack } from '@mui/material';

export default function FuseDialogPreformsStep() {
  const {
    setOpenScanDialog,

    selectedPreforms,
    setSelectedPreforms
  } = useFuseDialog();

  const loadPreforms = React.useCallback(async (query, config) => {
    const params = {
      ...query,
      withRelated: ['buildPlan', 'preformType']
    };

    if (!params.search) {
      params.statuses = [COMPONENT_STATUSES.BUILT];
    }

    const { data, pagination } = await ComponentService.getPreforms(
      params,
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const preformColumns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <PreformLink preform={row} />
      },
      {
        headerName: 'Build Plan',
        field: 'buildPlan',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.buildPlan ? (
            <BuildPlanLink
              buildPlan={row.buildPlan}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          )
      },
      {
        headerName: 'Preform Type',
        field: 'preformType',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.preformType
            ? `${row.preformType.name} (${row.preformType.labels})`
            : ''
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <ComponentStatus component={row} />
      },
      {
        headerName: 'Quality',
        field: 'quality',
        visibilityBreakpoint: 'lg',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          [COMPONENT_STATUSES.BUILT, COMPONENT_STATUSES.POST_BUILDING].includes(
            row.status
          ) && row.quality !== null ? (
            <Rating name="read-only" value={row.quality} readOnly />
          ) : (
            ''
          )
      }
    ],
    []
  );

  const preformsDataGridProps = useTitanDataGrid(loadPreforms, {
    orders: {
      created_at: SORT_ORDERS.DESC
    },
    columns: preformColumns,
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'id'],
      right: []
    }
  });

  const onSelectPreforms = React.useCallback(
    newSelectedPreforms => {
      setSelectedPreforms(newSelectedPreforms);
    },
    [setSelectedPreforms]
  );

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenScanDialog(true)}
          title="Scan"
        >
          Scan
        </Button>
      </Stack>
      <TitanDataGrid
        components={{
          Toolbar: TitanDataGridToolbar
        }}
        {...preformsDataGridProps}
        searchPlaceholder="Search by preform id, preform type or Build Plan name"
        isRowSelectable={({ row }) => !row.assemblyId && row.status === 'BUILT'}
        checkboxMultiSelection
        selectedRows={selectedPreforms}
        onSelectionModelChange={onSelectPreforms}
      />
    </>
  );
}
