import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';

import BUILD_MODULE_HEATER_LABELS from '../../constants/buildModuleHeaterLabels';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import BuildModuleService from '../../services/BuildModuleService';

const defaultTemperatures = [
  {
    key: 'T0',
    label: BUILD_MODULE_HEATER_LABELS.T0,
    temperature: ''
  },
  {
    key: 'T1',
    label: BUILD_MODULE_HEATER_LABELS.T1,
    temperature: ''
  },
  {
    key: 'B',
    label: BUILD_MODULE_HEATER_LABELS.B,
    temperature: ''
  },
  {
    key: 'C',
    label: BUILD_MODULE_HEATER_LABELS.C,
    temperature: ''
  },
  {
    key: 'M',
    label: BUILD_MODULE_HEATER_LABELS.M,
    temperature: ''
  },
  {
    key: 'IR',
    label: BUILD_MODULE_HEATER_LABELS.IR,
    temperature: ''
  }
];

export default function BuildModuleTemperatureDialog({
  buildModuleId,
  temperature,
  onClose,
  onSave,
  minTemperature = 0,
  maxTemperature = 400
}) {
  const [name, setName] = React.useState(temperature ? temperature.name : '');
  const [nameExists, setNameExists] = React.useState(false);

  const [temperatures, setTemperatures] = React.useState(
    temperature && temperature.temperatures
      ? defaultTemperatures.map(dt => {
          const t = temperature.temperatures.find(t => t.key === dt.key);

          if (t) {
            return {
              ...dt,
              ...t
            };
          }

          return dt;
        })
      : defaultTemperatures
  );

  const checkNameExists = React.useCallback(
    async e => {
      const { value } = e.target;

      setNameExists(false);

      if (temperature && temperature.name === value) {
        return;
      }

      const {
        exists
      } = await BuildModuleService.checkBuildModulePreHeatingTemperatureNameExists(
        buildModuleId,
        value
      );

      setNameExists(exists);
    },
    [buildModuleId, temperature]
  );

  const onSubmitForm = React.useCallback(async () => {
    if (!temperature || temperature.name !== name) {
      const {
        exists
      } = await BuildModuleService.checkBuildModulePreHeatingTemperatureNameExists(
        buildModuleId,
        name
      );

      if (exists) {
        setNameExists(true);

        return Promise.reject({
          message: 'Name already exists for this Build Module'
        });
      }
    }

    return onSave({
      name,
      temperatures: temperatures
        .filter(({ temperature }) => !!temperature)
        .map(({ key, temperature }) => ({
          key,
          temperature
        }))
    });
  }, [name, buildModuleId, temperatures]);

  const onChangeTemperature = React.useCallback((key, temperature) => {
    setTemperatures(prev =>
      prev.map(t => {
        if (t.key === key) {
          return {
            ...t,
            temperature: temperature,
            isValid: temperature
              ? temperature > minTemperature && temperature <= maxTemperature
              : true
          };
        }

        return t;
      })
    );
  }, []);

  const hasSetTemperatures = temperatures.some(
    t => t.temperature > minTemperature && t.temperature <= maxTemperature
  );

  return (
    <TitanDialog
      title={`${temperature ? 'Edit' : 'Create'} Pre-Heating Temperatures`}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      onSave={onSubmitForm}
      isValid={
        name.length &&
        hasSetTemperatures &&
        temperatures.every(t => t.isValid !== false)
      }
    >
      <TextField
        autoFocus
        id="name"
        label="Name"
        type="text"
        value={name}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={e => setName(e.target.value)}
        onBlur={checkNameExists}
        error={nameExists}
        helperText={
          nameExists ? 'Name already exists for this Build Module' : ''
        }
      />

      {temperatures.map(temperature => (
        <TextField
          id={`temperature-${temperature.key}`}
          label={`${temperature.label} (${temperature.key})`}
          type="number"
          value={temperature.temperature}
          fullWidth
          InputProps={{
            inputProps: { min: minTemperature, max: maxTemperature },
            endAdornment: <InputAdornment position="end">°C</InputAdornment>
          }}
          margin="normal"
          variant="outlined"
          onChange={e =>
            onChangeTemperature(
              temperature.key,
              e.target.value.length ? parseInt(e.target.value, 10) : ''
            )
          }
          key={temperature.key}
          error={temperature.isValid === false}
          helperText={
            !temperature.isValid
              ? 'Temperature should be in range between 0 and 400°C'
              : ''
          }
        />
      ))}

      {!hasSetTemperatures ? (
        <Alert severity="warning">Please set one or more temperatures</Alert>
      ) : (
        ''
      )}
    </TitanDialog>
  );
}
