import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import FilterChips from '../FilterChips/FilterChips';

const statusLabels = {
  ALL: 'All',
  IN_PROGRESS: 'In Progress',
  FINISHED: 'Finished',
  CANCELED: 'Canceled'
};

export default function HistoryFilter({
  itemsType,
  onChangeItemsType,
  quality,
  onChangeQuality,
  status,
  onChangeStatus
}) {
  const items = React.useMemo(() => {
    const items = [];

    items.push({
      separatorLabel: 'Item Types: '
    });

    items.push({
      label: 'All',
      onClick: () => onChangeItemsType('all'),
      isHighlighted: itemsType === 'all'
    });

    items.push({
      label: 'Preforms',
      onClick: () => onChangeItemsType('preform'),
      isHighlighted: itemsType === 'preform'
    });

    items.push({
      label: 'Final Parts',
      onClick: () => onChangeItemsType('part'),
      isHighlighted: itemsType === 'part'
    });

    items.push({
      separatorLabel: 'Status: '
    });

    items.push({
      label: 'All',
      onClick: () => onChangeStatus('ALL'),
      isHighlighted: status === 'ALL'
    });

    items.push({
      label: 'In Progress',
      onClick: () => onChangeStatus('IN_PROGRESS'),
      isHighlighted: status === 'IN_PROGRESS'
    });

    items.push({
      label: 'Finished',
      onClick: () => onChangeStatus('FINISHED'),
      isHighlighted: status === 'FINISHED'
    });

    items.push({
      label: 'Cancelled',
      onClick: () => onChangeStatus('CANCELED'),
      isHighlighted: status === 'CANCELED'
    });

    if (quality > 0) {
      items.push({
        icon: <StarIcon />,
        label: `Quality: ${quality} start${quality > 1 ? 's' : ''}`,
        onDelete: () => onChangeQuality(null),
        isHighlighted: true
      });
    }

    return items;
  }, [itemsType, quality, status]);

  return <FilterChips items={items} />;
}
