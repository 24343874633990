import React from 'react';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import TitanDialog from '../Titan/TitanDialog';
import PreformCustomFields from '../Preform/PreformCustomFields';
import FinalPartService from '../../services/FinalPartService';

import { COMPONENT_STATUSES } from '../../constants';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import TabContext from '@mui/lab/TabContext';
import FusionJobService from '../../services/FusionJobService';
import AssemblyService from '../../services/AssemblyService';

export default function FusionJobPostFusingDialog({ fusionJob, onClose }) {
  const [finalParts, setFinalParts] = React.useState([]);
  const [fusionPlanCustomFields, setFusionPlanCustomFields] = React.useState(
    []
  );
  const [fusionJobComment, setFusionJobComment] = React.useState(
    fusionJob.comment || ''
  );

  const [currentTab, setCurrentTab] = React.useState('fusion-job');
  const [isFinalStep, setIsFinalStep] = React.useState(false);

  React.useEffect(() => {
    if (!fusionJob || fusionJob.assemblies.length === 0) {
      setFinalParts([]);
    }

    const fusionPlanCustomFields = fusionJob?.fusionPlan?.customFields
      ? fusionJob.fusionPlan.customFields
          .filter(cf => cf.formType === 'POST_FUSION')
          .sort((a, b) => a.order - b.order)
      : [];
    setFusionPlanCustomFields(fusionPlanCustomFields);

    setFinalParts(
      fusionJob.assemblies.map(finalPart => ({
        ...finalPart,
        customFields: FinalPartService.prepareFinalPartCustomFields(
          finalPart.id,
          fusionPlanCustomFields,
          finalPart.customFields
        )
      }))
    );
  }, [fusionJob]);

  React.useEffect(() => {
    if (
      finalParts.length !== 0 &&
      currentTab === `final-part-${finalParts[finalParts.length - 1].id}`
    ) {
      setIsFinalStep(true);
    } else if (currentTab === 'fusion-job' && finalParts.length === 0) {
      setIsFinalStep(true);
    } else if (isFinalStep) {
      setIsFinalStep(false);
    }
  }, [currentTab, finalParts]);

  const onChangeFinalPart = (index, key, value) => {
    setFinalParts(prevState => {
      const updatedFinalParts = [...prevState];

      updatedFinalParts[index] = {
        ...updatedFinalParts[index],
        [key]:
          key === 'customFields'
            ? FinalPartService.prepareFinalPartCustomFields(
                updatedFinalParts[index].id,
                fusionPlanCustomFields,
                value
              )
            : value
      };

      return updatedFinalParts;
    });
  };

  const onSaveFusionJob = async () => {
    await Promise.all(
      finalParts.map(async finalPart => {
        await AssemblyService.updateAssembly(finalPart.id, {
          comment: finalPart.comment || '',
          customFields: Object.values(finalPart.customFields)
        });
      })
    );

    await FusionJobService.update(fusionJob, {
      comment: fusionJobComment
    });

    if (fusionJob.status === COMPONENT_STATUSES.POST_FUSING) {
      await FusionJobService.markFusionJobFused(fusionJob);
    }

    if (fusionJob.status === COMPONENT_STATUSES.POST_CANCELED) {
      await FusionJobService.cancelFusionJob(fusionJob, true);
    }
  };

  if (!fusionJob) {
    return <TitanCircularProgress />;
  }

  return (
    <TitanDialog
      onClose={onClose}
      fullScreen
      showTitle={false}
      onSave={onSaveFusionJob}
      saveButtonLabel={
        fusionJob.status === COMPONENT_STATUSES.POST_FUSING
          ? 'Finish Fusing'
          : 'Finish Cancellation'
      }
      onPrev={() => {
        if (currentTab.startsWith('final-part-')) {
          const finalPartIndex = finalParts.findIndex(
            finalPart => finalPart.id === currentTab.replace('final-part-', '')
          );

          if (finalPartIndex !== -1) {
            if (finalPartIndex === 0) {
              setCurrentTab('fusion-job');
            } else if (finalPartIndex < finalParts.length) {
              setCurrentTab(`final-part-${finalParts[finalPartIndex - 1].id}`);
            }
          }
        }
      }}
      onNext={() => {
        if (currentTab === 'fusion-job' && finalParts.length !== 0) {
          setCurrentTab(`final-part-${finalParts[0].id}`);
        } else if (currentTab.startsWith('final-part-')) {
          const finalPartIndex = finalParts.findIndex(
            p => p.id === currentTab.replace('final-part-', '')
          );

          if (finalPartIndex !== -1) {
            if (finalPartIndex < finalParts.length - 1) {
              setCurrentTab(`final-part-${finalParts[finalPartIndex + 1].id}`);
            }
          }
        }
      }}
      isNextValid={!isFinalStep}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: '100%'
          }}
        >
          <Box sx={{ width: '255px', height: '100%' }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={(e, newTab) => {
                setCurrentTab(newTab);
              }}
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab
                label={`Fusion Job${
                  fusionJob.jobKey ? ' ' + fusionJob.jobKey : ''
                }`}
                value="fusion-job"
                sx={{ alignItems: 'start' }}
              />
              {finalParts.map(finalPart => (
                <Tab
                  label={`Final part ${finalPart.finalPartKey || finalPart.id}`}
                  value={`final-part-${finalPart.id}`}
                  key={`final-part-tab-${finalPart.id}`}
                  sx={{ alignItems: 'start' }}
                />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ width: 'calc(100% - 210px)', height: '100%' }}>
            <TabPanel value="fusion-job">
              <Box>
                <Typography variant="h6">{`Fusion Job ${
                  fusionJob.jobKey
                }`}</Typography>
              </Box>
              <Box>
                <TextField
                  autoFocus
                  placeholder="Fusion job comment"
                  type="text"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={fusionJobComment}
                  onChange={e => setFusionJobComment(e.target.value)}
                />
              </Box>
            </TabPanel>
            {finalParts.map((finalPart, index) => (
              <TabPanel value={`final-part-${finalPart.id}`} key={finalPart.id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h6">
                      {finalPart.finalPartKey || finalPart.id}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <TextField
                    autoFocus
                    label="Final part comment"
                    type="text"
                    fullWidth
                    value={finalPart.comment}
                    margin="normal"
                    variant="outlined"
                    onChange={e =>
                      onChangeFinalPart(index, 'comment', e.target.value)
                    }
                  />
                </Box>

                <PreformCustomFields
                  customFields={fusionPlanCustomFields}
                  entityCustomFields={finalPart.customFields}
                  onChange={finalPartCustomFields => {
                    onChangeFinalPart(
                      index,
                      'customFields',
                      finalPartCustomFields
                    );
                  }}
                />
              </TabPanel>
            ))}
          </Box>
        </Box>
      </TabContext>
    </TitanDialog>
  );
}
