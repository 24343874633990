import React from 'react';
import {
  BUILD_JOB_ACTION_TYPES,
  COMPONENT_STATUSES,
  FUSION_JOB_ACTION_TYPES,
} from '../../constants';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import ResendFusionJobDialog from '../Dialog/ResendFusionJobDialog';
import ManufacturingOrderSelectionDialog from '../Fibrify/FibrifyManufacturingOrderSelectionDialog';

export default function FusionJobConfirmationDialogs({
  fusionJob,
  currentAction,
  onConfirm,
  onCancel,
}) {
  return (
    <>
      {currentAction === FUSION_JOB_ACTION_TYPES.UNLOAD && (
        <TitanConfirmationDialog
          title="Unload Fusion Job"
          message="Are you sure you want to unload this Fusion Job from Fusion Module?"
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.FORCE_UNLOAD && (
        <TitanConfirmationDialog
          title="Unload Fusion Job"
          message="Are you sure you want to force unload this Fusion Job from Fusion Module?"
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.CANCEL && (
        <TitanConfirmationDialog
          title="Cancel Fusion Job"
          message="Are you sure you want to cancel this Fusion Job?"
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.FORCE_CANCEL && (
        <TitanConfirmationDialog
          title="Force cancel Fusion Job"
          message="Are you sure you want to force cancel this Fusion Job?"
          onConfirm={() => onConfirm(fusionJob, true)}
          onClose={onCancel}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.RESEND && (
        <TitanConfirmationDialog
          title={`${
            fusionJob.status.includes(COMPONENT_STATUSES.SCHEDULED)
              ? 'Send'
              : 'Resend'
          } Fusion Job to Fusion Module`}
          message={`Are you sure you want to ${
            fusionJob.status.includes(COMPONENT_STATUSES.SCHEDULED)
              ? 'send'
              : 'resend'
          } Fusion Job to Fusion Module?`}
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
          fullWidth={true}
        >
          <ResendFusionJobDialog jobToResend={fusionJob} />
        </TitanConfirmationDialog>
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.SET_AS_FUSED && (
        <TitanConfirmationDialog
          title="Mark Fusion Job as fused"
          message={`Are you sure you want to mark Fusion Job as successfully fused?`}
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.SET_FUSION_JOB_OPERATOR && (
        <TitanConfirmationDialog
          title="Set fusion job operator"
          message="Are you sure you want to set your account as owner of this job?"
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.ATTACH_TO_MO && (
        <ManufacturingOrderSelectionDialog
          title="Select Manufacturing Order to which you want to connect Fusion Job"
          filters={{
            fusionPlanIds: [fusionJob.fusionPlan.id],
          }}
          onSave={(manufacturingOrder) => onConfirm(manufacturingOrder)}
          onClose={onCancel}
          {...(fusionJob.manufacturingOrder && {
            currentSelection: [fusionJob.manufacturingOrder],
          })}
        />
      )}

      {currentAction === FUSION_JOB_ACTION_TYPES.DETACH_FROM_MO && (
        <TitanConfirmationDialog
          title="Remove Fusion Job from Manufacturing Order"
          message="Are you sure you want to remove this Fusion Job from Manufacturing Order?"
          onConfirm={() => onConfirm(fusionJob)}
          onClose={onCancel}
        />
      )}
    </>
  );
}
