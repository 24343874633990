import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CustomFields from '../CustomFields/CustomFields';

export default function PreformTypeDialog({
  preformType,
  onClose,
  onSave,
  hasPreforms,
}) {
  const [name, setName] = React.useState(preformType ? preformType.name : '');
  const [labels, setLabels] = React.useState(
    preformType && preformType.labels && preformType.labels.length
      ? preformType.labels.split(',')
      : [],
  );
  const [openDialog, setOpenDialog] = React.useState(false);

  const [customFields, setCustomFields] = React.useState(
    preformType && preformType.customFields
      ? [...preformType.customFields].sort((a, b) => a.order - b.order)
      : [],
  );

  const onSubmitForm = () => {
    return onSave({
      name,
      labels: labels.join(', '),
      customFields: customFields.map(
        ({
          id,
          name,
          type,
          units,
          options,
          expectedValue,
          image,
          isNew,
          newImage,
        }) => {
          const customField = {
            name,
            type,
            units,
            options,
            expectedValue,
            image,
          };

          if (image === null) {
            customField.newImage = null;
          }

          if (newImage !== undefined) {
            customField.newImage = newImage;
          }

          if (!isNew) {
            customField.id = id;
          }

          return customField;
        },
      ),
    });
  };

  const onAddCustomField = React.useCallback(
    async (newCustomField) => {
      await setCustomFields((prev) => [...prev, newCustomField]);
    },
    [customFields],
  );

  const onChangeCustomField = React.useCallback(
    async (updatedCustomField) => {
      setOpenDialog(true);
      try {
        setCustomFields((prev) =>
          prev.map((row) =>
            row.id === updatedCustomField.id ? updatedCustomField : row,
          ),
        );
      } catch (error) {
        setCustomFields((prev) => [...prev]);
      }
    },
    [customFields],
  );

  const onDeleteCustomField = React.useCallback((customField) => {
    setCustomFields((prev) => prev.filter((cf) => cf.id !== customField.id));
  }, []);

  const customTypes = React.useMemo(() => {
    return [
      {
        type: 'MACHINE_RELATED_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Machine related NC',
        options: [
          'OK Quality',
          'Error message',
          'Clogged fibers',
          'Clogged polymer',
          'Adhesion issue',
          'Cancelled',
          'Other',
        ],
      },
      {
        type: 'PART_RELATED_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Part related NC',
        options: [
          'OK Quality',
          'Fibers sticking out',
          'Fibers missing',
          'Polymer missing',
          'Delamination',
          'Layer shift',
          'Other',
        ],
      },
      {
        type: 'FIBER_MATERIAL_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Fiber material NC',
        options: [
          'OK Quality',
          'Winding',
          'Broken',
          'Empty',
          'Wrong material',
          'Other',
        ],
      },
      {
        type: 'PRIME_TOWEL_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Prime tower',
        options: ['OK Quality', 'Bad Quality'],
      },
    ];
  }, []);

  return (
    <TitanDialog
      title={`${
        preformType && preformType.id ? 'Edit' : 'Create'
      } Preform Type`}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      onSave={onSubmitForm}
      isValid={
        name && customFields.every((cf) => cf.name && cf.name.length !== 0)
      }
    >
      <TextField
        autoFocus
        id="name"
        label="name"
        type="text"
        value={name}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={(e) => setName(e.target.value)}
      />

      <Autocomplete
        multiple
        options={[]}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Labels"
            margin="normal"
            variant="outlined"
            helperText="Type your label and press Enter"
          />
        )}
        value={labels}
        onChange={(event, value) => setLabels(value)}
        onBlur={(event) => {
          if (event.target.value.trim() !== '') {
            setLabels((prev) => [...prev, event.target.value]);
          }
        }}
        clearOnBlur
      />

      <Box sx={{ mt: 2 }}>
        <CustomFields
          customFields={customFields}
          onAdd={onAddCustomField}
          onChange={onChangeCustomField}
          onDelete={onDeleteCustomField}
          disableEdit={hasPreforms}
          disableEditTooltip={
            hasPreforms
              ? "You can't add a new custom field because the Build Plan has preforms"
              : ''
          }
          disableDelete={hasPreforms}
          disableDeleteTooltip={
            hasPreforms
              ? "You can't delete custom field because the Build Plan has preforms"
              : ''
          }
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          customTypes={customTypes}
        />
      </Box>
    </TitanDialog>
  );
}
