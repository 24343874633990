import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import FusionPlanDialog from '../FusionPlan/FusionPlanDialog';
import FusionPlanSelectionDialog from '../Fibrify/FibrifyFusionPlanSelectionDialog';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import FusionPlanService from '../../services/FusionPlanService';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import { ROUTES, SORT_ORDERS } from '../../constants';

import defaultFusionPlanCustomFields from '../../constants/defaultFusionPlanCustomFields';

export default function FusionPlansTable({
  projectId,
  manufacturingOrderId,
  manufacturingOrdersProjectId,
  addFusionPlanToProjectButton = false,
  addFusionPlanToManufacturingOrderButton = false
}) {
  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();

  const { currentMemberId, roles } = useAuth0();

  const { pushSnackbar } = useTitan();

  const [openFusionPlanDialog, setOpenFusionPlanDialog] = React.useState(false);
  const [openAddToProjectDialog, setOpenAddToProjectDialog] = React.useState(
    false
  );
  const [
    openAddToManufacturingOrderDialog,
    setOpenAddToManufacturingOrderDialog
  ] = React.useState(false);
  const [
    openRemoveFromProjectDialog,
    setOpenRemoveFromProjectDialog
  ] = React.useState(false);
  const [
    openRemoveFromManufacturingOrderDialog,
    setOpenRemoveFromManufacturingOrderDialog
  ] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const [editableFusionPlan, setEditableFusionPlan] = React.useState({});

  const loadData = React.useCallback(
    async (queryParams, config) => {
      const { data, pagination } = await FusionPlanService.getFusionPlansV2(
        {
          ...queryParams,
          ...(projectId && { projectIds: [projectId] }),
          ...(manufacturingOrderId && {
            manufacturingOrderIds: [manufacturingOrderId]
          })
        },
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [projectId, manufacturingOrderId]
  );

  const titanDataGridProps = useTitanDataGrid(loadData, {
    orders: {
      created_at: SORT_ORDERS.DESC
    },
    ...(pageParam && Number(pageParam) > 0 && { page: Number(pageParam) })
  });

  const { setRows, reloadData } = titanDataGridProps;

  const updateFusionPlan = React.useCallback(
    fusionPlan => {
      setRows(prev =>
        prev.map(fp => {
          if (fp.id === fusionPlan.id) {
            return {
              ...fp,
              ...fusionPlan
            };
          }

          return fp;
        })
      );
    },
    [setRows]
  );

  const onSaveFusionPlan = React.useCallback(
    async data => {
      let planKeyExists = false;

      if (data.planKey) {
        planKeyExists = await FusionPlanService.planKeyExists(
          editableFusionPlan.id,
          data.planKey
        );
      }

      if (planKeyExists) {
        throw new Error(
          'Fusion Plan with the same Plan Key already exists within the organization'
        );
      } else {
        if (editableFusionPlan.id) {
          const updatedFusionPlan = await FusionPlanService.updateFusionPlan(
            editableFusionPlan.id,
            data,
            'v2'
          );
          updateFusionPlan(updatedFusionPlan);
        } else {
          const newFusionPlan = await FusionPlanService.createFusionPlan(
            { ...data, customFields: defaultFusionPlanCustomFields },
            'v2'
          );
          setRows(prev => [newFusionPlan, ...prev]);
        }

        setOpenFusionPlanDialog(false);
        setEditableFusionPlan({});
      }
    },
    [editableFusionPlan, updateFusionPlan, setRows]
  );

  const onAddingFusionPlansToProject = React.useCallback(
    async fusionPlans => {
      await Promise.allSettled(
        fusionPlans.map(buildPlan =>
          FusionPlanService.updateFusionPlan(
            buildPlan.id,
            {
              projectId: projectId
            },
            'v2'
          )
        )
      );

      await reloadData();
    },
    [projectId, reloadData]
  );

  const onAddingFusionPlansToManufacturingOrder = React.useCallback(
    async fusionPlans => {
      await Promise.allSettled(
        fusionPlans.map(fusionPlan =>
          ManufacturingOrderService.attachFusionPlan(
            manufacturingOrderId,
            fusionPlan.id
          )
        )
      );

      await reloadData();
    },
    [manufacturingOrderId, reloadData]
  );

  const onRemoveFromProject = React.useCallback(async () => {
    await FusionPlanService.updateFusionPlan(
      editableFusionPlan.id,
      {
        projectId: null
      },
      'v2'
    );

    await reloadData();
  }, [editableFusionPlan, reloadData]);

  const onRemoveFromManufacturingOrder = React.useCallback(async () => {
    await ManufacturingOrderService.detachFusionPlan(
      manufacturingOrderId,
      editableFusionPlan.id
    );

    await reloadData();
  }, [manufacturingOrderId, editableFusionPlan, reloadData]);

  const onDeleteFusionPlan = React.useCallback(async () => {
    await FusionPlanService.deleteFusionPlan(editableFusionPlan.id, 'v2');

    pushSnackbar('Fusion Plan successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);

    setRows(prev => prev.filter(fp => fp.id !== editableFusionPlan.id));
    setEditableFusionPlan({});
  }, [editableFusionPlan, setRows, pushSnackbar]);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sortable: false,
        flex: 1,
        renderCell: ({ row, colDef }) => (
          <FusionPlanLink fusionPlan={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.FUSION_PLAN_V2(row.id),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 120,
        hideInMenu: true,
        sortable: false,
        getActions: params =>
          FusionPlanService.getFusionPlanActions(
            params.row,
            currentMemberId,
            roles,
            {
              onRename: () => {
                setEditableFusionPlan(params.row);
                setOpenFusionPlanDialog(true);
              },
              onDelete: () => {
                setEditableFusionPlan(params.row);
                setOpenDeleteDialog(true);
              },
              onToggleFavorite: fusionPlan => updateFusionPlan(fusionPlan),
              onRemoveFromProject: projectId
                ? () => {
                    setEditableFusionPlan(params.row);
                    setOpenRemoveFromProjectDialog(true);
                  }
                : null,
              onRemoveFromManufacturingOrder: manufacturingOrderId
                ? () => {
                    setEditableFusionPlan(params.row);
                    setOpenRemoveFromManufacturingOrderDialog(true);
                  }
                : null
            }
          ).map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu={!!action.label}
            />
          ))
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        searchPlaceholder="Search by Fusion Plan name..."
        columns={columns}
        {...addFusionPlanToProjectButton && {
          onCreateClick: () => setOpenAddToProjectDialog(true),
          createButtonLabel: 'Fusion Plan'
        }}
        {...addFusionPlanToManufacturingOrderButton && {
          onCreateClick: () => setOpenAddToManufacturingOrderDialog(true),
          createButtonLabel: 'Fusion Plan'
        }}
      />

      {openFusionPlanDialog && (
        <FusionPlanDialog
          fusionPlan={editableFusionPlan}
          onClose={() => {
            setOpenFusionPlanDialog(false);
            setEditableFusionPlan({});
          }}
          currentMemberId={currentMemberId}
          onSave={onSaveFusionPlan}
        />
      )}

      {openAddToProjectDialog && (
        <FusionPlanSelectionDialog
          title="Select Fusion Plans that you want to add to the Project"
          saveButtonLabel="Add"
          multipleSelection
          filters={{ withoutProject: true }}
          onSave={onAddingFusionPlansToProject}
          onClose={() => setOpenAddToProjectDialog(false)}
        />
      )}

      {openAddToManufacturingOrderDialog && (
        <FusionPlanSelectionDialog
          title="Select Fusion Plans that you want to add to this Manufacturing Order"
          saveButtonLabel="Add"
          multipleSelection
          filters={{
            ...(manufacturingOrdersProjectId && {
              projectIds: [manufacturingOrdersProjectId]
            }),
            ...(manufacturingOrderId && {
              excludeManufacturingOrderIds: [manufacturingOrderId]
            })
          }}
          onSave={onAddingFusionPlansToManufacturingOrder}
          onClose={() => setOpenAddToManufacturingOrderDialog(false)}
        />
      )}

      {openRemoveFromProjectDialog && (
        <TitanConfirmationDialog
          title="Remove this Fusion Plan from Project?"
          message={`Are you sure you want to remove this Fusion Plan from Project?`}
          onClose={() => {
            setOpenRemoveFromProjectDialog(false);
            setEditableFusionPlan({});
          }}
          onConfirm={() => onRemoveFromProject()}
        />
      )}

      {openRemoveFromManufacturingOrderDialog && (
        <TitanConfirmationDialog
          title="Remove this Fusion Plan from Manufacturing Order?"
          message="Are you sure you want to remove this Fusion Plan from Manufacturing Order?"
          onClose={() => {
            setOpenRemoveFromManufacturingOrderDialog(false);
          }}
          onConfirm={() => onRemoveFromManufacturingOrder()}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Fusion Plan?"
          message={`Are you sure you want to delete Fusion Plan ${
            editableFusionPlan.name
          }?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditableFusionPlan({});
          }}
          onConfirm={() => onDeleteFusionPlan()}
        />
      )}
    </>
  );
}
