import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/GetApp';
import { useBuildPlan } from './BuildPlanPage';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
const FileDownload = require('js-file-download');

export default function BuildPlanFilesTab() {
  const { buildPlan } = useBuildPlan();
  const [downloadingFile, setDownloadingFile] = React.useState();

  const onDownloadClick = (e, buildPlanFile) => {
    setDownloadingFile(buildPlanFile);
    e.preventDefault();

    const fileName = buildPlanFile.location.split('/')[1];

    axios
      .get(buildPlanFile.url, {
        sendAuth0Headers: buildPlanFile.location.endsWith('.gcode'),
        responseType: 'blob'
      })
      .then(res => {
        setDownloadingFile(null);
        FileDownload(res.data, fileName);
      })
      .catch(() => setDownloadingFile(null));
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.location && <span> {row.location.split('/')[1]}</span>
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) =>
          row.url ? (
            <IconButton onClick={e => onDownloadClick(e, row)}>
              {downloadingFile && row.id === downloadingFile.id ? (
                <CircularProgress size={20} />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
          ) : null
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        showSearch={false}
        rows={buildPlan.files}
        columns={columns}
        pagination={false}
        rowCount={buildPlan.files.length}
      />
    </>
  );
}
