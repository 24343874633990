import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTES, WEB_SOCKET_ACTIONS } from '../../constants';
import AssemblyService from '../../services/AssemblyService';
import FinalPartSummary from './FinalPartSummary';
import PreformsTable from '../Preforms/PreformsTable';
import FusionModuleLogsTable from '../FusionModule/FusionModuleLogsTable';
import TitanTabs from '../Titan/TitanTabs';
import FinalPartPhotos from './FinalPartPhotos';
import useWebSocket from 'react-use-websocket';
import FusionModuleTelemetry from '../FusionModule/FusionModuleTelemetry';
import { useTitan } from '../Titan/Titan';
import FusionJobStates from '../FusionJob/FusionJobStates';
import FinalPartActions from './FinalPartActions';

export default function FinalPartPage() {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [finalPart, setFinalPart] = React.useState();
  const history = useHistory();
  const location = useLocation();
  const { finalPartId, tab = 'summary' } = useParams();

  const {
    addPageToPageHistory,
    backToPreviousPage,
    getWebSocketUrl,
    getWebSocketOptions,
  } = useTitan();

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  React.useEffect(() => {
    if (
      finalPartId &&
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FINAL_PART &&
      lastJsonMessage.data &&
      lastJsonMessage.data.id === finalPartId
    ) {
      setFinalPart((prev) => ({
        ...prev,
        ...lastJsonMessage.data,
        fusionPlan: prev.fusionPlan, // TODO prepare list on updatable fields
      }));
    }
  }, [lastJsonMessage, finalPartId]);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Fusion Plans',
        path: ROUTES.FUSION_PLANS(0),
      },
      {
        name: finalPart?.fusionPlan?.name || 'Fusion Plan',
        path: ROUTES.FUSION_PLAN_V2_TAB(
          finalPart?.fusionPlan?.id,
          'configuration',
        ),
      },
      {
        name: `Final Part ${finalPartId}`,
        disabled: true,
      },
    ],
    [finalPart],
  );

  React.useEffect(() => {
    setLoading(true);
    AssemblyService.getAssembly(finalPartId, {
      withRelated: [
        'fusor',
        'fusionPlan',
        'fusionPlan.customFields',
        'fusionPlan.customFields.image',
        'fusionJob',
        'fusionJob.mould',
        'customFields',
        'photos',
        'manufacturingOrder',
        'components',
        'components.preformType.customFields',
        'components.customFields',
      ],
    })
      .then((assembly) => {
        setFinalPart(assembly);
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, [finalPartId]);

  const pushHistory = React.useCallback((tabName, settedPage) => {
    history.push(ROUTES.FINAL_PART_TAB_PAGE(finalPartId, tabName, settedPage));
  }, []);

  const preparePageObject = (tabForURL, tab) => {
    return {
      id: `FINAL_PART:${finalPartId}`,
      url:
        tabForURL === 'preforms' || tabForURL === 'logs'
          ? ROUTES.FINAL_PART_TAB_PAGE(finalPartId, tabForURL, 0)
          : ROUTES.FINAL_PART_TAB(finalPartId, tabForURL),
      label: `${
        finalPart.finalPartKey || `Final Part: ${finalPart.id}`
      } | ${tab}`,
    };
  };

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      if (finalPartId) {
        const newRoute = ROUTES.FINAL_PART_TAB_PAGE(
          finalPartId,
          tabName,
          settedPage,
        );
        addPageToPageHistory({
          id: `FINAL_PART:${finalPartId}`,
          url: newRoute,
          label:
            settedPage === 0
              ? `${
                  finalPart.finalPartKey
                    ? finalPart.finalPartKey
                    : `Final Part ${finalPart.id}`
                } | ${labelName}`
              : `${
                  finalPart.finalPartKey
                    ? finalPart.finalPartKey
                    : `Final Part ${finalPart.id}`
                } | ${labelName} | Page : ${settedPage + 1}`,
        });
      }
    },
    [finalPart],
  );

  const tabs = React.useMemo(
    () => [
      {
        label: 'Summary',
        value: 'summary',
        content: finalPart ? (
          <FinalPartSummary finalPart={finalPart} setFinalPart={setFinalPart} />
        ) : (
          ''
        ),
      },
      {
        label: 'Telemetry',
        value: 'telemetry',
        content: finalPart ? (
          <FusionModuleTelemetry
            fusionModuleId={finalPart.fusorId}
            fusionJobId={finalPart.fusionJobId}
            from={finalPart.startFuseTime}
            to={finalPart.endFuseTime}
            timeDirection="forward"
          />
        ) : (
          ''
        ),
      },
      {
        label: 'Preforms',
        value: 'preforms',
        content: <PreformsTable finalPartId={finalPartId} />,
      },
      {
        label: 'Photos',
        value: 'Photos',
        content: (
          <FinalPartPhotos finalPart={finalPart} setFinalPart={setFinalPart} />
        ),
      },
      {
        label: 'Logs',
        value: 'logs',
        content: (
          <FusionModuleLogsTable
            finalPartId={finalPartId}
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        ),
      },
      {
        label: 'States History',
        value: 'states',
        content: <FusionJobStates fusionJob={finalPart?.fusionJob} />,
      },
    ],
    [finalPart, setNewPageForTab],
  );

  const onMarkFused = React.useCallback(async () => {
    await AssemblyService.markFused(finalPart.id);
  }, [finalPart]);

  const onMarkFusionScrap = React.useCallback(async () => {
    await AssemblyService.markFusionScrap(finalPart.id);
  }, [finalPart]);

  return (
    <TitanPage
      loading={loading}
      error={error}
      title={`Final Part ${finalPart?.finalPartKey || finalPartId}`}
      breadcrumbs={breadcrumbs}
      onBackButtonClick={() => {
        backToPreviousPage(location);
      }}
      headerContent={
        <FinalPartActions
          finalPart={finalPart}
          onFused={onMarkFused}
          onFusionScrap={onMarkFusionScrap}
        />
      }
    >
      <TitanTabs
        tabs={tabs}
        activeTab={tab}
        onChangeTab={(value) => {
          const activeLabel = tabs.find((tab) => tab.value === value)?.label;
          addPageToPageHistory(preparePageObject(value, activeLabel));
          history.push(
            value === 'logs' || value === 'preforms'
              ? ROUTES.FINAL_PART_TAB_PAGE(finalPartId, value, 0)
              : ROUTES.FINAL_PART_TAB(finalPartId, value),
          );
        }}
      />
    </TitanPage>
  );
}
