import React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, IconButton, Tooltip } from '@mui/material';

import { useFusionPlan } from './FusionPlanPage';
import TitanTableEmptyData from '../TitanTable/TitanTableEmptyData';
import emptyFusionPlansImage from '../../assets/icons/empty-fusion-plan.svg';
import { FUSION_PLAN_CATEGORIES, ROUTES } from '../../constants';
import Button from '@mui/material/Button';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import FusionPlanParamsTable from './FusionPlanParamsTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import DownloadIcon from '@mui/icons-material/GetApp';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CrossDarkIcon from '../../assets/icons/cross-dark.svg';
import SearchIcon from '../../assets/icons/search.svg';
import Stack from '@mui/material/Stack';
import SelectProjectDialog from '../Projects/SelectProjectDialog';
import FusionPlanService from '../../services/FusionPlanService';
import CopyToClipboard from '../Titan/CopyToClipboard';
import { useHistory } from 'react-router-dom';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanEditableText from '../Titan/TitanEditableText';
import REGEXPS from '../../constants/regexps';
import { useAuth0 } from '../Authentication/Auth0';
import CreateIcon from '@mui/icons-material/Create';
import Typography from '@mui/material/Typography';

import defaultFusionPlanCustomFields from '../../constants/defaultFusionPlanCustomFields';

const FileDownload = require('js-file-download');

export default function FusionPlanConfigurationTab() {
  const history = useHistory();

  const {
    fusionPlan,
    setOpenSelectFusionModuleDialog,
    configurationLoading,
    hasFinalParts,
    updateFusionPlan,
    setOpenMouldsDialog,
  } = useFusionPlan();

  const { currentMemberId, isOrganizationAdmin } = useAuth0();

  const [selectedCategory, setSelectedCategory] = React.useState(
    FUSION_PLAN_CATEGORIES[0],
  );
  const [search, setSearch] = React.useState('');

  const [openProjectsDialog, setOpenProjectsDialog] = React.useState(false);

  const validatePlanKey = (planKey) =>
    planKey.length > 0 ? REGEXPS.PLAN_KEY.test(planKey) : true;

  const validateJobIndex = (jobIndex) =>
    REGEXPS.LAST_JOB_INDEX.test(jobIndex) && Number(jobIndex) > 0;

  const validateComment = (comment) => comment.length;

  const sendLoadConfigurationRequest = () => {
    setOpenSelectFusionModuleDialog(true);
  };

  const isUserFusionPlanOwner = fusionPlan?.memberId === currentMemberId;

  const enableEditing = React.useMemo(
    () => !hasFinalParts && isUserFusionPlanOwner,
    [hasFinalParts, isUserFusionPlanOwner],
  );

  const disableReason = React.useMemo(() => {
    switch (true) {
      case Boolean(fusionPlan.deletedAt):
        return 'Fusion Plan is deleted';
      case !isUserFusionPlanOwner:
        return 'Only owner can edit this field';
      case hasFinalParts:
        return 'Fusion Plan has final parts';
      default:
        return '';
    }
  }, [fusionPlan, isUserFusionPlanOwner, hasFinalParts]);

  if (configurationLoading) {
    return (
      <Paper sx={{ p: 2 }}>
        <TitanCircularProgress />
      </Paper>
    );
  }

  const exportConfiguration = () => {
    FileDownload(
      JSON.stringify({
        fusionModuleVersion: fusionPlan.fusionModuleVersion,
        configuration: fusionPlan.configuration,
      }),
      `${fusionPlan.name}.json`,
    );
  };

  const importConfiguration = (e) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = JSON.parse(event.target.result);

      const configuration = data.configuration ? data.configuration : data;
      const fusionModuleVersion = data.fusionModuleVersion
        ? data.fusionModuleVersion
        : '1.0.0';

      if (fusionPlan.configuration) {
        const newFusionPlanVersion = await FusionPlanService.createFusionPlan(
          {
            name: fusionPlan.name,
            configuration,
            fusionModuleVersion,
            prevFusionPlanId: fusionPlan.id,
            customFields: defaultFusionPlanCustomFields,
          },
          'v2',
        );

        history.push({
          pathname: ROUTES.FUSION_PLAN_V2(newFusionPlanVersion.id),
          state: { from: ROUTES.FUSION_PLANS(0) },
        });
      } else {
        await updateFusionPlan({
          configuration,
          fusionModuleVersion,
        });
      }
    };

    reader.readAsText(e.target.files[0]);
  };

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TitanInfoItem label="Plan Key">
              <TitanEditableText
                label="Plan Key"
                text={fusionPlan.planKey}
                validator={(planKey) => validatePlanKey(planKey)}
                onChangeValue={async (planKey) => {
                  if (planKey) {
                    const planKeyExists = await FusionPlanService.planKeyExists(
                      fusionPlan.id,
                      planKey,
                    );

                    if (planKeyExists) {
                      throw new Error(
                        'Fusion Plan with the same Plan Key already exists within the organization',
                      );
                    }
                  }

                  updateFusionPlan({ planKey });
                }}
                enableEditing={enableEditing}
                disableReason={disableReason}
                haveEditingPermission={isUserFusionPlanOwner}
              />
            </TitanInfoItem>
          </Grid>
          <Grid item xs={3}>
            <TitanInfoItem label="Last job index">
              <TitanEditableText
                label="Last job index"
                text={fusionPlan.lastJobIndex}
                enableEditing={!hasFinalParts && isUserFusionPlanOwner}
                validator={(lastJobIndex) => validateJobIndex(lastJobIndex)}
                onChangeValue={(lastJobIndex) =>
                  updateFusionPlan({ lastJobIndex })
                }
                disableReason={disableReason}
                haveEditingPermission={isUserFusionPlanOwner}
              />
            </TitanInfoItem>
          </Grid>
          <Grid item xs={3}>
            <TitanInfoItem label="Plan version">
              <Box sx={{ padding: '10px 0' }} alignItems="center">
                {fusionPlan.version}
              </Box>
            </TitanInfoItem>
          </Grid>
          <Grid item xs={3}>
            <TitanInfoItem label="Fusion Module Version">
              <Box sx={{ padding: '10px 0' }} alignItems="center">
                {fusionPlan.fusionModuleVersion}
              </Box>
            </TitanInfoItem>
          </Grid>
          <Grid item xs={3}>
            <TitanInfoItem label="Project">
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography display="inline" variant="body1">
                  {fusionPlan.project ? fusionPlan.project.name : '-'}
                </Typography>
                <Tooltip
                  title={
                    !isUserFusionPlanOwner && !isOrganizationAdmin
                      ? "Only Fusion Plan's owner or organization admin can edit this field"
                      : false
                  }
                  disableHoverListener={isUserFusionPlanOwner}
                  disableTouchListener={isUserFusionPlanOwner}
                >
                  <div>
                    <IconButton
                      size="large"
                      onClick={() => setOpenProjectsDialog(true)}
                      disabled={!isUserFusionPlanOwner && !isOrganizationAdmin}
                    >
                      <CreateIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Stack>
            </TitanInfoItem>
          </Grid>
          <Grid item xs={3}>
            <TitanInfoItem label="Owner">
              {fusionPlan.member &&
              fusionPlan.member.name &&
              fusionPlan.member.email ? (
                <CopyToClipboard
                  text={fusionPlan.member.name}
                  copyValue={fusionPlan.member.email}
                  label="owner's email"
                />
              ) : (
                '-'
              )}
            </TitanInfoItem>
          </Grid>
          <Grid item xs={3}>
            <TitanInfoItem label="Mould">
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography display="inline" variant="body1">
                  {fusionPlan.mould ? fusionPlan.mould.mouldIdentifier : '-'}
                </Typography>
                <IconButton
                  size="large"
                  onClick={() => setOpenMouldsDialog(true)}
                >
                  <CreateIcon />
                </IconButton>
              </Stack>
            </TitanInfoItem>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitanInfoItem label="Comment">
              <TitanEditableText
                text={fusionPlan.comment}
                label="Comment"
                validator={(comment) => validateComment(comment)}
                onChangeValue={(comment) => updateFusionPlan({ comment })}
                enableEditing={isUserFusionPlanOwner}
                disableReason={
                  isUserFusionPlanOwner ? '' : 'Only owner can edit this field'
                }
              />
            </TitanInfoItem>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={3} />
            <Grid item xs={9}>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search by parameter's key..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  sx={{ mr: 'auto', width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {search ? (
                          <img
                            src={CrossDarkIcon}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSearch('')}
                          />
                        ) : (
                          <img src={SearchIcon} alt="" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <label htmlFor="import-fusion-plan-configuration-file">
                  <input
                    accept="application/json"
                    id="import-fusion-plan-configuration-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={importConfiguration}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Import
                  </Button>
                </label>
                {fusionPlan && fusionPlan.configuration ? (
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={exportConfiguration}
                  >
                    Export
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={sendLoadConfigurationRequest}
                  title="Load Fusion Plan configuration from Fusion Module"
                >
                  {fusionPlan.configuration
                    ? 'Update configuration'
                    : 'Load configuration'}
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {fusionPlan && fusionPlan.configuration ? (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Tabs
                  orientation="vertical"
                  value={selectedCategory}
                  onChange={(event, category) => {
                    setSelectedCategory(category);
                  }}
                  sx={{ '& .MuiTabs-indicator': { left: 0, right: 'auto' } }}
                >
                  {FUSION_PLAN_CATEGORIES.map((category) => {
                    return (
                      <Tab
                        sx={{ alignItems: 'start' }}
                        key={category}
                        label={category}
                        value={category}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              <Grid item xs={9}>
                <FusionPlanParamsTable
                  search={search}
                  setSearch={setSearch}
                  category={selectedCategory}
                  configuration={fusionPlan.configuration}
                />
              </Grid>
            </Grid>
          ) : (
            <TitanTableEmptyData
              image={emptyFusionPlansImage}
              title="No Fusion Plan Configuration"
              buttonLabel="Load Fusion Plan Configuration"
              onCreateClick={sendLoadConfigurationRequest}
            />
          )}
        </>
      </Paper>

      {openProjectsDialog && (
        <SelectProjectDialog
          project={fusionPlan.project}
          onSave={(projectId) => updateFusionPlan({ projectId })}
          onClose={() => setOpenProjectsDialog(false)}
        />
      )}
    </>
  );
}
