import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import React from 'react';
import {
  MenuItem,
  Chip,
  Stack,
  Tooltip,
  IconButton,
  Checkbox,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import FileService from '../../services/FileService';
import ImageIcon from '@mui/icons-material/Image';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import ListSubheader from '@mui/material/ListSubheader';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

const Input = styled('input')({
  display: 'none',
});
const Image = styled('img')({
  width: '100%',
});

const useStyles = makeStyles((theme) => ({
  imageBlock: {
    position: 'relative',
  },
  imageButton: {
    position: 'absolute',
    top: '2%',
    right: '2%',
    background: '#ffffff99',

    '&:hover': {
      background: '#ffffff',
    },
  },
  label: {
    width: 'fit-content',
    margin: '9px 0',
  },
}));

export default function CustomFieldsDialog({
  onAddCustomField,
  onChangeCustomField,
  onClose,
  customFields,
  customField,
  customTypes,
}) {
  const classes = useStyles();

  const [name, setName] = React.useState(customField ? customField.name : '');
  const [type, setType] = React.useState(
    customField ? customField.type : 'STRING',
  );
  const [baseType, setBaseType] = React.useState(
    customField ? customField.type : 'STRING',
  );
  const [units, setUnits] = React.useState(
    customField ? customField.units : '',
  );
  const [options, setOptions] = React.useState(
    customField && customField.options ? customField.options : '',
  );
  const [expectedValue, setExpectedValue] = React.useState(
    customField ? customField.expectedValue : '',
  );
  const [image, setImage] = React.useState(
    customField && customField.imageId ? customField.image.url : '',
  );
  const [newImage, setNewImage] = React.useState(null);

  const [uploadingImage, setUploadingImage] = React.useState(false);

  const onSubmitForm = React.useCallback(() => {
    const customFieldObject = {
      id: customField ? customField.id : customFields.length + 1,
      name,
      type: baseType,
      units: units === '' ? null : units,
      options: options === '' ? null : options,
      expectedValue,
      isNew: customField && typeof customField.id === 'string' ? false : true,
      formType: customField?.formType ? customField.formType : 'POST_FUSING',
    };

    if (newImage !== null) {
      customFieldObject.newImage = newImage;
    }

    return customField
      ? onChangeCustomField(customFieldObject)
      : onAddCustomField(customFieldObject);
  }, [
    customField,
    name,
    baseType,
    options,
    units,
    image,
    newImage,
    expectedValue,
    onChangeCustomField,
    onAddCustomField,
  ]);

  const checkTypeOptions = React.useMemo(() => {
    if (type.endsWith('_SELECT')) {
      return options.length !== 0;
    } else {
      return true;
    }
  }, [type, options]);

  return (
    <TitanDialog
      title={`${customField ? 'Edit' : 'Create'} Custom field`}
      f
      maxWidth="xs"
      onClose={onClose}
      onSave={onSubmitForm}
      saveButtonLabel={customField ? 'Save' : 'Create'}
      isValid={name.length !== 0 && checkTypeOptions}
    >
      <TextField
        required
        label="Name"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <TextField
          select
          label="Type"
          required
          fullWidth
          value={type}
          onChange={(e) => {
            const data = {
              type: e.target.value,
            };

            let newType = data.type;
            let newTypeOptions;
            let newTypeUnits;

            if (customTypes) {
              const customType = customTypes.find((ct) => ct.type === newType);

              if (customType) {
                newType = customType.baseType;

                if (customType.options) {
                  newTypeOptions = customType.options;
                }

                if (!name || name.length === 0) {
                  setName(customType.typeLabel);
                }

                if (customType.units) {
                  newTypeUnits = customType.units;
                }
              }
            }

            const isNumber = newType === 'NUMBER' ? 'MM' : null;
            setUnits(newTypeUnits ? newTypeUnits : isNumber);
            setExpectedValue('');

            if (type.endsWith('_SELECT') && !newType.endsWith('_SELECT')) {
              setOptions([]);
            }

            setType(data.type);
            setBaseType(newType);

            if (newTypeOptions) {
              setOptions(newTypeOptions);
            }
          }}
        >
          <MenuItem value="STRING">String</MenuItem>
          <MenuItem value="NUMBER">Number</MenuItem>
          <MenuItem value="BOOLEAN">Boolean</MenuItem>
          <MenuItem value="SINGLE_SELECT">Single Select</MenuItem>
          <MenuItem value="MULTI_SELECT">Multi Select</MenuItem>
          {customTypes && <ListSubheader>Custom Types</ListSubheader>}
          {customTypes &&
            customTypes.map((customType) => (
              <MenuItem key={customType.type} value={customType.type}>
                {customType.typeLabel}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
      {type === 'NUMBER' || type.endsWith('_NUMBER') ? (
        <FormControl variant="standard" margin="normal" fullWidth>
          <TextField
            select
            required
            value={units}
            label="Unit"
            fullWidth
            onChange={(e) => {
              setUnits(e.target.value);
              setOptions(null);
            }}
          >
            <MenuItem value="MM">mm</MenuItem>
            <MenuItem value="G">g</MenuItem>
          </TextField>
        </FormControl>
      ) : type.endsWith('_SELECT') ? (
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              required
              variant="outlined"
              margin="normal"
              label="Values"
              {...params}
            />
          )}
          value={options ? options : []}
          onChange={(event, value) => {
            setOptions(value);
            setUnits(null);
          }}
          onBlur={(event) => {
            if (event.target.value.trim() !== '') {
              setOptions((prev) => [...prev, event.target.value]);
              setUnits(null);
            }
          }}
          clearOnBlur={options.length !== 0}
        />
      ) : null}

      {type === 'BOOLEAN' ? (
        <FormControl>
          <FormLabel>Expected Value</FormLabel>
          <RadioGroup
            row
            value={expectedValue}
            onChange={(e) => setExpectedValue(e.target.value)}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      ) : (
        <TextField
          type="string"
          label="Expected value"
          placeholder="Expected value"
          value={expectedValue}
          onChange={(e) => setExpectedValue(e.target.value)}
          fullWidth
          margin="normal"
        />
      )}
      <Stack
        display="flex"
        justifyContent="flex-end"
        className={classes.imageBlock}
      >
        {image && <Image src={image} alt="" />}
        {uploadingImage ? (
          <CircularProgress />
        ) : image ? (
          <Tooltip title="Clear image" className={classes.imageButton}>
            <IconButton component="span" onClick={() => setImage(null)}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <label
            htmlFor={`custom-field-image-${customField ? customField.id : ''}`}
            className={classes.label}
          >
            <Input
              accept="image/*"
              id={`custom-field-image-${customField ? customField.id : ''}`}
              type="file"
              onChange={async (e) => {
                setUploadingImage(true);
                const file = e.target.files[0];

                const fileData = await FileService.uploadFile(file.name, file);
                const base64File = await FileService.fileToBase64(file);

                setUploadingImage(false);
                setImage(base64File);
                setNewImage(fileData);
              }}
            />
            <Button
              variant="outlined"
              component="span"
              startIcon={<ImageIcon />}
            >
              Upload Image
            </Button>
          </label>
        )}
      </Stack>
    </TitanDialog>
  );
}
