import React from 'react';
import FusionJobService from '../../services/FusionJobService';
import FusionJobConfirmationDialogs from './FusionJobConfirmationDialogs';
import { FUSION_JOB_ACTION_TYPES, ROUTES } from '../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '../Authentication/Auth0';

export default function FusionJobActions({
  fusionJob,
  currentAction,
  onSuccess,
  onCancel,
}) {
  const { userId } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  const fusionJobActions = React.useMemo(
    () => ({
      [FUSION_JOB_ACTION_TYPES.FINISH]: async (fusionJob) => {
        history.push({
          pathname: ROUTES.FUSION_JOB_TAB_PAGE(
            fusionJob.id,
            'summary',
            0,
            'post-fusing',
          ),

          state: { from: location.pathname },
        });
      },
      [FUSION_JOB_ACTION_TYPES.RESEND]: async (fusionJob) => {
        try {
          const updatedFusionJob =
            await FusionJobService.resendFusionJob(fusionJob);
          onSuccess({ ...fusionJob, ...updatedFusionJob });
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.SET_AS_FUSED]: async (fusionJob) => {
        try {
          const updatedFusionJob =
            await FusionJobService.markFusionJobFused(fusionJob);
          onSuccess(updatedFusionJob);
        } catch (e) {}
      },

      [FUSION_JOB_ACTION_TYPES.UNLOAD]: async (fusionJob) => {
        try {
          const updatedFusionJob =
            await FusionJobService.unloadFusionJob(fusionJob);
          onSuccess({ ...fusionJob, ...updatedFusionJob });
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.FORCE_UNLOAD]: async (
        fusionJob,
        force = true,
      ) => {
        try {
          const updatedFusionJob = await FusionJobService.unloadFusionJob(
            fusionJob,
            { force },
          );
          onSuccess({ ...fusionJob, ...updatedFusionJob });
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.CANCEL]: async (fusionJob, force = false) => {
        try {
          const updatedFusionJob = await FusionJobService.cancelFusionJob(
            fusionJob,
            force,
          );
          onSuccess({ ...fusionJob, ...updatedFusionJob });
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.FORCE_CANCEL]: async (
        fusionJob,
        force = true,
      ) => {
        try {
          const updatedFusionJob = await FusionJobService.cancelFusionJob(
            fusionJob,
            force,
          );
          onSuccess({ ...fusionJob, ...updatedFusionJob });
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.ATTACH_TO_MO]: async (manufacturingOrder) => {
        try {
          const updatedFusionJob = await FusionJobService.update(fusionJob, {
            manufacturingOrderId: manufacturingOrder.id,
          });
          onSuccess([{ ...updatedFusionJob, manufacturingOrder }]);
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.DETACH_FROM_MO]: async () => {
        try {
          const updatedFusionJob = await FusionJobService.update(fusionJob, {
            manufacturingOrderId: null,
          });
          onSuccess([{ ...updatedFusionJob, manufacturingOrder: null }]);
        } catch (e) {}
      },
      [FUSION_JOB_ACTION_TYPES.SET_FUSION_JOB_OPERATOR]: async (fusionJob) => {
        try {
          const updatedFusionJob = await FusionJobService.update(fusionJob, {
            operatorId: userId,
          });
          onSuccess({ ...fusionJob, ...updatedFusionJob });
        } catch (e) {}
      },
    }),
    [onSuccess, fusionJob],
  );

  return (
    <>
      <FusionJobConfirmationDialogs
        fusionJob={fusionJob}
        currentAction={currentAction}
        onConfirm={(fusionJob, force) =>
          fusionJobActions[currentAction](fusionJob, force)
        }
        onCancel={onCancel}
      />
    </>
  );
}
