import React from 'react';
import Grid from '@mui/material/Grid';

import { useBuildDialog } from './BuildDialog';

import BuildModuleCard from '../BuildModule/BuildModuleCard';

export default function BuildDialogBuildModulesStep() {
  const {
    buildModules,
    selectedBuildModules,
    setSelectedBuildModules
  } = useBuildDialog();

  const onSelectBuildModule = React.useCallback(
    (e, buildModule) => {
      setSelectedBuildModules(prev => {
        if (prev.find(bm => bm.id === buildModule.id)) {
          return prev.filter(bm => bm.id !== buildModule.id);
        }

        return [...prev, buildModule];
      });
    },
    [setSelectedBuildModules]
  );

  return (
    <Grid container spacing={2}>
      {buildModules.map(buildModule => (
        <Grid key={buildModule.id} item xl={6} lg={6} md={6} sm={12} xs={12}>
          <BuildModuleCard
            buildModule={buildModule}
            onClick={onSelectBuildModule}
            selected={
              !!selectedBuildModules.find(bm => bm.id === buildModule.id)
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}
