import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import UserList from '../UserList/UserList';
import BuildModulesTable from '../DeviceList/BuildModulesTable';
import FusionModulesTable from '../DeviceList/FusionModulesTable';
import PreBuildingChecklistsManageList from '../PreBuildingChecklists/PreBuildingChecklistsManageList';
import OrganizationRoleRestrictions from './OrganizationRoleRestrictions';
import OrganizationService from '../../services/OrganizationService';
import { PERMISSIONS } from '../../constants/auth0';
import TitanPage from '../Titan/TitanPage';
import TitanTabPanel from '../Titan/TitanTabPanel';
import { useAuth0 } from '../Authentication/Auth0';
import { ROUTES } from '../../constants';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTitan } from '../Titan/Titan';
import LabelPrintersTable from '../LabelPrinters/LabelPrintersTable';

export default function OrganizationPage() {
  const { organizationId, tabName = 'users' } = useParams();
  const { can, isSuperAdmin } = useAuth0();

  const history = useHistory();
  const { addPageToPageHistory } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [organization, setOrganization] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(
    tabName ? tabName : 'users',
  );

  const loadOrganizationInfo = async () => {
    const data = await OrganizationService.getOrganizationById(organizationId);
    setOrganization(data);
  };

  React.useEffect(() => {
    setLoading(true);

    loadOrganizationInfo().then(() => setLoading(false));
  }, []);

  const breadcrumbs = React.useMemo(
    () => [
      ...(isSuperAdmin
        ? [
            {
              name: 'Admin Dashboard',
              path: ROUTES.ADMIN_DASHBOARD,
            },
          ]
        : []),
      ...(organization
        ? [
            {
              name: organization.name,
              disabled: true,
            },
          ]
        : []),
    ],
    [organization],
  );

  React.useEffect(() => {
    if (organizationId && !isSuperAdmin) {
      const activeLabel =
        tabName === 'build-modules'
          ? 'Build Modules'
          : tabName === 'fusion-modules'
          ? 'Fusion Modules'
          : tabName.charAt(0).toUpperCase() + tabName.slice(1);
      addPageToPageHistory({
        id: `ORGANIZATION_DASHBOARD`,
        url: ROUTES.ORGANIZATION_TAB_PAGE(organizationId, tabName, 0),
        label: `Organization Dashboard | ${activeLabel}`,
      });
    }
  }, [organizationId, isSuperAdmin]);

  const pushHistory = React.useCallback(
    (tabName, settedPage) => {
      history.push(
        ROUTES.ORGANIZATION_TAB_PAGE(organizationId, tabName, settedPage),
      );
    },
    [organizationId, history],
  );

  const preparePageObject = (tabForURL, tab) => {
    return {
      id: `ORGANIZATION_DASHBOARD`,
      url: ROUTES.ORGANIZATION_TAB_PAGE(organizationId, tabForURL, 0),
      label: `Organization Dashboard | ${tab}`,
    };
  };

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      if (!isSuperAdmin) {
        const newRoute = ROUTES.ORGANIZATION_TAB_PAGE(
          organizationId,
          tabName,
          settedPage,
        );
        addPageToPageHistory({
          id: `ORGANIZATION_DASHBOARD`,
          url: newRoute,
          label:
            settedPage === 0
              ? `Organization Dashboard | ${labelName}`
              : `Organization Dashboard | ${labelName} | Page : ${
                  settedPage + 1
                }`,
        });
      }
    },
    [organizationId, isSuperAdmin],
  );

  const tabs = React.useMemo(
    () => [
      {
        permissions: [
          PERMISSIONS.READ_ORGANIZATION_MEMBERS,
          PERMISSIONS.READ_ORGANIZATION_ROLES,
        ],
        label: 'Users',
        value: 'users',
        renderPanel: () => (
          <UserList
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        ),
      },
      {
        permissions: [PERMISSIONS.READ_BUILD_MODULE],
        label: 'Build Modules',
        value: 'build-modules',
        renderPanel: () => (
          <BuildModulesTable
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
            organizationId={organizationId}
          />
        ),
      },
      {
        permissions: [PERMISSIONS.READ_FUSION_MODULE],
        label: 'Fusion Modules',
        value: 'fusion-modules',
        renderPanel: () => (
          <FusionModulesTable
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        ),
      },
      {
        permissions: [
          PERMISSIONS.READ_PRE_BUILDING_CHECKLISTS,
          PERMISSIONS.CREATE_PRE_BUILDING_CHECKLIST,
          PERMISSIONS.UPDATE_PRE_BUILDING_CHECKLIST,
          PERMISSIONS.DELETE_PRE_BUILDING_CHECKLIST,
        ],
        label: 'Pre Building Checklists',
        value: 'pre-building-checklists',
        renderPanel: () => <PreBuildingChecklistsManageList />,
      },
      {
        permissions: [PERMISSIONS.READ_BUILD_MODULE],
        label: 'Label printers',
        value: 'label-printers',
        renderPanel: () => <LabelPrintersTable pushHistory={pushHistory} />,
      },
      isSuperAdmin && {
        permissions: [
          PERMISSIONS.READ_ORGANIZATION_ROLES,
          PERMISSIONS.UPDATE_ORGANIZATION_ROLES,
        ],
        label: 'Role restrictions',
        value: 'role-restrictions',
        renderPanel: () => (
          <OrganizationRoleRestrictions
            organizationId={organizationId}
            pushHistory={pushHistory}
          />
        ),
      },
    ],
    [organizationId, pushHistory, setNewPageForTab],
  );

  return (
    <TitanPage
      title={organization ? organization.name : ''}
      loading={loading}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={(event, newTab) => {
              const activeLabel = tabs.find(
                (tab) => tab.value === newTab,
              )?.label;
              setSelectedTab(newTab);
              addPageToPageHistory(preparePageObject(newTab, activeLabel));
            }}
            sx={{
              '& .MuiTabs-indicator': { left: 0, right: 'auto' },
              '& .MuiTab-root': { textAlign: 'left' },
            }}
          >
            {tabs.map((tab) =>
              can(tab.permissions) ? (
                <Tab
                  sx={{ alignItems: 'start' }}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={ROUTES.ORGANIZATION_TAB_PAGE(
                    organizationId,
                    tab.value,
                    0,
                  )}
                />
              ) : (
                ''
              ),
            )}
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          {tabs.map((tab) =>
            can(tab.permissions) ? (
              <TitanTabPanel
                key={tab.value}
                value={tab.value}
                index={selectedTab}
              >
                {tab.renderPanel()}
              </TitanTabPanel>
            ) : (
              ''
            ),
          )}
        </Grid>
      </Grid>
    </TitanPage>
  );
}
