import React, { useState, useEffect, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';
import ProfileService from '../../services/ProfileService';
import ROUTES from '../../constants/routes';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import { ROLES } from '../../constants';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import auth0 from 'auth0-js';
import { AUTH0_SCOPES } from '../../constants/auth0';
import Axios from 'axios';
import Cookies from 'js-cookie';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = props => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const auth0Client = React.useMemo(() => {
    return new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE_API,
      scope: AUTH0_SCOPES,
      responseType: 'token id_token'
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [expiresAt, setExpiresAt] = useState();
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();
  const [currentMember, setCurrentMember] = useState();
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);
  const [isApplicationEngineer, setIsApplicationEngineer] = useState(false);

  const parseHash = React.useCallback(
    options =>
      new Promise((resolve, reject) => {
        auth0Client.parseHash(options, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }),
    [auth0Client]
  );

  const checkSession = React.useCallback(
    () =>
      new Promise((resolve, reject) => {
        auth0Client.checkSession({}, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }),
    [auth0Client]
  );

  const redirectToWelcomePage = React.useCallback(() => {
    const isManualLogout = window.localStorage.getItem('manualLogout');

    if (
      isManualLogout !== 'true' &&
      !['/welcome', '/'].includes(window.location.pathname)
    ) {
      const queryStringParams = new URL(document.location).searchParams;

      if (queryStringParams.get('reason') === 'AUTO_MERGE_ACCOUNT') {
        enqueueSnackbar(
          'Account was merged with existing. Please login again',
          {
            variant: 'info'
          }
        );
      } else {
        enqueueSnackbar('Session expired... Login again...', {
          variant: 'warning'
        });
      }
    }

    window.localStorage.removeItem('manualLogout');

    if (window.location.pathname !== '/logout') {
      window.localStorage.setItem('lastActivePath', window.location.pathname);
    }

    setLoading(false);
    history.push(ROUTES.WELCOME);
  }, [history, setLoading, enqueueSnackbar]);

  const setProfileData = React.useCallback(
    profile => {
      setProfile(profile);

      let currentMember;
      let roles;
      let permissions;

      if (
        profile.loggedAsMember &&
        profile.loggedAsMember.roles &&
        profile.loggedAsMember.permissions
      ) {
        currentMember = profile.loggedAsMember;
        roles = profile.loggedAsMember.roles;
        permissions = profile.loggedAsMember.permissions.map(
          p => p.permission_name
        );
      } else {
        currentMember = profile;
        roles = profile.roles;

        if (!accessToken) {
          throw new Error('Access token is empty');
        }

        const jwt = jwtDecode(accessToken);

        permissions = jwt.permissions;
      }

      setCurrentMember(currentMember);
      setRoles(roles);
      setPermissions(permissions);

      setIsSuperAdmin(!!roles.find(r => r.id === ROLES.SUPER_ADMIN));
      setIsOrganizationAdmin(!!roles.find(r => r.id === ROLES.ADMIN));
      setIsApplicationEngineer(
        !!roles.find(r => r.id === ROLES.APPLICATION_ENGINEER)
      );
    },
    [accessToken]
  );

  const initAuth0 = React.useCallback(async () => {
    try {
      setLoading(true);

      let authResult;

      if (window.location.hash.includes('#access_token=')) {
        authResult = await parseHash({
          hash: window.location.hash
        });

        authResult.user = authResult.idTokenPayload;
      } else if (window.location.hash.includes('error=')) {
        try {
          authResult = await parseHash({
            hash: window.location.hash
          });
        } catch (e) {
          console.error(e);
          setLoading(false);
          enqueueSnackbar(e.errorDescription, {
            variant: 'error'
          });

          return history.push(ROUTES.WELCOME);
        }
      }

      if (!authResult) {
        try {
          authResult = await checkSession();
        } catch (e) {
          if (e?.code === 'login_required') {
            return redirectToWelcomePage();
          }
        }
      }

      const isAuthenticated = !!authResult;
      const currentTime = new Date().getTime();
      let accessToken;
      let user;
      let expiresAt;

      if (isAuthenticated) {
        accessToken = authResult.accessToken;
        user = authResult.idTokenPayload;
        expiresAt = currentTime + authResult.expiresIn * 1000;

        Axios.interceptors.request.use(async config => {
          if (config.sendAuth0Headers !== false) {
            if (accessToken != null) {
              config.headers.Authorization = `Bearer ${accessToken}`;
            }
          }

          return config;
        });
      } else {
        const isManualLogout = window.localStorage.getItem('manualLogout');

        if (
          isManualLogout !== 'true' &&
          !['/welcome', '/'].includes(window.location.pathname)
        ) {
          enqueueSnackbar('Session expired... Login again...', {
            variant: 'warning'
          });
        }

        window.localStorage.removeItem('manualLogout');

        if (window.location.pathname !== '/logout') {
          window.localStorage.setItem(
            'lastActivePath',
            window.location.pathname
          );
        }

        setLoading(false);
        history.push(ROUTES.WELCOME);
        return;
      }

      setIsAuthenticated(isAuthenticated);
      setAccessToken(accessToken);
      Cookies.set('token', accessToken, {
        secure: true
      });
      setUser(user);
      setExpiresAt(expiresAt);

      try {
        const profile = await ProfileService.loadProfileData();
        setProfile(profile);
      } catch (e) {
        console.error(e);
        setError(`Can't load profile. Please try later.`);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      if (e.error && e.error === 'invalid_token') {
        setError('Invalid token');
      } else {
        setError('Auth error');
      }
      setLoading(false);
    }
  }, [setIsAuthenticated, setProfileData]);

  const checkPermissions = (userPermissions, permission, matchAll = false) => {
    if (userPermissions.length === 0) {
      return false;
    }

    const permissions = Array.isArray(permission) ? permission : [permission];

    const matchingPermissions = userPermissions.filter(p =>
      permissions.includes(p)
    );

    return matchAll
      ? matchingPermissions.length === permissions.length
      : matchingPermissions.length !== 0;
  };

  const can = (permissionsToCheck, matchAll = false) => {
    return checkPermissions(permissions, permissionsToCheck, matchAll);
  };

  useEffect(() => {
    initAuth0();
  }, []);

  useEffect(() => {
    if (user && !user.email_verified) {
      if (location.pathname !== '/verify-email') {
        history.push(ROUTES.VERIFY_EMAIL);
      }
    } else if (
      user &&
      user.email_verified &&
      location.pathname === '/verify-email'
    ) {
      history.push(ROUTES.HOME);
    }
  }, [user, location]);

  useEffect(() => {
    if (profile) {
      setProfileData(profile);
    }
  }, [profile]);

  const login = React.useCallback(
    ({ email, password }) =>
      new Promise((resolve, reject) => {
        auth0Client.login(
          {
            email,
            password,
            realm: process.env.REACT_APP_AUTH0_REALM
          },
          (err, res) => {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          }
        );
      }),
    [auth0Client]
  );

  const socialAuthorize = React.useCallback(
    connection =>
      new Promise((resolve, reject) => {
        auth0Client.authorize({ connection }, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }),
    [auth0Client]
  );

  const changePassword = React.useCallback(
    ({ email }) =>
      new Promise((resolve, reject) => {
        auth0Client.changePassword(
          { email, connection: process.env.REACT_APP_AUTH0_REALM },
          (err, res) => {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          }
        );
      }),
    [auth0Client]
  );

  const logout = React.useCallback(
    async params => {
      window.localStorage.setItem('manualLogout', 'true');

      await new Promise((resolve, reject) => {
        auth0Client.logout(
          {
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI,
            ...params
          },
          (err, res) => {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          }
        );
      });

      setIsAuthenticated(false);
      setAccessToken(null);
      Cookies.remove('token');

      setUser(null);
      setExpiresAt(null);
    },
    [auth0Client, setIsAuthenticated, setAccessToken, setUser, setExpiresAt]
  );

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        accessToken,
        expiresAt,
        user,
        setUser,
        userId: user ? user.sub : null,
        profile,
        setProfile: setProfileData,
        currentMember,
        currentMemberId: currentMember?.id,
        permissions,
        roles,
        login,
        changePassword,
        socialAuthorize,
        logout,
        initAuth0,
        checkPermissions,
        can,
        isSuperAdmin,
        isOrganizationAdmin,
        isApplicationEngineer
      }}
    >
      {error ? (
        <Box
          display="flex"
          flexDirection="column"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Alert severity="error">{error}</Alert>
          <Button onClick={() => logout()} sx={{ mt: 2 }}>
            Try again
          </Button>
        </Box>
      ) : loading ? (
        <TitanCircularProgress fullHeight />
      ) : (
        props.children
      )}
    </Auth0Context.Provider>
  );
};
