import React from 'react';
import Typography from '@mui/material/Typography';
import { COMPONENT_STATUSES } from '../../constants';
import { colors } from '../Theme/vars';
import _ from 'lodash';

import ComponentStatusInfo from './ComponentStatusInfo';
import Box from '@mui/material/Box';

export default function ComponentStatus({
  component,
  layout = 'horizontal',
  showTooltip = true,
  showVersion = true
}) {
  let statusColor = colors.WHITE;
  let statusName = '';
  let progress = '';

  if (!component.status) {
    return '';
  }

  switch (component.status.toUpperCase()) {
    case COMPONENT_STATUSES.ONLINE:
    case COMPONENT_STATUSES.IDLE: // BM state
    case COMPONENT_STATUSES.WAIT_FOR_SUPPORT: // BM state
    case COMPONENT_STATUSES.REPAIR: // BM state
    case COMPONENT_STATUSES.SUPPORT: // BM state
      statusColor = colors.YELLOW;
      break;
    case COMPONENT_STATUSES.OPERATIONAL:
    case COMPONENT_STATUSES.BUILT:
    case COMPONENT_STATUSES.FUSED:
      statusColor = colors.GREEN;
      break;
    case COMPONENT_STATUSES.LOAD_TO_BM:
      statusName = 'Load to BM';
      statusColor = colors.BLUE;
      break;
    case COMPONENT_STATUSES.UNLOAD_FROM_BM:
      statusName = 'Unload from BM';
      statusColor = colors.BLUE;
      break;
    case COMPONENT_STATUSES.LOAD_TO_FM:
      statusName = 'Load to FM';
      statusColor = colors.BLUE;
      break;
    case 'PRINTING':
    case COMPONENT_STATUSES.LOADED_TO_BM:
    case COMPONENT_STATUSES.LOADED_TO_FM:
    case COMPONENT_STATUSES.PRE_BUILDING:
    case COMPONENT_STATUSES.POST_BUILDING:
    case COMPONENT_STATUSES.POST_CANCELED:
    case COMPONENT_STATUSES.POST_FAILED:
    case COMPONENT_STATUSES.PRE_FUSING:
    case COMPONENT_STATUSES.POST_FUSING:

    case COMPONENT_STATUSES.BUILD_PREPARATION: // BM state
    case COMPONENT_STATUSES.POST_BUILD: // BM state
    case COMPONENT_STATUSES.UNLOADING: // BM state
      statusColor = colors.BLUE;
      break;
    case COMPONENT_STATUSES.BUILDING: // BM state
    case COMPONENT_STATUSES.FUSING:
    case COMPONENT_STATUSES.BUILD_PAUSING:
    case COMPONENT_STATUSES.BUILD_PAUSED:
    case COMPONENT_STATUSES.FUSE_PAUSED:

    case COMPONENT_STATUSES.PAUSED: // BM state
      statusColor = colors.BLUE;
      progress = component.progress;
      break;
    case COMPONENT_STATUSES.FUSION_PLAN_LOADED:
      statusName = 'Fusion Plan Loaded';
      statusColor = colors.BLUE;
      break;
    case COMPONENT_STATUSES.UPLOADING_LOG_FILE:
      statusName = 'Uploading Log File';
      statusColor = colors.BLUE;
      break;
    case COMPONENT_STATUSES.BUILD_FAILED:
    case COMPONENT_STATUSES.FUSE_FAILED:
    case COMPONENT_STATUSES.LOAD_TO_BM_FAILED:
    case COMPONENT_STATUSES.LOAD_TO_FM_FAILED:
    case COMPONENT_STATUSES.ERROR: // BM state
      statusColor = colors.LIGHT_RED;
      break;
    case COMPONENT_STATUSES.OFFLINE:
    case COMPONENT_STATUSES.POWER_OFF: // BM state
    case COMPONENT_STATUSES.MAINTENANCE: // BM state
    case COMPONENT_STATUSES.OFFLINE_AFTER_ERROR: // BM state
    case COMPONENT_STATUSES.BUILD_CANCELED:
    case COMPONENT_STATUSES.BUILD_CANCELING:
    case COMPONENT_STATUSES.FUSE_CANCELED:
    case COMPONENT_STATUSES.FUSE_CANCELING:
    case COMPONENT_STATUSES.SCHEDULED:
    case COMPONENT_STATUSES.SCRAPPED:
    case COMPONENT_STATUSES.ASSEMBLY_SCRAPPED:
    case COMPONENT_STATUSES.DEFECTED:
    case COMPONENT_STATUSES.FUSION_SCRAP:
      statusColor = colors.GREY4;
      break;
    default:
      break;
  }

  const formatStatus = status => status.replaceAll('_', ' ').toLowerCase();

  if (!statusName) {
    statusName = _.upperFirst(formatStatus(component.status));
  }

  return component.status ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: layout === 'horizontal' ? 'row' : 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: layout !== 'horizontal' ? 1 : 0
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            mr: 1,
            borderRadius: '50%',
            backgroundColor: statusColor
          }}
          className={statusColor}
        />
        <Typography>{statusName}</Typography>
        {progress ? (
          <Typography color="primary" sx={{ ml: 1 }} variant="button">
            {progress}%
          </Typography>
        ) : (
          ''
        )}
      </Box>
      {component.deviceVersion && showVersion && (
        <Typography sx={{ color: colors.GREY, ml: 1 }}>
          {component.deviceVersion}
        </Typography>
      )}
      {(component.rate || component.buildTime) && showTooltip ? (
        <Box sx={{ ml: 1 }}>
          <ComponentStatusInfo component={component} />
        </Box>
      ) : (
        ''
      )}
    </Box>
  ) : (
    '-'
  );
}
