import React from 'react';
import { COMPONENT_STATUSES } from '../../constants';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { Button } from '@mui/material';

export default function PreformActions({ preform, onBuilt, onMarkAsDefected }) {
  const [actions, setActions] = React.useState([]);
  const [preformToBuilt, setPreformToBuilt] = React.useState();
  const [preformDefected, setPreformDefected] = React.useState();

  React.useEffect(() => {
    const actions = [];

    if (
      [
        COMPONENT_STATUSES.POST_CANCELED,
        COMPONENT_STATUSES.BUILD_CANCELED,
        COMPONENT_STATUSES.DEFECTED,
      ].includes(preform.status)
    ) {
      actions.push({
        color: 'secondary',
        variant: 'outlined',
        label: 'Set as Built',
        onClick: () => setPreformToBuilt(preform),
      });
    }

    if (preform.status === COMPONENT_STATUSES.BUILT) {
      actions.push({
        color: 'primary',
        variant: 'outlined',
        label: 'Mark as Defected',
        onClick: () => setPreformDefected(preform),
      });
    }

    setActions(actions);
  }, [preform]);

  if (actions.length === 0) {
    return '';
  }

  return (
    <>
      {actions.length
        ? actions.map((action, index) => (
            <Button
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick(e);
              }}
              variant={action.variant}
              color={action.color}
            >
              {action.label}
            </Button>
          ))
        : ''}

      {preformToBuilt && (
        <TitanConfirmationDialog
          title={`Set Preform ${preform.id} as successfully built`}
          message={`Are you sure you want to set preform ${preform.id} as successfully built?`}
          onConfirm={async () => {
            await onBuilt(preform);
          }}
          onClose={() => setPreformToBuilt(null)}
        />
      )}

      {preformDefected && (
        <TitanConfirmationDialog
          title={`Mark Preform ${preform.id} as defected`}
          message={`Are you sure you want to mark preform ${preform.id} as defected?`}
          onConfirm={async () => {
            await onMarkAsDefected(preform);
          }}
          onClose={() => setPreformDefected(null)}
        />
      )}
    </>
  );
}
