import React, { useContext } from 'react';
import useAsyncEffect from 'use-async-effect';
import TitanDialog from '../Titan/TitanDialog';
import PrinterService from '../../services/PrinterService';
import LinearProgress from '@mui/material/LinearProgress';
import BuildPlanService from '../../services/BuildPlanService';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import Link from '@mui/material/Link';
import { useTitan } from '../Titan/Titan';
import useBuildModules from '../BuildModule/use-build-modules';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Tooltip from '@mui/material/Tooltip';
import BuildDialogBuildModulesStep from './BuildDialogBuildModulesStep';
import DialogManufacturingOrdersStep from '../ManufacturingOrders/DialogManufacturingOrdersStep';
import BuildDialogReviewStep from './BuildDialogReviewStep';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';

export const BuildDialogContext = React.createContext();
export const useBuildDialog = () => useContext(BuildDialogContext);

const STEP_KEYS = {
  SELECT_BUILD_MODULE: 'SELECT_BUILD_MODULE',
  SELECT_MANUFACTURING_ORDER: 'SELECT_MANUFACTURING_ORDER',
  REVIEW: 'REVIEW'
};

export default function BuildDialog({ buildPlan, onClose, onSave }) {
  const [saveButtonLabel, setSaveButtonLabel] = React.useState('Load to BM');

  const location = useLocation();

  const { addPageToPageHistory } = useTitan();

  const [steps, setSteps] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedBuildModules, setSelectedBuildModules] = React.useState([]);
  const [
    manufacturingOrdersAvailable,
    setManufacturingOrdersAvailable
  ] = React.useState(false);
  const [
    selectedManufacturingOrder,
    setSelectedManufacturingOrder
  ] = React.useState();

  const loadBuildModules = React.useCallback(async () => {
    const response = await PrinterService.getPrinters({
      withRelated: [
        'activeJobs',
        'activeJobs.buildPlan',
        'activeJobs.components',
        'prevBuildJob',
        'prevBuildJob.buildPlan',
        'prevBuildJob.components',
        'labelPrinter'
      ]
    });

    return response.data;
  }, []);

  const { loading, buildModules } = useBuildModules(loadBuildModules);

  React.useEffect(() => {
    setSaveButtonLabel(
      selectedBuildModules.length > 1
        ? "Load to BM's"
        : selectedBuildModules.length === 1 &&
          selectedBuildModules.every(
            selectedBuildModule => selectedBuildModule.isAvailableToBuild
          )
        ? 'Load to BM'
        : 'Add to queue'
    );
  }, [setSaveButtonLabel, selectedBuildModules]);

  useAsyncEffect(async () => {
    const manufacturingOrders = await ManufacturingOrderService.getManufacturingOrders(
      {
        buildPlanIds: [buildPlan.id],
        pageSize: 1
      }
    );

    setManufacturingOrdersAvailable(
      manufacturingOrders.pagination.totalCount !== 0
    );
  }, [buildPlan]);

  React.useEffect(() => {
    setSteps([
      {
        key: STEP_KEYS.SELECT_BUILD_MODULE,
        label: 'Select Build Module'
      },
      {
        key: STEP_KEYS.SELECT_MANUFACTURING_ORDER,
        label: 'Select Manufacturing Order',
        disabled: !buildPlan.projectId || !manufacturingOrdersAvailable,
        disableReason: !buildPlan.projectId
          ? 'Build Plan is not connected to any Project'
          : !manufacturingOrdersAvailable
          ? "Build Plan's Project does not have any Manufacturing Orders"
          : null
      },
      {
        key: STEP_KEYS.REVIEW,
        label: 'Review'
      }
    ]);
  }, [buildPlan.projectId, manufacturingOrdersAvailable]);

  const onSaveHandler = React.useCallback(async () => {
    return Promise.all(
      selectedBuildModules.map(selectedBuildModule =>
        BuildPlanService.startBuild(
          buildPlan.id,
          {
            printerId: selectedBuildModule.id,
            manufacturingOrderId: selectedManufacturingOrder
              ? selectedManufacturingOrder.id
              : null
          },
          'v2'
        ).then(data => {
          if (onSave) {
            onSave(data);
          }
        })
      )
    );
  }, [selectedBuildModules, selectedManufacturingOrder]);

  const messageForPush = React.useMemo(() => {
    const LinksArray = selectedBuildModules.map(
      (selectedBuildModule, i, buildModulesArray) => {
        return (
          <Link
            key={selectedBuildModule?.id || i}
            to={{
              pathname: ROUTES.BUILD_MODULE_TAB(
                selectedBuildModule?.id,
                'buildJobs'
              ),
              state: { from: location.pathname }
            }}
            sx={{
              color: '#fff',
              textDecorationColor: '#fff',
              fontWeight: 'bold',
              margin: '0 5px'
            }}
            component={RouterLink}
            onClick={() =>
              addPageToPageHistory({
                id: `BUILD_MODULE:${selectedBuildModule?.id}`,
                url: ROUTES.BUILD_MODULE_TAB(
                  selectedBuildModule?.id,
                  'buildJobs'
                ),
                label: `${selectedBuildModule?.name ||
                  'Build Module'} | Build Jobs`
              })
            }
          >
            {buildModulesArray.length > 1 && i !== buildModulesArray.length - 1
              ? `${selectedBuildModule?.name},`
              : selectedBuildModule?.name}
          </Link>
        );
      }
    );

    return (
      <>
        {`Build Plan ${buildPlan.name} sent to`}
        {LinksArray}
      </>
    );
  }, [
    buildPlan.name,
    selectedBuildModules,
    location.pathname,
    addPageToPageHistory
  ]);

  return (
    <TitanDialog
      open={true}
      maxWidth="lg"
      title={`Build ${buildPlan.name}`}
      onClose={onClose}
      onSave={activeStep === steps.length - 1 ? onSaveHandler : null}
      saveButtonLabel={saveButtonLabel}
      isValid={selectedBuildModules.length !== 0}
      isNextValid={selectedBuildModules.length !== 0}
      successSaveMessage={messageForPush}
      onPrev={
        activeStep > 0
          ? () => {
              if (
                activeStep === 2 &&
                (!buildPlan.projectId || !manufacturingOrdersAvailable)
              ) {
                setActiveStep(0);
              } else {
                setActiveStep(activeStep - 1);
              }
            }
          : null
      }
      onNext={
        activeStep < steps.length - 1
          ? () => {
              if (
                activeStep === 0 &&
                (!buildPlan.projectId || !manufacturingOrdersAvailable)
              ) {
                setActiveStep(2);
                setSteps(
                  steps.map((step, index) =>
                    index === 1 ? { ...step, completed: false } : step
                  )
                );
              } else {
                setActiveStep(activeStep + 1);
              }
            }
          : null
      }
    >
      <BuildDialogContext.Provider
        value={{
          buildModules,
          selectedBuildModules,
          setSelectedBuildModules,
          selectedManufacturingOrder,
          setSelectedManufacturingOrder
        }}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
              {steps.map(step => (
                <Step key={step.key} completed={step.completed}>
                  {step.disabled ? (
                    <Tooltip title={step.disableReason}>
                      <StepLabel>{step.label}</StepLabel>
                    </Tooltip>
                  ) : (
                    <StepLabel>{step.label}</StepLabel>
                  )}
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 && <BuildDialogBuildModulesStep />}

            {activeStep === 1 && (
              <DialogManufacturingOrdersStep
                buildPlanId={buildPlan.id}
                selectedManufacturingOrder={selectedManufacturingOrder}
                setSelectedManufacturingOrder={setSelectedManufacturingOrder}
              />
            )}

            {activeStep === 2 && <BuildDialogReviewStep />}
          </>
        )}
      </BuildDialogContext.Provider>
    </TitanDialog>
  );
}
