import TitanDialog from '../Titan/TitanDialog';
import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import { useAuth0 } from '../Authentication/Auth0';
import OrganizationService from '../../services/OrganizationService';

export default function PrintHeadDialog({
  onSave,
  onClose,
  editablePrintHead
}) {
  const { isSuperAdmin } = useAuth0();

  const [printHeadId, setPrintHeadId] = React.useState(
    editablePrintHead ? editablePrintHead.printHeadId : ''
  );

  const [version, setVersion] = React.useState(
    editablePrintHead ? editablePrintHead.version : ''
  );

  const [comment, setComment] = React.useState(
    editablePrintHead ? editablePrintHead.comment : ''
  );

  const [organizationId, setOrganizationId] = React.useState(
    editablePrintHead ? editablePrintHead.organizationId : ''
  );
  const [organizations, setOrganizations] = React.useState([]);

  const loadOrganizations = async () => {
    const { data } = await OrganizationService.getOrganizations();
    setOrganizations(data);
  };
  React.useEffect(() => {
    if (isSuperAdmin) {
      loadOrganizations();
    }
  }, [isSuperAdmin]);

  const onSubmitForm = () => {
    return onSave({
      printHeadId,
      organizationId,
      version,
      comment
    });
  };

  return (
    <>
      <TitanDialog
        title={`${editablePrintHead ? 'Edit' : 'Create'} Print Head`}
        maxWidth="xs"
        onClose={onClose}
        onSave={onSubmitForm}
        isValid={
          !!printHeadId && (!isSuperAdmin || (isSuperAdmin && organizationId))
        }
        saveButtonLabel={editablePrintHead ? 'Save' : 'Create'}
      >
        <TextField
          required
          label="Print Head Identifier"
          placeholder="Print Head Identifier"
          value={printHeadId}
          onChange={e => setPrintHeadId(e.target.value)}
          fullWidth
          margin="normal"
        />

        {isSuperAdmin ? (
          <FormControl fullWidth margin="normal">
            <TextField
              select
              label="Organization"
              required
              fullWidth
              value={organizationId}
              onChange={e => setOrganizationId(e.target.value)}
            >
              {organizations.map(organization => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        ) : (
          ''
        )}

        <TextField
          type="text"
          label="Version"
          placeholder="Version"
          value={version}
          onChange={e => {
            setVersion(e.target.value);
          }}
          fullWidth
          margin="normal"
        />

        <TextField
          type="text"
          label="Comment"
          placeholder="Comment"
          value={comment}
          onChange={e => {
            setComment(e.target.value);
          }}
          fullWidth
          multiline
          rows={3}
          margin="normal"
        />
      </TitanDialog>
    </>
  );
}
