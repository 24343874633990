import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BuildJobConfirmationDialogs from './BuildJobConfirmationDialogs';
import BuildJobService from '../../services/BuildJobService';
import { BUILD_JOB_ACTION_TYPES, ROUTES } from '../../constants';
import { useAuth0 } from '../Authentication/Auth0';

export default function BuildJobActions({
  buildJob,
  currentAction,
  onSuccess,
  onCancel,
}) {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useAuth0();

  const buildJobActions = React.useMemo(
    () => ({
      [BUILD_JOB_ACTION_TYPES.ON_FINISH]: async (buildJob) => {
        history.push({
          pathname: ROUTES.BUILD_JOB_TAB_PAGE(
            buildJob.id,
            'summary',
            0,
            'post-building',
          ),

          state: { from: location.pathname },
        });
      },
      [BUILD_JOB_ACTION_TYPES.ON_SET_AS_BUILT]: async (buildJob) => {
        try {
          await BuildJobService.markBuildJobBuilt(buildJob.id);
          onSuccess(buildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_DEFER_POST_BUILDING]: async (buildJob) => {
        try {
          await BuildJobService.deferPostBuilding(buildJob.id);
          onSuccess(buildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_RESEND]: async (buildJob) => {
        try {
          const updatedBuildJob =
            await BuildJobService.resendBuildJob(buildJob);
          onSuccess(updatedBuildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_UNLOAD]: async (buildJob, force = false) => {
        try {
          const updatedBuildJob = await (force
            ? BuildJobService.cancelBuildJob(buildJob, force)
            : BuildJobService.unloadBuildJob(buildJob, force));

          onSuccess(updatedBuildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_FORCE_UNLOAD]: async (
        buildJob,
        force = true,
      ) => {
        try {
          const updatedBuildJob = await BuildJobService.unloadBuildJob(
            buildJob,
            force,
          );
          onSuccess(updatedBuildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_CANCEL]: async (buildJob, force = false) => {
        try {
          const updatedBuildJob = await BuildJobService.cancelBuildJob(
            buildJob,
            force,
          );
          onSuccess(updatedBuildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_FORCE_CANCEL]: async (
        buildJob,
        force = true,
      ) => {
        try {
          const updatedBuildJob = await BuildJobService.cancelBuildJob(
            buildJob,
            force,
          );
          onSuccess(updatedBuildJob);
        } catch (e) {}
      },
      [BUILD_JOB_ACTION_TYPES.ON_SET_BUILD_JOB_OPERATOR]: async (buildJob) => {
        try {
          const updatedBuildJob = await BuildJobService.updateBuildJob(
            buildJob.id,
            {
              createdBy: userId,
            },
          );
          onSuccess(updatedBuildJob);
        } catch (e) {}
      },
      /* [BUILD_JOB_ACTION_TYPES.ON_ATTACH_TO_MANUFACTURING_ORDER]: async manufacturingOrder => {
        try {
          const updatedBuildJob = await BuildJobService.updateBuildJob(
            buildJob.id, // will work correctly?
            { manufacturingOrderId: manufacturingOrder.id }
          );
          //updateBuildJob({ ...updatedBuildJob, manufacturingOrder });
        } catch (e) {
          //pushSnackbar(e.message, { variant: 'error' });
        }
      },
      [BUILD_JOB_ACTION_TYPES.ON_DETACH_FROM_MANUFACTURING_ORDER]: async buildJob => {
        try {
          const updatedBuildJob = await BuildJobService.updateBuildJob(
            buildJob.id,
            { manufacturingOrderId: null }
          );
          //updateBuildJob({ ...updatedBuildJob, manufacturingOrder: null });
        } catch (e) {
          //pushSnackbar(e.message, { variant: 'error' });
        }
      } */
    }),
    [onSuccess],
  );

  return (
    <>
      <BuildJobConfirmationDialogs
        buildJob={buildJob}
        currentAction={currentAction}
        onConfirm={(buildJob, force) =>
          buildJobActions[currentAction](buildJob, force)
        }
        onCancel={onCancel}
      />
    </>
  );
}
