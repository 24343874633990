import React from 'react';
import ComponentStatus from '../Component/ComponentStatus';
import FinalPartService from '../../services/FinalPartService';
import {
  ROUTES,
  SORT_ORDERS,
  WEB_SOCKET_ACTIONS,
  COMPONENT_STATUSES,
} from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import FinalPartLink from './FinalPartLink';
import FinalPartsTableFilter from './FinalPartsTableFilter';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useWebSocket from 'react-use-websocket';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import FusionPlanService from '../../services/FusionPlanService';
import FusionJobService from '../../services/FusionJobService';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { RenderTreeCell } from '../TitanDataGrid/TitanGroupingTreeCell';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import renderCellExpand from '../ExpandRenderCell/ExpandRenderCell';
import PreformLink from '../Component/PreformLink';
import AssemblyService from '../../services/AssemblyService';
import { RenderFinalPartsCell } from '../TablesComponents/RenderFinalPartsCell';
import { useTitan } from '../Titan/Titan';
import FinalPartsChips from './FinalPartsChips';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import ManufacturingOrderSelectionDialog from '../Fibrify/FibrifyManufacturingOrderSelectionDialog';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';

export default function FinalPartsTable({
  fusionPlanId,
  fusionJobId,
  rootFusionPlanId,
  manufacturingOrderIds = [],
  isHeaderShown = true,
  columnsToHide = [],
  setNewPageForTab,
}) {
  const { getWebSocketUrl, getWebSocketOptions, pushSnackbar } = useTitan();

  const history = useHistory();
  const location = useLocation();
  const [fusionPlanVersionType, setFusionPlanVersionType] =
    React.useState('current');
  const { page: pageParam = 0 } = useParams();
  const [fusionPlan, setFusionPlan] = React.useState();

  const [
    finalPartToAttachToManufacturingOrder,
    setFinalPartToAttachToManufacturingOrder,
  ] = React.useState(null);
  const [
    finalPartToDetachFromManufacturingOrder,
    setFinalPartToDetachFromManufacturingOrder,
  ] = React.useState(null);

  React.useEffect(() => {
    let columnsFusionPlanId;

    if (fusionPlanVersionType === 'all' && rootFusionPlanId) {
      columnsFusionPlanId = rootFusionPlanId;
    } else if (fusionPlanId) {
      columnsFusionPlanId = fusionPlanId;
    }

    if (columnsFusionPlanId) {
      FusionPlanService.getFusionPlan(
        columnsFusionPlanId,
        {
          withRelated: ['customFields'],
        },
        'v2',
      ).then((fusionPlan) => setFusionPlan(fusionPlan));
    } else if (fusionPlanId) {
      FusionJobService.getFusionJob(fusionJobId, {
        withRelated: ['fusionPlan', 'fusionPlan.customFields'],
      }).then((fusionPlan) => setFusionPlan(fusionPlan.fusionPlan));
    }
  }, []);

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: [
          'fusor',
          'customFields',
          'customFields.fusionPlanCustomField',
          'components',
          'components.manufacturingOrder',
          'components.printer',
          'fusionJob.mould',
          'fusionPlan',
          'fusionPlan.customFields',
          'manufacturingOrder',
        ],
      };

      if (fusionPlanVersionType === 'all' && rootFusionPlanId) {
        params.rootFusionPlanId = rootFusionPlanId;
      } else if (fusionPlanId) {
        params.fusionPlanId = fusionPlanId;
      }

      if (fusionJobId) {
        params.fusionJobId = fusionJobId;
      }

      if (manufacturingOrderIds) {
        params.manufacturingOrderIds = manufacturingOrderIds;
      }

      const { data, pagination } = await FinalPartService.getFinalParts(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [rootFusionPlanId, fusionPlanId, fusionJobId, fusionPlanVersionType],
  );

  const isEditable = React.useCallback((row) => {
    return [COMPONENT_STATUSES.FUSED].includes(row.status);
  }, []);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  const updateFinalParts = (finalPartsToUpdate) => {
    const filteredFinalPartsToUpdate = finalPartsToUpdate.filter(
      (finalPart) =>
        (fusionJobId && finalPart.fusionJobId === fusionJobId) ||
        (fusionPlanId && finalPart.fusionPlanId === fusionPlanId) ||
        (manufacturingOrderIds &&
          manufacturingOrderIds.includes(finalPart.manufacturingOrderId)) ||
        (!fusionJobId && !fusionPlanId && !manufacturingOrderIds),
    );

    if (filteredFinalPartsToUpdate.length === 0) {
      return;
    }

    const changeFinalParts = (rows) => {
      let finalParts = [...rows];

      finalParts = finalParts.map((fP) => {
        const preformToUpdate = filteredFinalPartsToUpdate.find(
          (c) => c.id === fP.id,
        );

        if (preformToUpdate) {
          return {
            ...fP,
            ...preformToUpdate,
          };
        }

        return fP;
      });

      const newFinalParts = [];

      filteredFinalPartsToUpdate.forEach((fp) => {
        if (!finalParts.find((finalPart) => finalPart.id === fp.id)) {
          newFinalParts.push(fp);
        }
      });

      if (newFinalParts.length !== 0) {
        finalParts = [...prepareRows(newFinalParts), ...finalParts];
      }

      return finalParts;
    };

    setRows((prev) => changeFinalParts(prev));
  };

  const prepareFinalPartToUpdate = (fieldType, row, customField, newValue) => {
    let fpToUpdate = {
      ...row,
      customFields: [
        ...row.customFields.map((cf) => {
          if (cf.fusionPlanCustomField.name === customField) {
            return {
              ...cf,
              value:
                fieldType === 'MULTI_SELECT' ? newValue.join(', ') : newValue,
            };
          }
          return cf;
        }),
      ],
    };
    return fpToUpdate;
  };

  const prepareCustomFieldsObject = (row, customFieldId, newValue) => {
    return {
      customFields: Object.values({
        ...AssemblyService.prepareFinalPartCustomFields(row),
        [customFieldId]: {
          ...AssemblyService.prepareFinalPartCustomFields(row)[customFieldId],
          value: newValue,
        },
      }),
    };
  };

  const getCustomFieldFromUpdatedFinalPart = (finalPart, customFieldId) => {
    const updatedCF = finalPart.customFields.find(
      (cf) => cf.fusionPlanCustomFieldId === customFieldId,
    );
    return updatedCF;
  };

  const updateAssembly = async (row, data, cf, preformToUpdate) => {
    const updatedComponent = await AssemblyService.updateAssembly(row.id, data);
    const updatedCF = getCustomFieldFromUpdatedFinalPart(
      updatedComponent,
      cf.id,
    );
    if (updatedCF) {
      if (preformToUpdate.customFields.find((cf) => cf.id === updatedCF.id)) {
        preformToUpdate.customFields = preformToUpdate.customFields.map(
          (cf) => {
            return cf.id === updatedCF.id ? updatedCF : cf;
          },
        );
      } else {
        preformToUpdate.customFields = [
          ...preformToUpdate.customFields,
          updatedCF,
        ];
      }
    }
    await updateFinalParts([preformToUpdate]);
  };

  const updateCustomField = React.useCallback(
    async (finalPart, updatedCF) => {
      const updatedCustomFields = finalPart.customFields.map((cf) => {
        if (cf.id === updatedCF.id) {
          return updatedCF;
        }
        return cf;
      });
      await AssemblyService.updateAssembly(finalPart.id, {
        customFields: updatedCustomFields,
      });
    },
    [fusionPlan],
  );

  const staticColumns = React.useMemo(
    () => [
      {
        headerName: 'Device',
        field: 'device',
        visibilityBreakpoint: 'sm',
        hide: columnsToHide?.includes('device'),
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row && row.fusor ? (
            <FusionModuleLink
              fusionModule={row.fusor}
              width={colDef.computedWidth}
            />
          ) : row && row.printer ? (
            <BuildModuleLink
              buildModule={row.printer}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          history.push({
            pathname: ROUTES.FUSION_MODULE(row.fusor.id),
            state: { from: location.pathname },
          });
        },
      },
      {
        headerName: 'Manufacturing order',
        field: 'manufacturingOrder',
        visibilityBreakpoint: 'md',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: columnsToHide.includes('manufacturingOrder'),
        renderCell: ({ row }) =>
          row.manufacturingOrder ? (
            <ManufacturingOrderLink
              manufacturingOrder={row.manufacturingOrder}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Operator',
        field: 'operator',
        visibilityBreakpoint: 'lg',
        hide: columnsToHide ? columnsToHide.includes('operator') : false,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          row.fusionJob && row.fusionJob.operator
            ? row.fusionJob.operator.name
            : '',
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (
          <ComponentStatus
            component={{
              status: row.status,
              progress: row.progress,
            }}
          />
        ),
      },
      {
        headerName: 'Time',
        field: 'time',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <TitanTimeAgo
            time={
              row.status === COMPONENT_STATUSES.SCHEDULED
                ? row.createdAt
                : row.updatedAt
            }
            start={row.startFuseTime}
            end={row.endFuseTime}
            status={row.status}
          />
        ),
      },
      {
        headerName: 'Mould',
        field: 'mould',
        visibilityBreakpoint: 'lg',
        minWidth: 180,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(
            row.fusionJob && row.fusionJob.mould
              ? row.fusionJob.mould.mouldIdentifier
              : '',
            colDef.computedWidth,
          ),
      },
      /*
      {
        headerName: '',
        field: 'actions',
        hideable: false,
        hideInMenu: true,
        type: 'actions',
        visibilityBreakpoint: 'sm',
        width: 60,
        getActions: params =>
          FinalPartService.getFinalPartActions({
            finalPart: params.row,
            onAttachToManufacturingOrder: () => {
              setFinalPartToAttachToManufacturingOrder(params.row);
            },
            onDetachFromManufacturingOrder: () => {
              setFinalPartToDetachFromManufacturingOrder(params.row);
            }
          }).map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              showInMenu
              onClick={action.onClick}
              disabled={action.disabled}
            />
          ))
      }
      */
    ],
    [],
  );
  const groupingColDef = React.useMemo(
    () => ({
      headerName: 'ID',
      field: 'id',
      visibilityBreakpoint: 'sm',
      hideable: false,
      pinnable: true,
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        return params.row.assemblyId ? (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <PreformLink preform={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        ) : (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <FinalPartLink finalPart={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        );
      },
      onCellClick: (params) => {
        if (params.row.assemblyId) {
          history.push({
            pathname: ROUTES.PREFORM(params.id),
            state: { from: location.pathname },
          });
        } else {
          history.push({
            pathname: ROUTES.FINAL_PART(params.id),
            state: { from: location.pathname },
          });
        }
      },
    }),
    [],
  );

  const gridOptions = {
    orders: {
      created_at: SORT_ORDERS.DESC,
    },
    columns: staticColumns,
    groupingColumnInfo: {
      groupingColumn: groupingColDef,
      childrenArrays: ['components'],
    },
    pinnedColumns: {
      left: ['id'],
      right: [],
    },
    onChangePage: (page) => {
      setNewPageForTab('finalParts', 'Final Parts', page);
    },
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { setRows, prepareRows, setColumns } = titanDataGridProps;

  React.useEffect(() => {
    if (fusionPlan && fusionPlan.customFields) {
      const customFieldColumns = [];
      fusionPlan.customFields
        .sort((a, b) => a.order - b.order)
        .forEach((customField) => {
          if (!customFieldColumns.find((c) => c.name === customField.name)) {
            customFieldColumns.push({
              headerName: customField.name,
              field: customField.name,
              visibilityBreakpoint: 'lg',
              minWidth: 200,
              hide: true,
              sortable: false,
              renderCell: ({ row }) => (
                <RenderFinalPartsCell
                  row={row}
                  customField={customField}
                  fusionPlan={fusionPlan}
                  isEditable={isEditable}
                  prepareFinalPartToUpdate={prepareFinalPartToUpdate}
                  updateAssembly={updateAssembly}
                  updateFinalParts={updateFinalParts}
                  updateCustomField={updateCustomField}
                  prepareCustomFieldsObject={prepareCustomFieldsObject}
                />
              ),
            });
          }
        });

      setColumns([...staticColumns, ...customFieldColumns]);
    }
  }, [fusionPlan]);

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      [WEB_SOCKET_ACTIONS.FINAL_PART, WEB_SOCKET_ACTIONS.PREFORM].includes(
        lastJsonMessage.action,
      ) &&
      lastJsonMessage.data
    ) {
      updateFinalParts([lastJsonMessage.data]);
    }
  }, [lastJsonMessage]);

  const getRowHeight = React.useCallback(
    ({ model }) => {
      if (model.customFields) {
        const customFieldsLength = model?.customFields
          ?.filter((cf) => {
            return cf.fusionPlanCustomField.type.endsWith('SELECT');
          })
          ?.reduce((accum, current) => {
            if (current.fusionPlanCustomField.options.length >= accum) {
              return current.fusionPlanCustomField.options.length + 1;
            }
            return accum;
          }, 0);
        if (customFieldsLength && customFieldsLength > 0) {
          return 1.25 * 52;
        }
      }
      return null;
    },
    [fusionPlan],
  );

  const onAttachToManufacturingOrder = React.useCallback(
    async (manufacturingOrder) => {
      try {
        const updatedFinalPart = await AssemblyService.updateAssembly(
          finalPartToAttachToManufacturingOrder.id,
          {
            manufacturingOrderId: manufacturingOrder.id,
          },
        );
        updateFinalParts([
          {
            ...updatedFinalPart,
            manufacturingOrder,
          },
        ]);
      } catch (e) {
        pushSnackbar(e.message, {
          variant: 'error',
        });
      }
    },
    [finalPartToAttachToManufacturingOrder?.id, pushSnackbar, updateFinalParts],
  );

  const onDetachFromManufacturingOrder = React.useCallback(async () => {
    try {
      const updatedFinalPart = await AssemblyService.updateAssembly(
        finalPartToDetachFromManufacturingOrder.id,
        { manufacturingOrderId: null },
      );
      updateFinalParts([
        {
          ...updatedFinalPart,
          manufacturingOrder: null,
        },
      ]);
    } catch (e) {
      pushSnackbar(e.message, {
        variant: 'error',
      });
    }
  }, [
    finalPartToDetachFromManufacturingOrder?.id,
    pushSnackbar,
    updateFinalParts,
  ]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        treeData={true}
        components={{
          Toolbar: TitanDataGridToolbar,
        }}
        getRowHeight={getRowHeight}
        showSearch={isHeaderShown}
        searchPlaceholder="Search by Final Part ID"
        filtersContent={
          !isHeaderShown ? null : rootFusionPlanId ? (
            <FinalPartsTableFilter
              fusionPlanVersionType={fusionPlanVersionType}
              onChangeFusionPlanVersionType={(buildPlanVersionType) => {
                setFusionPlanVersionType(buildPlanVersionType);
              }}
            />
          ) : (
            ''
          )
        }
        chipsContent={
          fusionPlanId ? (
            <FinalPartsChips
              fusionPlanVersionType={fusionPlanVersionType}
              setFusionPlanVersionType={setFusionPlanVersionType}
            />
          ) : (
            ''
          )
        }
      />

      {finalPartToAttachToManufacturingOrder && (
        <ManufacturingOrderSelectionDialog
          title="Select Manufacturing Order to which you want to connect this Final Part"
          filters={{
            fusionPlanIds: [
              finalPartToAttachToManufacturingOrder.fusionPlan.id,
            ],
          }}
          onSave={onAttachToManufacturingOrder}
          onClose={() => setFinalPartToAttachToManufacturingOrder(null)}
          {...(finalPartToAttachToManufacturingOrder.manufacturingOrder && {
            currentSelection: [
              finalPartToAttachToManufacturingOrder.manufacturingOrder,
            ],
          })}
        />
      )}

      {finalPartToDetachFromManufacturingOrder && (
        <TitanConfirmationDialog
          title="Remove Final Part from Manufacturing Order"
          message="Are you sure you want to remove this Final Part from Manufacturing Order?"
          onConfirm={() => onDetachFromManufacturingOrder()}
          onClose={() => setFinalPartToDetachFromManufacturingOrder(null)}
        />
      )}
    </>
  );
}
