import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { colors } from '../Theme/vars';
import FusionModuleIcon from '../../assets/icons/fusion-module.svg';
import ComponentStatus from '../Component/ComponentStatus';
import Paper from '@mui/material/Paper';
import { Grid, Button, List } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import FusionJobLink from '../FusionJob/FusionJobLink';
import FinalPartLink from '../FinalPart/FinalPartLink';
import Box from '@mui/material/Box';
import useFusionModuleStatus from './use-fusion-module-status';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles({
  container: {
    border: `2.75px solid ${colors.WHITE}`
  },
  selected: {
    border: `2.75px solid ${colors.RED}`
  },
  content: {
    width: '100%'
  },
  statusBar: {
    width: '100%',
    backgroundColor: colors.GREY2
  }
});

export default function FusionModuleCard({ fusionModule, onClick, selected }) {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const [activeJob, setActiveJob] = React.useState();
  const [prevJob, setPrevJob] = React.useState();
  const [showAllFinalParts, setShowAllFinalParts] = React.useState(false);

  const { addPageToPageHistory } = useTitan();

  const {
    status,
    setStatus,
    progress,
    setProgress,
    deviceVersion,
    setDeviceVersion
  } = useFusionModuleStatus(fusionModule.id);

  React.useEffect(() => {
    if (fusionModule.prevFusionJob) {
      setPrevJob(fusionModule.prevFusionJob);
    } else {
      setPrevJob(null);
    }
    if (fusionModule.activeJobs && fusionModule.activeJobs.length !== 0) {
      setActiveJob(fusionModule.activeJobs[0]);
    } else {
      setActiveJob(null);
    }
  }, [fusionModule]);

  React.useEffect(() => {
    setStatus(
      fusionModule.state ? fusionModule.state.state : COMPONENT_STATUSES.OFFLINE
    );
    setProgress(fusionModule.state ? fusionModule.state.progress : 0);
    setDeviceVersion(
      fusionModule.state ? fusionModule.state.fusionModuleVersion : 'v1.0.0'
    );
  }, [fusionModule]);

  return (
    <Paper
      sx={{
        width: {
          xs: '100%'
        },
        minHeight: {
          xs: 280,
          md: 283,
          lg: 300
        },
        p: 2,
        display: 'flex',
        cursor: 'pointer'
      }}
      className={classNames({
        [classes.container]: true,
        [classes.selected]: selected
      })}
      onClick={e => {
        if (onClick) {
          onClick(e, fusionModule);
        } else {
          addPageToPageHistory({
            id: `FUSION_MODULE:${fusionModule.id}`,
            url: ROUTES.FUSION_MODULE_TAB(fusionModule.id, 'temperature'),
            label: `${fusionModule.name || 'Fusion Module'} | Temperature`
          });
          history.push({
            pathname: ROUTES.FUSION_MODULE(fusionModule.id),
            state: { from: location.pathname }
          });
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box maxWidth={130}>
            <img src={FusionModuleIcon} alt="" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                {fusionModule.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ComponentStatus
                component={{
                  status,
                  progress,
                  deviceVersion
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {(activeJob || prevJob) && (
          <>
            <Grid item xs={6} key={'fusion-job'}>
              <TitanInfoItem
                label={'Fusion Job'}
                chipInfo={`${activeJob ? 'active' : 'previous'}`}
              >
                <FusionJobLink fusionJob={activeJob ? activeJob : prevJob} />

                <List
                  sx={{
                    maxHeight: showAllFinalParts
                      ? `${
                          activeJob
                            ? activeJob.assemblies.length * 25
                            : prevJob.assemblies.length * 25
                        }px`
                      : '50px',
                    overflow: 'hidden',
                    transition: '0.5s ease-out'
                  }}
                >
                  {activeJob
                    ? activeJob.assemblies.map(finalPart => (
                        <FinalPartLink
                          finalPart={finalPart}
                          key={finalPart.id}
                        />
                      ))
                    : prevJob.assemblies.map(finalPart => (
                        <FinalPartLink
                          finalPart={finalPart}
                          key={finalPart.id}
                        />
                      ))}
                </List>
                {(activeJob
                  ? activeJob?.assemblies?.length > 2
                  : prevJob?.assemblies?.length > 2) && (
                  <Button
                    sx={{ width: '100%', padding: '0 20px', marginTop: '5px' }}
                    onClick={e => {
                      e.stopPropagation();
                      setShowAllFinalParts(prev => !prev);
                    }}
                  >
                    {' '}
                    {showAllFinalParts ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Button>
                )}
              </TitanInfoItem>
            </Grid>
            <Grid
              item
              xs={6}
              key={'fusion-job-status'}
              sx={{ minWidth: '50%' }}
            >
              {activeJob && (
                <TitanInfoItem label={'Job Status'}>
                  <ComponentStatus component={activeJob} />
                </TitanInfoItem>
              )}

              {activeJob && (
                <TitanInfoItem
                  label={
                    [COMPONENT_STATUSES.SCHEDULED].includes(activeJob.status)
                      ? 'Created'
                      : [
                          COMPONENT_STATUSES.LOAD_TO_FM,
                          COMPONENT_STATUSES.LOADED_TO_FM,
                          COMPONENT_STATUSES.PRE_FUSING
                        ].includes(activeJob.status)
                      ? 'Updated'
                      : activeJob.endFuseTime
                      ? 'Finished'
                      : 'Started'
                  }
                >
                  <TitanTimeAgo
                    time={
                      activeJob.status === COMPONENT_STATUSES.SCHEDULED
                        ? activeJob.createdAt
                        : [
                            COMPONENT_STATUSES.LOAD_TO_FM,
                            COMPONENT_STATUSES.LOADED_TO_FM,
                            COMPONENT_STATUSES.PRE_FUSING
                          ].includes(activeJob.status)
                        ? activeJob.statusUpdatedAt || activeJob.createdAt
                        : null
                    }
                    start={activeJob.startFuseTime}
                    end={activeJob.endFuseTime}
                    status={activeJob.status}
                    emptyValue="-"
                  />
                </TitanInfoItem>
              )}
              {!activeJob && prevJob && (
                <TitanInfoItem
                  label={
                    [COMPONENT_STATUSES.FUSED].includes(prevJob.status)
                      ? 'Finished'
                      : [COMPONENT_STATUSES.FUSED_CANCELED].includes(
                          prevJob.status
                        )
                      ? 'Canceled'
                      : ''
                  }
                >
                  <TitanTimeAgo
                    time={
                      prevJob.status === COMPONENT_STATUSES.FUSED
                        ? prevJob.endFuseTime || prevJob.updatedAt || '-'
                        : [COMPONENT_STATUSES.BUILD_CANCELED].includes(
                            prevJob.status
                          )
                        ? prevJob.statusUpdatedAt
                        : null
                    }
                    start={prevJob.startFuseTime}
                    end={prevJob.endFuseTime}
                    status={prevJob.status}
                    emptyValue="-"
                  />
                </TitanInfoItem>
              )}
            </Grid>
          </>
        )}

        {status &&
          [COMPONENT_STATUSES.FUSING, COMPONENT_STATUSES.FUSE_PAUSED].includes(
            status
          ) && (
            <Grid item xs={12}>
              <LinearProgress
                sx={{ width: '100%', backgroundColor: colors.GREY2 }}
                variant="determinate"
                value={progress}
              />
            </Grid>
          )}
      </Grid>
    </Paper>
  );
}
