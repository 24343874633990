import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import { ROUTES, SORT_ORDERS } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import FusionPlanService from '../../services/FusionPlanService';
import Button from '@mui/material/Button';
import PlusWhiteIcon from '../../assets/icons/plus-white.svg';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import ProjectLink from '../Projects/ProjectLink';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import FusionPlanDialog from '../FusionPlan/FusionPlanDialog';
import { useAuth0 } from '../Authentication/Auth0';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';

import defaultFusionPlanCustomFields from '../../constants/defaultFusionPlanCustomFields';
import useComparableFusionPlans from './use-comparable-fusion-plans';

export default function FusionPlansPage() {
  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();

  const { currentMemberId, roles } = useAuth0();
  const {
    comparablePlans,
    toggleComparablePlan,
    renderCompareButton,
    renderClearButton
  } = useComparableFusionPlans();

  const [openFusionPlanDialog, setOpenFusionPlanDialog] = React.useState(false);
  const [editableFusionPlan, setEditableFusionPlan] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [
    openRemoveFromProjectDialog,
    setOpenRemoveFromProjectDialog
  ] = React.useState(false);

  const { pushSnackbar, addPageToPageHistory } = useTitan();

  React.useEffect(() => {
    localStorage.setItem(
      'comparableFusionPlans',
      JSON.stringify(comparablePlans)
    );
  }, [comparablePlans]);

  const getComparable = React.useCallback(
    plan => {
      return !!comparablePlans.includes(plan.id);
    },
    [comparablePlans]
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Fusion Plans',
        disabled: true
      }
    ],
    []
  );

  const loadData = React.useCallback(async (params, config) => {
    const { data, pagination } = await FusionPlanService.getFusionPlansV2(
      {
        ...params,
        withRelated: ['project']
      },
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const gridOptions = {
    orders: {
      created_at: SORT_ORDERS.DESC
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);
  const { rows, page, setRows, reloadData } = titanDataGridProps;

  const updateFusionPlan = React.useCallback(
    fusionPlan =>
      setRows(prev =>
        prev.map(fp =>
          fp.id === fusionPlan.id ? { ...fp, ...fusionPlan } : fp
        )
      ),
    [rows]
  );

  const onSaveFusionPlan = async data => {
    let planKeyExists = false;

    if (data.planKey) {
      planKeyExists = await FusionPlanService.planKeyExists(
        editableFusionPlan.id,
        data.planKey
      );
    }

    if (planKeyExists) {
      throw new Error(
        'Fusion Plan with the same Plan Key already exists within the organization'
      );
    } else {
      if (editableFusionPlan.id) {
        const updatedFusionPlan = await FusionPlanService.updateFusionPlan(
          editableFusionPlan.id,
          data,
          'v2'
        );
        updateFusionPlan(updatedFusionPlan);
      } else {
        const newFusionPlan = await FusionPlanService.createFusionPlan(
          { ...data, customFields: defaultFusionPlanCustomFields },
          'v2'
        );
        setRows(prev => [newFusionPlan, ...prev]);
      }

      setOpenFusionPlanDialog(false);
      setEditableFusionPlan({});
    }
  };

  const onRemoveFromProject = React.useCallback(async () => {
    await FusionPlanService.updateFusionPlan(
      editableFusionPlan.id,
      {
        projectId: null
      },
      'v2'
    );

    await reloadData();
  }, [editableFusionPlan, reloadData]);

  const onDelete = async () => {
    await FusionPlanService.deleteFusionPlan(editableFusionPlan.id, 'v2');

    pushSnackbar('Fusion Plan successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows(prev => prev.filter(fp => fp.id !== editableFusionPlan.id));
    setEditableFusionPlan({});
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (
          <FusionPlanLink fusionPlan={row} sx={{ whiteSpace: 'pre-wrap' }} />
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.FUSION_PLAN_V2(row.id),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Project',
        field: 'project',
        sortable: false,
        flex: 1,
        minWidth: 200,
        renderCell: ({ row, colDef }) =>
          row.projectId ? (
            <ProjectLink project={row.project} width={colDef.computedWidth} />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.projectId) {
            history.push({
              pathname: ROUTES.PROJECT(row.projectId),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 180,
        sortable: false,
        getActions: params =>
          FusionPlanService.getFusionPlanActions(
            params.row,
            currentMemberId,
            roles,
            {
              onRename: () => {
                setEditableFusionPlan(params.row);
                setOpenFusionPlanDialog(true);
              },
              onDelete: () => {
                setEditableFusionPlan(params.row);
                setOpenDeleteDialog(true);
              },
              onToggleFavorite: fusionPlan => updateFusionPlan(fusionPlan),
              onRemoveFromProject: () => {
                setEditableFusionPlan(params.row);
                setOpenRemoveFromProjectDialog(true);
              },
              onAddToComparison: () => {
                toggleComparablePlan(params.row);
              },
              isInComparison: () => getComparable(params.row)
            }
          ).map(action =>
            action.label === '' && action.disabled ? (
              <Tooltip
                title="No configuration in this plan"
                placement="top-start"
              >
                <span>
                  <GridActionsCellItem
                    icon={action.icon}
                    label={action.label}
                    onClick={action.onClick}
                    disabled={action.disabled}
                    showInMenu={!!action.label}
                  />
                </span>
              </Tooltip>
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                disabled={action.disabled}
                showInMenu={!!action.label}
              />
            )
          )
      }
    ],
    [
      comparablePlans,
      currentMemberId,
      roles,
      history,
      location.pathname,
      toggleComparablePlan,
      updateFusionPlan,
      getComparable
    ]
  );

  React.useEffect(() => {
    addPageToPageHistory({
      id: `FUSION_PLANS`,
      url: ROUTES.FUSION_PLANS(page),
      label: page === 0 ? `Fusion Plans` : `Fusion Plans | Page : ${page + 1}`
    });
  }, [page]);

  return (
    <TitanPage
      breadcrumbs={breadcrumbs}
      title="Fusion Plans"
      headerContent={
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenFusionPlanDialog(true);
            }}
          >
            <img src={PlusWhiteIcon} alt="Plus Icon" /> Fusion Plan
          </Button>
          {renderCompareButton()}
          {renderClearButton()}
        </>
      }
    >
      <TitanDataGrid
        {...titanDataGridProps}
        searchPlaceholder="Search by Fusion Plan name..."
        columns={columns}
      />

      {openFusionPlanDialog && (
        <FusionPlanDialog
          fusionPlan={editableFusionPlan}
          onClose={() => {
            setOpenFusionPlanDialog(false);
            setEditableFusionPlan({});
          }}
          currentMemberId={currentMemberId}
          onSave={onSaveFusionPlan}
        />
      )}

      {openRemoveFromProjectDialog && (
        <TitanConfirmationDialog
          title="Remove this Fusion Plan from Project?"
          message={`Are you sure you want to remove this Fusion Plan from Project?`}
          onClose={() => {
            setOpenRemoveFromProjectDialog(false);
            setEditableFusionPlan({});
          }}
          onConfirm={() => onRemoveFromProject()}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Fusion Plan?"
          message={`Are you sure you want to delete Fusion Plan ${
            editableFusionPlan.name
          }?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </TitanPage>
  );
}
