import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import {
  SORT_ORDERS,
  ROUTES,
  WEB_SOCKET_ACTIONS,
  COMPONENT_STATUSES,
  COMPONENT_ACTIVE_STATUSES,
  FUSION_JOB_ACTION_TYPES,
} from '../../constants';
import React from 'react';
import ComponentStatus from '../Component/ComponentStatus';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import FusionJobService from '../../services/FusionJobService';
import FusorService from '../../services/FusorService';
import FinalPartLink from '../FinalPart/FinalPartLink';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import useWebSocket from 'react-use-websocket';
import FusionJobLink from './FusionJobLink';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { useTitan } from '../Titan/Titan';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import FusionJobConfirmationDialogs from './FusionJobConfirmationDialogs';
import { RenderTreeCell } from '../TitanDataGrid/TitanGroupingTreeCell';
import PreformLink from '../Component/PreformLink';
import FusionJobsFilter from './FusionJobsFilter';
import FusionJobsChips from './FusionJobsChips';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';
import BuildJobActions from '../BuildJob/BuildJobActions';
import FusionJobActions from './FusionJobActions';

export default function FusionJobsTable({
  fusionPlan,
  fusionModuleId,
  fusionModuleName,
  statuses,
  hiddenColumns = [],
  hideFusionModulesFilter = false,
}) {
  const history = useHistory();
  const location = useLocation();
  const { pushSnackbar } = useTitan();

  const fusionPlanId = fusionPlan?.id;
  const fusionPlanName = fusionPlan?.name;
  const rootFusionPlanId = fusionPlan?.root;

  const [showAll, setShowAll] = React.useState(true);

  const [fusionModules, setFusionModules] = React.useState([]);
  const [chosenFusionModules, setChosenFusionModules] = React.useState([]);

  const [currentFusionJob, setCurrentFusionJob] = React.useState();
  const [currentAction, setCurrentAction] = React.useState();

  const [
    jobToDetachFromManufacturingOrder,
    setJobToDetachFromManufacturingOrder,
  ] = React.useState();
  const [fusionJobsIdsToCancel, setFusionJobsIdsToCancel] = React.useState();

  const { addPageToPageHistory, getWebSocketUrl, getWebSocketOptions } =
    useTitan();

  const { page: pageParam = 0 } = useParams();

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: [
          'fusionPlan',
          'assemblies',
          'fusor',
          'fusionPlan.member',
          'assemblies.components',
          'assemblies.components.manufacturingOrder',
          'mould',
          'manufacturingOrder',
        ],
      };

      if (
        (rootFusionPlanId && !fusionPlanId) ||
        (rootFusionPlanId && showAll)
      ) {
        params.rootFusionPlanId = rootFusionPlanId;
      } else if (fusionPlanId) {
        params.fusionPlanId = fusionPlanId;
      }

      if (fusionModuleId) {
        params.fusionModuleId = fusionModuleId;
      }

      if (chosenFusionModules.length > 0) {
        params.fusionModulesIds = chosenFusionModules.map(
          (fusionModule) => fusionModule.id,
        );
      }

      if (statuses && !showAll) {
        params.statuses = statuses;
      }

      // Fusion Plans > Fusion Jobs
      if (fusionPlanId && !showAll) {
        params.fusionPlanId = fusionPlanId;
      }

      const { data, pagination } = await FusionJobService.getFusionJobs(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [
      statuses,
      fusionModuleId,
      rootFusionPlanId,
      chosenFusionModules,
      fusionPlanId,
      showAll,
    ],
  );

  const onFusionModulesChange = (e, values) => {
    const chosenFusionModules = [];

    values.forEach((fusionModule) => {
      chosenFusionModules.push(fusionModule);
    });

    setChosenFusionModules(chosenFusionModules);
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Final Parts',
        field: 'finalParts',
        visibilityBreakpoint: 'lg',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.assemblies
            ? row.assemblies.map((finalPart) => (
                <FinalPartLink
                  finalPart={finalPart}
                  key={finalPart.id}
                  width={colDef.computedWidth}
                />
              ))
            : '',
        onCellClick: (params) => {
          history.push({
            pathname: ROUTES.FINAL_PART(params.row.assemblies[0].id),
            state: { from: location.pathname },
          });
        },
      },
      {
        headerName: 'Fusion Module',
        field: 'fusionModule',
        visibilityBreakpoint: 'sm',
        hide: hiddenColumns.includes('fusionModule'),
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.fusor ? (
            <FusionModuleLink
              fusionModule={row.fusor}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.fusor) {
            history.push({
              pathname: ROUTES.FUSION_MODULE(row.fusor.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Fusion Plan',
        field: 'fusionPlan',
        visibilityBreakpoint: 'lg',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('fusionPlan'),
        renderCell: ({ row, colDef }) =>
          row.fusionPlan ? (
            <FusionPlanLink
              fusionPlan={row.fusionPlan}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.fusionPlan) {
            history.push({
              pathname: ROUTES.FUSION_PLAN_V2(row.fusionPlanId),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Manufacturing order',
        field: 'manufacturingOrder',
        visibilityBreakpoint: 'md',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('manufacturingOrder'),
        renderCell: ({ row }) =>
          row.manufacturingOrder ? (
            <ManufacturingOrderLink
              manufacturingOrder={row.manufacturingOrder}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Operator',
        field: 'operator',
        visibilityBreakpoint: 'md',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('operator'),
        renderCell: ({ row }) => (row.operator ? row.operator.name : ''),
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.status && <ComponentStatus component={row} />,
      },
      {
        headerName: 'Time',
        field: 'time',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (
          <TitanTimeAgo
            time={
              row.status === COMPONENT_STATUSES.SCHEDULED
                ? row.createdAt
                : row.updatedAt
            }
            start={row.startFuseTime}
            end={row.endFuseTime}
            status={row.status}
          />
        ),
      },
      {
        headerName: '',
        field: 'actions',
        hideable: false,
        hideInMenu: true,
        type: 'actions',
        visibilityBreakpoint: 'sm',
        width: 60,
        getActions: (params) =>
          FusionJobService.getFusionJobActions({
            fusionJob: params.row,
            onFinish: async () => {
              setCurrentAction(FUSION_JOB_ACTION_TYPES.FINISH);
              setCurrentFusionJob(params.row);
            },
            onResend: () => {
              setCurrentAction(FUSION_JOB_ACTION_TYPES.RESEND);
              setCurrentFusionJob(params.row);
            },
            onCancel: () => {
              setCurrentFusionJob(params.row);
              setCurrentAction(FUSION_JOB_ACTION_TYPES.CANCEL);
            },
            onForceCancel: () => {
              setCurrentFusionJob(params.row);
              setCurrentAction(FUSION_JOB_ACTION_TYPES.FORCE_CANCEL);
            },
            onUnload: () => {
              setCurrentAction(FUSION_JOB_ACTION_TYPES.UNLOAD);
              setCurrentFusionJob(params.row);
            },
            onForceUnload: () => {
              setCurrentFusionJob(params.row);
              setCurrentAction(FUSION_JOB_ACTION_TYPES.FORCE_UNLOAD);
            },
            onSetAsFused: () => {
              setCurrentFusionJob(params.row);
              setCurrentAction(FUSION_JOB_ACTION_TYPES.SET_AS_FUSED);
            },
            onSetFusionJobOperator: () => {
              setCurrentFusionJob(params.row);
              setCurrentAction(FUSION_JOB_ACTION_TYPES.SET_FUSION_JOB_OPERATOR);
            },
          }).map((action) => {
            if (params.row?.assemblyId) {
              return <span />;
            } else {
              return (
                <GridActionsCellItem
                  icon={action.icon}
                  label={action.label}
                  showInMenu
                  onClick={action.onClick}
                  disabled={action.disabled}
                />
              );
            }
          }),
      },
    ],
    [],
  );

  const groupingColDef = React.useMemo(
    () => ({
      headerName: 'ID',
      field: 'id',
      visibilityBreakpoint: 'sm',
      hideable: false,
      pinnable: true,
      minWidth: 190,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.assemblyId ? (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <PreformLink preform={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        ) : (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <FusionJobLink fusionJob={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        );
      },
      onCellClick: ({ row }) => {
        if (row.assemblyId) {
          history.push({
            pathname: ROUTES.PREFORM(row.id),
            state: { from: location.pathname },
          });
        } else {
          history.push({
            pathname: ROUTES.FUSION_JOB(row.id),
            state: { from: location.pathname },
          });
        }
      },
    }),
    [],
  );

  const pushHistory = React.useCallback(
    (page) => {
      let newRecentPage;
      if (fusionPlanId || rootFusionPlanId) {
        newRecentPage = {
          id: `FUSION_PLAN:${fusionPlanId || rootFusionPlanId}`,
          url: ROUTES.FUSION_PLAN_V2_TAB_PAGE(
            fusionPlanId || rootFusionPlanId,
            'fusionJobs',
            page,
          ),
          label:
            page === 0
              ? `${fusionPlanName || 'Fusion Plan'} | Fusion Jobs`
              : `${
                  fusionPlanName || 'Fusion Plan'
                } | Fusion Jobs | Page : ${page + 1}`,
        };
      } else if (fusionModuleId) {
        newRecentPage = {
          id: `FUSION_MODULE:${fusionModuleId}`,
          url: ROUTES.FUSION_MODULE_TAB_PAGE(
            fusionModuleId,
            'fusionJobs',
            page,
          ),
          label:
            page === 0
              ? `${
                  fusionModuleName ? fusionModuleName : 'Fusion Module'
                } | Fusion Jobs`
              : `${
                  fusionModuleName ? fusionModuleName : 'Fusion Module'
                } | Fusion Jobs | Page : ${page + 1}`,
        };
      }

      addPageToPageHistory(newRecentPage);
    },
    [fusionPlanId, rootFusionPlanId, fusionModuleId],
  );

  const gridOptions = {
    orders: {
      updated_at: SORT_ORDERS.DESC,
    },
    columns,
    groupingColumnInfo: {
      groupingColumn: groupingColDef,
      childrenArrays: ['assemblies'],
      nestedChildrenArrays: ['components'],
    },
    pinnedColumns: {
      left: ['id'],
      right: ['actions'],
    },
    onChangePage: (page) => {
      pushHistory(page);
    },
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { rows, loading, setRows, pageSize, prepareRows, reloadData } =
    titanDataGridProps;

  const updateFusionJobs = React.useCallback(
    (fusionJobsToUpdate) => {
      const filteredFusionJobsToUpdate = fusionJobsToUpdate
        .filter(
          (fusionJob) =>
            (fusionModuleId &&
              (fusionJob.fusionModuleId === fusionModuleId ||
                fusionJob.fusorId === fusionModuleId)) ||
            (fusionPlanId && fusionJob.fusionPlanId === fusionPlanId) ||
            (!fusionModuleId && !fusionPlanId),
        )
        .map((fusionJob) => ({ ...fusionJob, hierarchy: [fusionJob.id] }));

      if (filteredFusionJobsToUpdate.length === 0) {
        return;
      }

      setRows((rows) => {
        let fusionJobs = rows;

        fusionJobs = fusionJobs.map((fusionJob) => {
          const fusionJobToUpdate = filteredFusionJobsToUpdate.find(
            (c) => c.id === fusionJob.id,
          );

          if (fusionJobToUpdate) {
            const { hierarchy, ...newFusionJobData } = fusionJobToUpdate;

            return {
              ...fusionJob,
              ...newFusionJobData,
            };
          }

          return fusionJob;
        });

        const newFusionJobs = [];

        filteredFusionJobsToUpdate.forEach((fj) => {
          if (!fusionJobs.find((fusionJob) => fusionJob.id === fj.id)) {
            newFusionJobs.push(fj);
          }
        });

        if (newFusionJobs.length !== 0) {
          fusionJobs = [...prepareRows(newFusionJobs), ...fusionJobs];
        }

        if (statuses && !showAll) {
          fusionJobs = fusionJobs.filter((fj) => statuses.includes(fj.status));
        }

        return fusionJobs.slice(0, pageSize);
      });
    },
    [chosenFusionModules, showAll],
  );

  const cancelAllFusionJobs = React.useCallback(() => {
    const fusionJobsIdsToCancel = rows
      .filter(
        (fusionJob) =>
          [COMPONENT_STATUSES.SCHEDULED, ...COMPONENT_ACTIVE_STATUSES].includes(
            fusionJob.status,
          ) && fusionJob?.hierarchy?.length === 1,
      )
      .map((fusionJob) => fusionJob.id);

    if (fusionJobsIdsToCancel.length) {
      setFusionJobsIdsToCancel(fusionJobsIdsToCancel);
    } else {
      pushSnackbar('There are no active Build Jobs ', { variant: 'warning' });
    }
  }, [rows, setFusionJobsIdsToCancel]);

  const actions = React.useMemo(
    () => [
      {
        title: 'Cancel All',
        onClick: cancelAllFusionJobs,
      },
    ],
    [cancelAllFusionJobs],
  );

  React.useEffect(() => {
    if (
      !loading &&
      lastJsonMessage !== null &&
      [WEB_SOCKET_ACTIONS.FUSION_JOB, WEB_SOCKET_ACTIONS.PREFORM].includes(
        lastJsonMessage.action,
      ) &&
      lastJsonMessage.data
    ) {
      updateFusionJobs([lastJsonMessage.data]);
    }
  }, [loading, lastJsonMessage, updateFusionJobs]);

  React.useEffect(() => {
    FusorService.getFusors().then(({ data }) => setFusionModules(data));
  }, []);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        treeData={true}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        actions={actions}
        filtersContent={
          statuses || rootFusionPlanId ? (
            <FusionJobsFilter
              showAll={showAll}
              setShowAll={setShowAll}
              hideFusionModulesFilter={hideFusionModulesFilter}
              fusionModules={fusionModules}
              chosenFusionModules={chosenFusionModules}
              onFusionModulesChange={onFusionModulesChange}
            />
          ) : (
            ''
          )
        }
        defaultGroupingExpansionDepth={1}
        chipsContent={
          <FusionJobsChips
            showAll={showAll}
            setShowAll={(value) => setShowAll(value)}
            isVersionChips={fusionPlanId !== undefined}
            isActivityChips={fusionPlanId === undefined}
            chosenFusionModules={chosenFusionModules}
            setChosenFusionModules={setChosenFusionModules}
          />
        }
      />

      {fusionJobsIdsToCancel && (
        <TitanConfirmationDialog
          title="Cancel all Fusion Jobs?"
          message="Are you sure you want to cancel all Fusion Jobs?"
          onClose={() => setFusionJobsIdsToCancel(null)}
          onConfirm={async () => {
            await Promise.all(
              fusionJobsIdsToCancel.map((fusionJobId) =>
                FusionJobService.cancelFusionJob({ id: fusionJobId }),
              ),
            );
          }}
        />
      )}

      <FusionJobActions
        fusionJob={currentFusionJob}
        currentAction={currentAction}
        onSuccess={(updatedFusionJob) => {
          updateFusionJobs([updatedFusionJob]);
          reloadData();
        }}
        onCancel={() => setCurrentAction(null)}
      />
    </>
  );
}
