import React from 'react';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';

import PrinterService from '../../services/PrinterService';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildModuleState from '../BuildModule/BuildModuleState';
import FusorService from '../../services/FusorService';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import FusionModuleStatus from '../FusionModule/FusionModuleStatus';
import { COMPONENT_STATUSES } from '../../constants';

export default function FibrifyFusionModuleSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  onSave,
  saveButtonLabel = 'Select',
  onClose
}) {
  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        ...filters,
        page: page,
        pageSize: pageSize
      };

      if (search) {
        params.search = search;
      }

      return FusorService.getFusors(params, config);
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <FusionModuleLink fusionModule={row} />
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 300,
        sortable: false,
        renderCell: ({ row }) => (
          <FusionModuleStatus
            fusionModuleId={row.id}
            initialProgress={row.state ? row.state.progress : 0}
            initialDeviceVersion={
              row.state ? row.state.fusionModuleVersion : 'v1.0.0'
            }
            initialStatus={
              row.state ? row.state.state : COMPONENT_STATUSES.OFFLINE
            }
          />
        )
      }
    ],
    []
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by build module name"
      chipsNameKey="name"
    />
  );
}
