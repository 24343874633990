import React from 'react';
import { useFusionPlan } from './FusionPlanPage';
import CustomFields from '../CustomFields/CustomFields';
import { Box } from '@mui/material';

export default function FusionPlanCustomFields() {
  const { fusionPlan, hasFinalParts, updateFusionPlan } = useFusionPlan();

  const [customFields, setCustomFields] = React.useState();
  const [preFusionCustomFields, setPreFusionCustomFields] = React.useState([]);
  const [postFusionCustomFields, setPostFusionPostFusionCustomFields] =
    React.useState([]);

  const [openPreFusionDialog, setOpenPreFusionDialog] = React.useState(false);
  const [openPostFusionDialog, setOpenPostFusionDialog] = React.useState(false);

  const saveCustomFields = async (data) => {
    setCustomFields(data);

    const isValid = data.every((cf) => cf.name.length !== 0);

    if (isValid) {
      const customFields = data.map(
        (
          {
            id,
            name,
            type,
            units,
            options,
            expectedValue,
            image,
            isNew,
            newImage,
            formType,
          },
          order,
        ) => {
          const customField = {
            name,
            type,
            units,
            options,
            expectedValue,
            order,
            image,
            formType,
          };

          if (image === null) {
            customField.newImage = null;
          }

          if (newImage !== undefined) {
            customField.newImage = newImage;
          }

          if (!isNew) {
            customField.id = id;
          }

          return customField;
        },
      );

      await updateFusionPlan({
        customFields,
      });
    }
  };

  const onAddCustomField = React.useCallback(
    async (newCustomField) => {
      await saveCustomFields(
        [...customFields, newCustomField].sort((a, b) => a.order - b.order),
      );
    },
    [saveCustomFields, customFields],
  );

  React.useEffect(() => {
    setCustomFields(fusionPlan.customFields);
    setPreFusionCustomFields(
      [...fusionPlan.customFields]
        .filter((cf) => cf.formType === 'PRE_FUSION')
        .sort((a, b) => a.order - b.order),
    );
    setPostFusionPostFusionCustomFields(
      [...fusionPlan.customFields]
        .filter((cf) => cf.formType === 'POST_FUSION')
        .sort((a, b) => a.order - b.order),
    );
  }, [fusionPlan.customFields]);

  const onChangeCustomField = React.useCallback(
    async (updatedCustomField, save = true) => {
      if (save) {
        await saveCustomFields(
          customFields.map((row) => {
            return row.id === updatedCustomField.id ? updatedCustomField : row;
          }),
        );
      } else {
        setCustomFields((prev) =>
          prev.map((row) =>
            row.id === updatedCustomField.id ? updatedCustomField : row,
          ),
        );
      }
    },
    [customFields, saveCustomFields],
  );

  const onDeleteCustomField = React.useCallback(
    async (customFieldToDelete) => {
      return saveCustomFields(
        customFields
          .filter((cf) => cf.id !== customFieldToDelete.id)
          .sort((a, b) => a.order - b.order),
      );
    },
    [customFields, saveCustomFields],
  );

  const preFusionCustomTypes = React.useMemo(() => {
    return [
      {
        type: 'WEIGHT_PRE_FUSION_NUMBER',
        baseType: 'NUMBER',
        typeLabel: 'Weight pre-fusion',
        units: 'G',
      },
    ];
  }, []);

  const customTypes = React.useMemo(() => {
    return [
      {
        type: 'MOULD_SLOT_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Mold-Slot',
        options: ['1', '2', '3', '4'],
      },
      {
        type: 'ADDED_POLYMER_NUMBER',
        baseType: 'NUMBER',
        typeLabel: 'Added polymer material',
        units: 'G',
      },
      {
        type: 'WEIGHT_PRE_FUSION_NUMBER',
        baseType: 'NUMBER',
        typeLabel: 'Weight pre-fusion',
        units: 'G',
      },
      {
        type: 'WEIGHT_POST_FUSION_NUMBER',
        baseType: 'NUMBER',
        typeLabel: 'Weight post-fusion',
        units: 'G',
      },
      {
        type: 'DIFFERENCE_BETWEEN_WEIGHT_NUMBER',
        baseType: 'NUMBER',
        typeLabel: 'Difference between weight pre/post-fusion',
        units: 'G',
      },
      {
        type: 'HEIGHT_POST_FUSION_NUMBER',
        baseType: 'NUMBER',
        typeLabel: 'Height post-fusion',
        units: 'MM',
      },
      {
        type: 'PART_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Part NC',
        options: [
          'Dry',
          'Open pores',
          'To high outflow',
          'To low final height',
          'Other',
        ],
      },
    ];
  }, []);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CustomFields
          customFields={preFusionCustomFields}
          onAdd={(newCustomField) =>
            onAddCustomField({ ...newCustomField, formType: 'PRE_FUSION' })
          }
          onChange={onChangeCustomField}
          onDelete={onDeleteCustomField}
          disableEdit={hasFinalParts}
          disableEditTooltiop={
            hasFinalParts
              ? "You can't add a new custom field because the fusion plan has final parts"
              : ''
          }
          disableDelete={hasFinalParts}
          disableDeleteTooltiop={
            hasFinalParts
              ? "You can't delete custom field because the fusion plan has final parts"
              : ''
          }
          title="Pre-fusion"
          openDialog={openPreFusionDialog}
          setOpenDialog={setOpenPreFusionDialog}
          customTypes={preFusionCustomTypes}
        />
      </Box>

      <CustomFields
        customFields={postFusionCustomFields}
        onAdd={(newCustomField) =>
          onAddCustomField({ ...newCustomField, formType: 'POST_FUSION' })
        }
        onChange={onChangeCustomField}
        onDelete={onDeleteCustomField}
        disableEdit={hasFinalParts}
        disableEditTooltiop={
          hasFinalParts
            ? "You can't add a new custom field because the fusion plan has final parts"
            : ''
        }
        disableDelete={hasFinalParts}
        disableDeleteTooltiop={
          hasFinalParts
            ? "You can't delete custom field because the fusion plan has final parts"
            : ''
        }
        title="Post-fusion"
        openDialog={openPostFusionDialog}
        setOpenDialog={setOpenPostFusionDialog}
        customTypes={customTypes}
      />
    </>
  );
}
