import React from 'react';
import { Typography } from '@mui/material';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TitanDialog from '../Titan/TitanDialog';
import ComponentService from '../../services/ComponentService';
import BuildJobService from '../../services/BuildJobService';
import Rating from '@mui/material/Rating';
import IconButton from '@mui/material/IconButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import nanoid from 'nanoid';

import FileService from '../../services/FileService';
import TitanTakePhotosDialog from '../Titan/TitanTakePhotosDialog';
import Badge from '@mui/material/Badge';
import PreformCustomFields from '../Preform/PreformCustomFields';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import { COMPONENT_STATUSES } from '../../constants';
import PreformService from '../../services/PreformService';

const rates = [
  { key: 'GOOD_QUALITY', label: 'Good Quality' },
  { key: 'CALIBRATION_ISSUE', label: 'Calibration Issue' },
  { key: 'FIRMWARE_ERROR', label: 'Firmware Error' },
  { key: 'USER_ERROR', label: 'User Error' },
  { key: 'MACHINE_WEAR_FAILURE', label: 'Machine Wear Failure' },
  { key: 'TESTING', label: 'Testing' },
  { key: 'UNKNOWN_ISSUE', label: 'Unknown Issue' },
  { key: 'CF_ISSUE', label: 'CF Issue' },
  { key: 'POLYMER_ISSUE', label: 'Polymer Issue' },
  { key: 'RETRACTION_ISSUE', label: 'Retraction Issue' },
  { key: 'BLADE_ISSUE', label: 'Blade Issue' },
  { key: 'OVERHEATING_ISSUE', label: 'Overheating issue' }
];

export default function BuildJobPostBuildingDialog({ buildJob, onClose }) {
  const [buildPlateImageUrl, setBuildPlateImageUrl] = React.useState();
  const [preforms, setPreforms] = React.useState([]);
  const [buildJobPhotos, setBuildJobPhotos] = React.useState(buildJob.photos);
  const [buildJobQuality, setBuildJobQuality] = React.useState(0);
  const [buildJobComment, setBuildJobComment] = React.useState('');
  const [takePhotoBuildJob, setTakePhotoBuildJob] = React.useState();
  const [takePhotoPreform, setTakePhotoPreform] = React.useState();
  const [isFinalStep, setIsFinalStep] = React.useState(false);

  const [currentTab, setCurrentTab] = React.useState('buildJob');

  React.useEffect(() => {
    let buildPlateImage;

    if (buildJob && buildJob.buildPlan && buildJob.buildPlan.files) {
      buildPlateImage = buildJob.buildPlan.files.find(f =>
        f.location.endsWith('build-plate.png')
      );
    }

    setBuildPlateImageUrl(buildPlateImage ? buildPlateImage.url : null);

    setPreforms(
      buildJob.components
        ? buildJob.components
            .sort((a, b) =>
              a.preformType && b.preformType
                ? b.preformType.order - a.preformType.order
                : 0
            )
            .map(preform => {
              const normalizedPreform = { ...preform };

              if (!normalizedPreform.rate) {
                normalizedPreform.rate = 'GOOD_QUALITY';
              }

              if (!normalizedPreform.comment) {
                normalizedPreform.comment = '';
              }

              normalizedPreform.newPhotos = [];

              normalizedPreform.preformType.customFields = normalizedPreform.preformType.customFields.sort(
                (a, b) => a.order - b.order
              );

              normalizedPreform.customFields = PreformService.preparePreformCustomFields(
                normalizedPreform
              );

              return normalizedPreform;
            })
        : []
    );

    setBuildJobQuality(buildJob.quality || null);
    setBuildJobComment(buildJob.comment || '');
  }, [buildJob]);

  React.useEffect(() => {
    if (
      preforms.length !== 0 &&
      currentTab === `preform-${preforms[preforms.length - 1].id}`
    ) {
      setIsFinalStep(true);
    } else if (currentTab === 'buildJob' && preforms.length === 0) {
      setIsFinalStep(true);
    } else if (isFinalStep) {
      setIsFinalStep(false);
    }
  }, [currentTab, preforms]);

  React.useEffect(() => {
    if (takePhotoPreform) {
      const preformId = takePhotoPreform.id;
      const preformIndex = preforms.findIndex(p => p.id === preformId);

      if (preformIndex !== -1) {
        onChangePreform(preformIndex, 'photos', takePhotoPreform.photos);
      }
    }
  }, [takePhotoPreform]);

  if (!buildJob) {
    return <TitanCircularProgress />;
  }

  const onChangePreform = (index, key, value) => {
    setPreforms(prevState => {
      const updatedPreforms = [...prevState];

      updatedPreforms[index] = {
        ...updatedPreforms[index],
        [key]:
          key === 'customFields'
            ? PreformService.preparePreformCustomFields({
                ...updatedPreforms[index],
                customFields: value
              })
            : value
      };

      return updatedPreforms;
    });
  };

  const onSaveBuildJob = async () => {
    await Promise.all(
      preforms.map(async preform => {
        await ComponentService.updateComponent(preform.id, {
          successRate: preform.rate,
          comment: preform.comment,
          quality: buildJobQuality || null,
          customFields: Object.values(preform.customFields)
        });

        if (preform.newPhotos) {
          await Promise.all(
            preform.newPhotos.map(async (photo, order) => {
              const name = `${nanoid()}.png`;

              const { location } = await FileService.uploadBase64File(
                name,
                photo
              );

              return ComponentService.addPhoto(preform.id, {
                name,
                order,
                location
              });
            })
          );
        }
      })
    );

    await BuildJobService.updateBuildJob(buildJob.id, {
      comment: buildJobComment,
      quality: buildJobQuality || null
    });

    if (buildJob.status === COMPONENT_STATUSES.POST_BUILDING) {
      await BuildJobService.markBuildJobBuilt(buildJob.id);
    }

    if (buildJob.status === COMPONENT_STATUSES.POST_CANCELED) {
      await BuildJobService.finishPostCancel(buildJob);
    }

    if (buildJob.status === COMPONENT_STATUSES.POST_FAILED) {
      await BuildJobService.finishPostFailed(buildJob.id);
    }
  };

  const saveButtonLabel = {
    [COMPONENT_STATUSES.POST_BUILDING]: 'Finish Building',
    [COMPONENT_STATUSES.POST_CANCELED]: 'Finish cancellation',
    [COMPONENT_STATUSES.POST_FAILED]: 'Finish failed'
  }[buildJob.status];

  return (
    <TitanDialog
      onClose={onClose}
      fullScreen
      showTitle={false}
      onSave={onSaveBuildJob}
      saveButtonLabel={saveButtonLabel}
      onPrev={() => {
        if (currentTab === 'buildJob') {
          setCurrentTab('buildPlateImage');
        } else if (currentTab.startsWith('preform-')) {
          const preformIndex = preforms.findIndex(
            p => p.id === currentTab.replace('preform-', '')
          );

          if (preformIndex !== -1) {
            if (preformIndex === 0) {
              setCurrentTab('buildJob');
            } else if (preformIndex < preforms.length) {
              setCurrentTab(`preform-${preforms[preformIndex - 1].id}`);
            }
          }
        }
      }}
      onNext={() => {
        if (currentTab === 'buildPlateImage') {
          setCurrentTab('buildJob');
        } else if (currentTab === 'buildJob' && preforms.length !== 0) {
          setCurrentTab(`preform-${preforms[0].id}`);
        } else if (currentTab.startsWith('preform-')) {
          const preformIndex = preforms.findIndex(
            p => p.id === currentTab.replace('preform-', '')
          );

          if (preformIndex !== -1) {
            if (preformIndex < preforms.length - 1) {
              setCurrentTab(`preform-${preforms[preformIndex + 1].id}`);
            }
          }
        }
      }}
      isNextValid={!isFinalStep}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: '100%'
          }}
        >
          <Box sx={{ width: '255px', height: '100%' }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={(e, newTab) => {
                setCurrentTab(newTab);
              }}
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab
                label="Build Plate image"
                value="buildPlateImage"
                sx={{ alignItems: 'start' }}
              />
              <Tab
                label={`Build Job${
                  buildJob.jobKey ? ' ' + buildJob.jobKey : ''
                }`}
                value="buildJob"
                sx={{ alignItems: 'start' }}
              />
              {preforms.map(preform => (
                <Tab
                  label={`Preform ${preform.preformKey || preform.id}`}
                  value={`preform-${preform.id}`}
                  key={`preform-tab-${preform.id}`}
                  sx={{ alignItems: 'start' }}
                />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ width: 'calc(100% - 210px)', height: '100%' }}>
            <TabPanel value="buildPlateImage" sx={{ height: '100%' }}>
              <Box
                sx={{
                  height: '100%',
                  textAlign: 'center',
                  '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }
                }}
              >
                {buildPlateImageUrl ? (
                  <img src={buildPlateImageUrl} alt="" />
                ) : (
                  <Typography>No uploaded image</Typography>
                )}
              </Box>
            </TabPanel>
            <TabPanel value="buildJob">
              <Typography
                variant={'body1'}
                color="secondary"
                sx={{ marginBottom: 3 }}
              >
                Overall Build Evaluation
              </Typography>
              <Box sx={{ width: '60px', height: '60px' }}>
                <Badge
                  badgeContent={buildJobPhotos ? buildJobPhotos.length : 0}
                  color="primary"
                >
                  <IconButton
                    size="large"
                    onClick={() => setTakePhotoBuildJob(buildJob)}
                  >
                    <PhotoCameraIcon fontSize="large" />
                  </IconButton>
                </Badge>
              </Box>
              <Box>
                <Typography variant="h6">{`Build Job ${
                  buildJob.jobKey
                }`}</Typography>
              </Box>
              <Box>
                <Rating
                  name="build-job-rating"
                  size="large"
                  value={buildJobQuality}
                  onChange={async (event, quality) =>
                    setBuildJobQuality(quality)
                  }
                />
              </Box>
              <Box>
                <TextField
                  autoFocus
                  placeholder="Build Job comment"
                  type="text"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={buildJobComment}
                  onChange={e => setBuildJobComment(e.target.value)}
                />
              </Box>
            </TabPanel>
            {preforms.map((preform, index) => (
              <TabPanel value={`preform-${preform.id}`} key={preform.id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50px' }}>
                    <Badge
                      badgeContent={
                        (preform.photos ? preform.photos.length : 0) +
                        (preform.newPhotos ? preform.newPhotos.length : 0)
                      }
                      color="primary"
                    >
                      <IconButton onClick={() => setTakePhotoPreform(preform)}>
                        <PhotoCameraIcon />
                      </IconButton>
                    </Badge>
                  </Box>
                  <Box>
                    <Typography variant="h6">
                      {preform.preformKey || preform.id}
                    </Typography>
                    {preform.preformType ? (
                      <Typography variant={'body1'} color="secondary">
                        {preform.preformType.name} ({preform.preformType.labels}
                        )
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                    <Select
                      value={preform.rate}
                      onChange={e =>
                        onChangePreform(index, 'rate', e.target.value)
                      }
                      variant="outlined"
                    >
                      {rates.map(rate => (
                        <MenuItem value={rate.key} key={rate.key}>
                          {rate.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box>
                  <TextField
                    autoFocus
                    label="Preform comment"
                    type="text"
                    fullWidth
                    value={preform.comment}
                    margin="normal"
                    variant="outlined"
                    onChange={e =>
                      onChangePreform(index, 'comment', e.target.value)
                    }
                  />
                </Box>

                <PreformCustomFields
                  customFields={preform.preformType.customFields}
                  entityCustomFields={preform.customFields}
                  onChange={preformCustomFields => {
                    onChangePreform(index, 'customFields', preformCustomFields);
                  }}
                />
              </TabPanel>
            ))}
          </Box>
        </Box>
      </TabContext>

      {takePhotoPreform && (
        <TitanTakePhotosDialog
          photos={takePhotoPreform.photos}
          onClose={() => setTakePhotoPreform(null)}
          onDelete={async photoId => {
            await ComponentService.deletePhoto(photoId);

            setTakePhotoPreform(prevState => ({
              ...prevState,
              photos: prevState.photos.filter(
                currentPhoto => currentPhoto.id !== photoId
              )
            }));
          }}
          onSave={async photos => {
            const preformId = takePhotoPreform.id;
            const preformIndex = preforms.findIndex(p => p.id === preformId);

            const preformPhotos = preforms[preformIndex].photos;

            const newPreformPhotos = await Promise.all(
              photos.map(async (photo, order) => {
                const name = `${nanoid()}.png`;

                const initialOrder =
                  preformPhotos[preformPhotos.length - 1]?.order + 1 || 0;

                const { location } = await FileService.uploadBase64File(
                  name,
                  photo
                );

                return ComponentService.addPhoto(preformId, {
                  name,
                  location,
                  order: order + initialOrder
                });
              })
            );

            if (preformIndex !== -1) {
              onChangePreform(preformIndex, 'photos', [
                ...preforms[preformIndex].photos,
                ...newPreformPhotos
              ]);
            }

            setTakePhotoPreform(null);
          }}
        />
      )}
      {takePhotoBuildJob && (
        <TitanTakePhotosDialog
          photos={buildJobPhotos}
          onClose={() => setTakePhotoBuildJob(null)}
          onDelete={async photoId => {
            await BuildJobService.deletePhoto(photoId);
            setBuildJobPhotos(prevState =>
              prevState.filter(currentPhoto => currentPhoto.id !== photoId)
            );
          }}
          onSave={async photos => {
            const buildJobId = buildJob.id;

            const buildJobPhotos = buildJob.photos;

            const newBuildJobPhotos = await Promise.all(
              photos.map(async (photo, order) => {
                const name = `${nanoid()}.png`;

                const initialOrder =
                  buildJobPhotos[buildJobPhotos.length - 1]?.order + 1 || 0;

                const { location } = await FileService.uploadBase64File(
                  name,
                  photo
                );

                return BuildJobService.addPhoto(buildJobId, {
                  name,
                  location,
                  order: order + initialOrder
                });
              })
            );
            setBuildJobPhotos(prevState => [
              ...prevState,
              ...newBuildJobPhotos
            ]);
            setTakePhotoBuildJob(null);
          }}
        />
      )}
    </TitanDialog>
  );
}
