import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export default function SpoolChips({
  selectedMaterialType,
  onChangeMaterialType,
  selectedSpoolType,
  onChangeSpoolType
}) {
  const items = React.useMemo(() => {
    const items = [];

    items.push({
      separatorLabel: 'Material Type: '
    });

    items.push({
      label: 'All',
      onClick: () => onChangeMaterialType('all'),
      isHighlighted: selectedMaterialType === 'all'
    });
    items.push({
      label: 'Fiber',
      onClick: () => onChangeMaterialType('FIBER'),
      isHighlighted: selectedMaterialType === 'FIBER'
    });
    items.push({
      label: 'Polymer',
      onClick: () => onChangeMaterialType('PLASTIC'),
      isHighlighted: selectedMaterialType === 'PLASTIC'
    });

    items.push({
      separatorLabel: 'Spool Type: '
    });

    items.push({
      label: 'All',
      key: 'all-spool-type',
      onClick: () => onChangeSpoolType('all'),
      isHighlighted: selectedSpoolType === 'all'
    });
    items.push({
      label: 'Spools',
      onClick: () => onChangeSpoolType('SPOOL'),
      isHighlighted: selectedSpoolType === 'SPOOL'
    });
    items.push({
      label: 'Batch',
      onClick: () => onChangeSpoolType('BATCH'),
      isHighlighted: selectedSpoolType === 'BATCH'
    });

    return items;
  }, [selectedMaterialType, selectedSpoolType]);

  return <FilterChips items={items} />;
}
