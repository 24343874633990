import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import { ROUTES } from '../../constants';
import BuildModuleIcon from '../../assets/icons/build-module-small-secondary.svg';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  buildModuleLink: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  deviceIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function BuildModuleLink({
  buildModule,
  children,
  useName = true,
  width,
  onClick = e => e.stopPropagation()
}) {
  const classes = useStyles();
  const location = useLocation();
  const imageWithSpacingWidth = 52;

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'BUILD_MODULE',
        buildModule.id,
        buildModule.name || 'Build Module',
        ROUTES.BUILD_MODULE_TAB(buildModule.id, 'temperature'),
        'Temperature'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Link
      onClick={handleClick}
      to={{
        pathname: ROUTES.BUILD_MODULE(buildModule.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
      className={classes.buildModuleLink}
    >
      <img className={classes.deviceIcon} src={BuildModuleIcon} alt="" />
      {width ? (
        <OverflowTip width={width - imageWithSpacingWidth}>
          {useName ? buildModule.name : children}
        </OverflowTip>
      ) : useName ? (
        buildModule.name
      ) : (
        children
      )}
    </Link>
  );
}
