import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { Box, Stack } from '@mui/material';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';

export default function ManufacturingOrderChangeLogTable({ changeLog = {} }) {
  const changes = React.useMemo(() => changeLog?.changes || [], [changeLog]);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPageData, setCurrentPageData] = React.useState(
    changes.slice(currentPage, pageSize)
  );

  React.useEffect(() => {
    const changesToDisplay = changes.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );
    setCurrentPageData(changesToDisplay);
  }, [changes, currentPage, pageSize, setCurrentPageData]);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'New State',
        field: 'new-state',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            {Object.entries(row.newState).map(([key, value]) => (
              <Stack sx={{ pt: 1, pb: 1 }} key={key}>
                {_.startCase(key)} : {value}
              </Stack>
            ))}
          </Box>
        )
      },
      {
        headerName: 'Previous State',
        field: 'previous-state',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            {Object.entries(row.prevState).map(([key, value]) => (
              <Box sx={{ pt: 1, pb: 1 }} key={key}>
                {_.startCase(key)} : {value}
              </Box>
            ))}
          </Box>
        )
      },
      {
        headerName: 'Time',
        field: 'timestamp',
        width: 180,
        sortable: true,
        renderCell: ({ row }) =>
          format(new Date(row.timestamp), 'yyyy-MM-dd HH:mm')
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        title=""
        showSearch={false}
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        columns={columns}
        rows={currentPageData}
        rowCount={changes ? changes.length : 0}
        getRowId={row => row.timestamp}
        getRowHeight={() => 'auto'}
      />
    </>
  );
}
